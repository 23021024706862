import React from "react";
import "./textFieldDisable.scss";

const TextFieldDisable = ({
  title,
  disabled,
  defaultValue,
  type,
  onChange,
}) => {
  return (
    <label className="disable-input">
      <span className={"disable-input-title"}>{title}</span>
      <textarea
        className="disable-input-change"
        type={type}
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={onChange}
        style={{ borderBottom: !disabled ? "1px solid #393939" : "none" }}
      />
    </label>
  );
};

export default TextFieldDisable;
