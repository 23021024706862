import styled from "styled-components";

import { spacing } from "@runwayhealth/runway-components-react";

const PageConsultationBody = styled.div`
  position: relative;
  padding-bottom: ${spacing.lg};
  text-align: center;
`;

export default PageConsultationBody;
