import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { LANDING_PAGE } from "@utils/constantsEnv";
import { recoverQuestions } from "@store/questions/questionsSlice";

const QuestionsRoute = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const path = history.location.pathname;
  const conditions = useSelector((state) => state.case.newCase.data.conditions);
  const questionnaire = useSelector((state) => state.questions);

  if (!path.includes("dashboard") && !path.includes("login")) {
    // Check for answered questions.
    const answeredQuestions = sessionStorage.getItem("answered_questions");
    let answeredQuestionsArray = answeredQuestions
      ? [...new Set(JSON.parse(answeredQuestions))]
      : [];
    const currentRoute = children.find((child) => child.props.path === path);
    const previousRoute = currentRoute
      ? currentRoute.props.prevStep
        ? currentRoute.props.prevStep
        : []
      : [];

    // The following routes can't be reached without having at least 10 answered questions.
    const routes = ["id", "shipping", "registration"];
    if (routes.includes(path.split("/")[1]) && questionnaire.general.length < 10) {
      // We check for a backup questionnaire.
      // It arranges the questions in a single array.
      const questionnaireBackup = JSON.parse(sessionStorage.getItem("questions"));

      if (questionnaireBackup?.general.length > 10) {
        // We restore questionnaire with our back up.
        dispatch(recoverQuestions(questionnaireBackup));
      } else {
        // We redirect the user to the start of the questionnaire.
        history.push("/state");
      }
    } else if (!routes.includes(path.split("/")[1])) {
      // If the questionnaire is being answered, we backup the questionnaire state in session storage.
      sessionStorage.setItem("questions", JSON.stringify(questionnaire));
    }

    // If conditions are not loaded and there is not known previous route.
    // Redirects to landing site.
    if (conditions.length === 0 && previousRoute.length === 0) {
      window.location.replace(`${LANDING_PAGE}#/login`);
      return (
        <>
          <p>Redirecting...</p>
        </>
      );
    }

    // Update current progress.
    answeredQuestionsArray.push(path);
    sessionStorage.setItem("answered_questions", JSON.stringify(answeredQuestionsArray));
    sessionStorage.setItem("step", path);
  }

  return <>{children}</>;
};

export default QuestionsRoute;
