import "./account.scss";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CreditCardIllustration from "@components/elements/CreditCardIlustration";
import PaymentMethodManager, { PaymentMethodManagerView } from "@components/PaymentMethodManager";
import { CardBrands, PaymentMethod } from "@store/../@types/billing";
import { RootEntity, RootState } from "@store/../@types/state";
import { User } from "@store/../@types/user";
import { GET_PAYMENT_METHODS } from "@store/user/userActions";

import { PasswordAccount } from "./Components/PasswordAccount/PasswordAccount";
import ProfileAccount from "./Components/ProfileAccount/ProfileAccount";
import ShippingAccount from "./Components/ShippingAccount/ShippingAccount";
import BackButton from "./img/BackButton.svg";

const Account = () => {
  const dispatch = useDispatch();
  const [disableProfileForm, setDisableProfileForm] = useState(true);
  const [disablePasswordForm, setDisablePasswordForm] = useState(true);
  const [disableBillingForm, setDisableBillingForm] = useState(true);
  const [disablePaymentForm, setDisablePaymentForm] = useState(true);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod>();
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);
  const error = useSelector<RootState, string>((state) => state.ui.error);

  const changeDisable = (itemSelect: React.Dispatch<React.SetStateAction<boolean>>) => {
    itemSelect((prev) => !prev);
  };

  useEffect(() => {
    dispatch({ type: GET_PAYMENT_METHODS });
  }, []);

  useEffect(() => {
    if (user.data.paymentMethods.length > 1) {
      const defaultPaymentMethod = user.data.paymentMethods.find(
        (paymentMethod) => paymentMethod.id === user.data.defaultPaymentMethod
      );
      setDefaultPaymentMethod(defaultPaymentMethod);
    }
  }, [user.data.paymentMethods]);

  return (
    <div className="account">
      {disablePasswordForm && disableBillingForm && disablePaymentForm && (
        <div className="account-billing">
          {!disableProfileForm && (
            <div className="account-header-form">
              <div className="back-button" onClick={() => changeDisable(setDisableProfileForm)}>
                <img src={BackButton} alt="Go back" />
                <span>Back</span>
              </div>
            </div>
          )}
          <div className="account-header-form">
            <div className="account-title">Profile Info</div>
            {disableProfileForm && (
              <div
                className="account-header-form-button"
                onClick={() => changeDisable(setDisableProfileForm)}
              >
                Edit
              </div>
            )}
          </div>
          <ProfileAccount
            disableProfileForm={disableProfileForm}
            user={user.data}
            error={error}
            setDisableProfileForm={setDisableProfileForm}
          />
        </div>
      )}
      {disableProfileForm && disableBillingForm && disablePaymentForm && (
        <div className="account-billing">
          {!disablePasswordForm && (
            <div className="account-header-form">
              <div className="back-button" onClick={() => changeDisable(setDisablePasswordForm)}>
                <img src={BackButton} alt="Go back" />
                <span>Back</span>
              </div>
            </div>
          )}
          <div className="account-header-form">
            <div className="account-title">Reset Password</div>
            {disablePasswordForm && (
              <div
                className="account-header-form-mdbutton"
                onClick={() => changeDisable(setDisablePasswordForm)}
              >
                Change password
              </div>
            )}
          </div>
          {!disablePasswordForm && (
            <PasswordAccount
              disablePasswordForm={disablePasswordForm}
              setDisablePasswordForm={setDisablePasswordForm}
              error={error}
            />
          )}
        </div>
      )}
      {disableProfileForm && disablePasswordForm && disableBillingForm && (
        <div className="account-billing">
          {!disablePaymentForm && (
            <div className="account-header-form">
              <div className="back-button" onClick={() => changeDisable(setDisablePaymentForm)}>
                <img src={BackButton} alt="Go back" />
                <span>Back</span>
              </div>
            </div>
          )}
          <div className="account-header-form">
            {disablePaymentForm && (
              <>
                <div className="account-title">Billing</div>
                <div
                  className="account-header-form-mdbutton"
                  onClick={() => changeDisable(setDisablePaymentForm)}
                >
                  Manage
                </div>
              </>
            )}
          </div>
          {disablePaymentForm && (
            <>
              <CreditCardIllustration
                last4={defaultPaymentMethod?.card.last4 || "XXXX"}
                brand={defaultPaymentMethod?.card.brand || CardBrands.UNKNOWN}
              />
            </>
          )}
          {!disablePaymentForm && (
            <>
              <div className="account-title">Manage Billing</div>
              <PaymentMethodManager user={user} view={PaymentMethodManagerView.PROFILE_SETTINGS} />
            </>
          )}
        </div>
      )}
      {disableProfileForm && disablePasswordForm && disablePaymentForm && (
        <div className="account-billing">
          {!disableBillingForm && (
            <div className="account-header-form">
              <div className="back-button" onClick={() => changeDisable(setDisableBillingForm)}>
                <img src={BackButton} alt="Go back" />
                <span>Back</span>
              </div>
            </div>
          )}
          <div className="account-header-form">
            <div className="account-title">Shipping Address</div>
            {disableBillingForm && (
              <div
                className="account-header-form-xlbutton"
                onClick={() => changeDisable(setDisableBillingForm)}
              >
                Change shipping address
              </div>
            )}
          </div>
          {!disableBillingForm && (
            <ShippingAccount
              disableBillingForm={disableBillingForm}
              user={user}
              setDisableBillingForm={setDisableBillingForm}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Account;
