import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tooltip from "@material-ui/core/Tooltip";
import { Button } from "@runwayhealth/runway-components-react";
import { Order } from "@store/../@types/case";
import { RootState } from "@store/../@types/state";
import { getStaleOrders, syncStaleOrder } from "@store/admin/adminAction";
import { toastError } from "@utils/utils";

import { getTrackingLink } from "./OrderSearch";

const StaleOrders = () => {
  const dispatch = useDispatch();
  const orders = useSelector<RootState, Order[]>((state) => state.admin.orders);
  const orderSyncError = useSelector<RootState, boolean>((state) => state.admin.orderSyncError);

  useEffect(() => {
    dispatch(getStaleOrders());
  }, []);

  const syncOrderAlert = (orderId: string) => {
    if (
      confirm(
        `⚠️
        Are you sure you want to sync ${orderId}? Keep in mind that this procedure triggers email notifications to the users, that may result in delayed or buggy notifications.`
      )
    ) {
      dispatch(
        syncStaleOrder({
          orderId,
        })
      );
    }
  };

  useEffect(() => {
    if (orderSyncError) {
      toastError("Error", "Could no sync order.");
    }
  }, [orderSyncError]);
  return (
    <section>
      <h2>Stale Orders 💤</h2>
      <div>
        <p>
          The <b>synced</b> columns illustrate the synchronization of the order status between
          Runway and the Pharmacy
        </p>
        <p>✅ : Both pharmacy and Runway order status are equal.</p>
        <p>❌: Pharmacy and Runway order status have discrepancies</p>
        <p>
          The <b>SYNC</b> button which is the last column is designed to help solve these order
          status discrepancies.
        </p>
        <i>
          ⚠️ Please note that real email notifications will be triggered during the synchronization
          process.
        </i>
      </div>
      <table>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Status</th>
            <th>Tracking Number</th>
            <th>Updated At</th>
            <th>Synced</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => {
            return (
              <tr key={`order-${order.id}`}>
                <td>{order.order_id}</td>
                <td>{order.status}</td>
                <td>{getTrackingLink(order.tracking_number, order.carrier)}</td>
                <td>{order.updatedAt}</td>
                <td>
                  {order.externalStatus === order.status ? "✅" : `❌ ${order.externalStatus}`}
                </td>
                {order.externalStatus !== order.status && (
                  <td>
                    <Tooltip title="Sync">
                      <Button
                        size="sm"
                        iconName="ArrowClockwise"
                        onClick={() => {
                          syncOrderAlert(order.order_id);
                        }}
                        isIconOnly
                        variant="outline"
                      />
                    </Tooltip>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};
export default StaleOrders;
