import { useState, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

const StateSelect = ({ name, value, options, onFocus, onChange, onBlur, width, fontSize }) => {
  const [localValue, setLocalValue] = useState(value ?? "");
  const [label, setLabel] = useState("State");
  const [screenWidth] = useState(window.screen.width);

  useEffect(() => {
    setLocalValue(value ?? "state");

    // If value that changes is not empty, then remove label (placeholder)
    if (value !== "") {
      setLabel("");
    }
  }, [value]);

  let inputStyles = {
    width: `${width}`,
    fontSize: fontSize || screenWidth < 760 ? "20px" : "32px",
    fontFamily: "Gustavo",
    color: "#393939",
  };

  const handleFocus = () => {
    setLabel("");
    if (onFocus) {
      onFocus();
    }
  };

  const handleChange = (e) => {
    setLabel("");
    const value = e.target.value;
    setLocalValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e.target.value);
    }
  };

  function getLabelStyle() {
    return {
      fontSize: "20px",
      color: "#757575",
      fontFamily: "Gustavo",
      opacity: "60%",
      flexWrap: "wrap",
    };
  }

  return (
    <FormControl>
      <InputLabel style={getLabelStyle()}>{label}</InputLabel>
      <Select
        style={inputStyles}
        name={name}
        value={localValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {options?.map((option) => {
          const { abbreviation } = option;
          return (
            <MenuItem key={abbreviation} value={abbreviation}>
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default StateSelect;
