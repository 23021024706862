import update from "immutability-helper";
import moment from "moment-business-days-sk";

import { createSlice } from "@reduxjs/toolkit";
import { Case, Order, Question } from "@store/../@types/case";
import { Partner, PromoCode, Redemption } from "@store/../@types/state";

const partners: Partner[] = [];

const initialState = {
  users: [],
  partners,
  orders: [],
  cases: [],
  transactions: [],
  promoCodes: [],
  redemptions: [],
  credentials: {},
  cancelationError: false,
  resubmitError: false,
  caseShippingError: false,
  orderShippingError: false,
  orderSyncError: false,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    findUser: (state, action) => {
      return (state = { ...state, users: action.payload });
    },
    cancelOrderError: (state, action) => {
      return (state = { ...state, cancelationError: action.payload });
    },
    resubmitOrderError: (state, action) => {
      return (state = { ...state, resubmitError: action.payload });
    },
    caseShippingError: (state, action) => {
      return (state = { ...state, caseShippingError: action.payload });
    },
    orderShippingError: (state, action) => {
      return (state = { ...state, orderShippingError: action.payload });
    },
    orderSyncError: (state, action) => {
      return (state = { ...state, orderSyncError: action.payload });
    },
    getOrders: (state, action) => {
      const orders = action.payload.map((order: Order) => {
        const case_questions = order.Case?.case_questions || [];
        const parsedQuestions = case_questions !== null ? case_questions : [];
        const departureQuestion = parsedQuestions.filter(
          (case_question: Question) =>
            case_question.question === "When do you depart?" ||
            case_question.question === "When are you leaving?"
        );
        const date = departureQuestion
          ? departureQuestion.length > 0
            ? moment(new Date(departureQuestion[0].answer)).format("dddd, MMMM Do YYYY")
            : undefined
          : undefined;
        return {
          ...order,
          departureDate: date,
        };
      });
      return (state = { ...state, orders });
    },
    getCases: (state, action) => {
      const cases = action.payload.map((case_: Case) => {
        const case_questions = case_?.case_questions || [];
        const parsedQuestions = case_questions !== null ? case_questions : [];
        const departureQuestion = parsedQuestions.filter(
          (case_question: Question) =>
            case_question.question === "When do you depart?" ||
            case_question.question === "When are you leaving?"
        );
        const date =
          departureQuestion.length > 0
            ? moment(new Date(departureQuestion[0].answer)).format("dddd, MMMM Do YYYY")
            : undefined;
        return {
          ...case_,
          departureDate: date,
        };
      });
      return (state = { ...state, cases });
    },
    getCredentials: (state, action) => {
      return (state = {
        ...state,
        credentials: {
          clientKey: action.payload.clientKey,
          clientSecret: action.payload.decipheredSecret,
        },
      });
    },
    getPartners: (state, action) => {
      return (state = { ...state, partners: action.payload });
    },
    getTransactions: (state, action) => {
      return (state = { ...state, transactions: action.payload });
    },
    removePartner: (state, action) => {
      const partnerIndex = state.partners.findIndex(
        (partner) => partner.clientKey === action.payload
      );
      return update(state, {
        partners: {
          $splice: [[partnerIndex, 1]],
        },
      });
    },
    getPromoCode: (state, action) => {
      const promoCodes = action.payload.map((promoCode: PromoCode) => {
        const firstTime = moment(new Date(promoCode.first_time_redeemed)).format("MM-DD-YYYY");
        const lastTime = moment(new Date(promoCode.last_time_redeemed)).format("MM-DD-YYYY");

        return {
          ...promoCode,
          first_time_redeemed: firstTime,
          last_time_redeemed: lastTime,
        };
      });

      return (state = { ...state, promoCodes });
    },
    getRedemptions: (state, action) => {
      const redemptions = action.payload
        .map((redemption: Redemption) => {
          const createdAt = moment(new Date(redemption.createdAt)).format("MM-DD-YYYY");
          return {
            ...redemption,
            createdAt,
          };
        })
        .sort((a: Redemption, b: Redemption) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB.getTime() - dateA.getTime();
        });

      return (state = { ...state, redemptions });
    },
  },
});

export const {
  findUser,
  cancelOrderError,
  resubmitOrderError,
  caseShippingError,
  orderShippingError,
  orderSyncError,
  getOrders,
  getCases,
  getPartners,
  getCredentials,
  getTransactions,
  removePartner,
  getPromoCode,
  getRedemptions,
} = adminSlice.actions;

export default adminSlice.reducer;
