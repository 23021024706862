import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../elements/Header";
import NextButton from "../../elements/NextButton";
import Checkbox from "../../elements/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { userUpdate } from "../../../store/user/userSlice";
import { questionsUpdate } from "../../../store/questions/questionsSlice";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { Button, Text, Title } from "@runwayhealth/runway-components-react";

const SideEffects = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const variants = ["Yeast infection", "Diarrhea", "Upset stomach or nausea", "Rash", "None"];

  const [isNone, setNone] = useState(false);
  const [formState, setFormState] = useState([false, false, false, false, false]);
  const [captureVariants, setCaptureVariants] = useState([]);

  useEffect(() => {
    formState?.forEach((item, index) => {
      return item === true && captureVariants.indexOf(variants[index]) === -1
        ? setCaptureVariants((prev) => [...prev, variants[index]])
        : null;
    });
  }, [formState]);

  useEffect(() => {
    if (formState[4] === true) {
      setNone(true);
    } else {
      setNone(false);
    }
  }, [formState]);
  useTitle("Side Effects");

  let capturedVariant = formState
    ?.map((item, i) => (item ? variants[i] : false))
    .filter((variant) => variant)
    .join(", ");

  const setAnswer = () => {
    if (capturedVariant && !isNone) {
      dispatch(
        questionsUpdate({
          question: "Are you prone to any of the following antibiotic side effects?",
          value: capturedVariant,
          important: true,
          questionType: "malaria",
        })
      );
      dispatch(userUpdate({ name: "sideEffects", value: capturedVariant }));
      history.push("/past_medications");
    } else if (isNone) {
      dispatch(
        questionsUpdate({
          question: "Are you prone to any of the following antibiotic side effects?",
          value: "None",
          important: false,
          questionType: "malaria",
        })
      );
      dispatch(userUpdate({ name: "sideEffects", value: "None" }));
      history.push("/past_medications");
    }
  };

  const onKeyUpHandler = (e) => {
    e.key == "Enter" && setAnswer();
  };

  return (
    <>
      <Header progress={5} />
      <PageConsultation tabIndex={1} onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">Are you prone to any of the following antibiotic side effects?</Title>
          <Text size="md">Choose one or more options</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <div className="rw-page-consultation-list-checkboxes">
            {variants.map((variant, index) => (
              <>
                <Checkbox
                  content={variant}
                  checked={formState[index]}
                  setChecked={() => {
                    if (index === 4) {
                      setFormState([false, false, false, false, true]);
                    } else {
                      const newState = formState.map((state, i) => {
                        if (i === index) {
                          return !state;
                        } else if (i === formState.length - 1) {
                          return false;
                        }
                        return state;
                      });
                      setFormState(newState);
                    }
                  }}
                  num={index}
                  key={`checkbox-${index}`}
                />
              </>
            ))}
          </div>
        </PageConsultation.Body>

        <Button
          size="lg"
          iconName="ArrowRight"
          customWidth={440}
          onClick={setAnswer}
          disabled={!capturedVariant}
        >
          Next
        </Button>
      </PageConsultation>
    </>
  );
};

export default SideEffects;
