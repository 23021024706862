import "./YesNoQuestion.scss";

import ChoiceButtons from "../../elements/ChoiceButtons";
import GenderButtons from "../../elements/GenderButtons";

interface YesNoQuestionProps {
  title?: string;
  question?: string;
  choiceOne?: string;
  choiceTwo?: string;
  option?: string;
  gender?: boolean;
  setAnswer: (answer: string) => void;
  setNo?: (answer: string) => void;
  problems?: boolean;
  isActive?: boolean;
  isActiveSecond?: boolean;
}

const YesNoQuestion = ({
  title,
  question,
  choiceOne = "Yes",
  choiceTwo = "No",
  option,
  gender = false,
  setAnswer,
  setNo = (_answer) => {},
  problems = false,
  isActive = false,
  isActiveSecond = false,
}: YesNoQuestionProps) => {
  return (
    <div className="question-container">
      {title && <span className="slide-title">{title}</span>}
      {question && <div className="slide-question">{question}</div>}
      {option && <span className="slide-choice">{option}</span>}
      <div className="slide-buttons">
        {gender ? (
          <GenderButtons setAnswer={setAnswer} />
        ) : (
          <ChoiceButtons
            setAnswer={setAnswer}
            first={choiceOne}
            isActive={isActive}
            isActiveSecond={isActiveSecond}
            second={choiceTwo}
            setNo={setNo}
            problems={problems}
          />
        )}
      </div>
    </div>
  );
};

export default YesNoQuestion;
