import { call, put, takeEvery } from "redux-saga/effects";
import { PHARMACIES_SAVE } from "./pharmacyAction";
import { push } from "connected-react-router";
import { changeHomeStatusAddress } from "../ui/uiSlice";
import { shippingAddressUpdate } from "../user/userSlice";

function* pharmacySave(action) {
  // Temporary disabling fetch to Database for other pharmacies.
  // Saving pharmacy address regardless of the DB existence.
  yield put(
    shippingAddressUpdate({
      address: action.payload.address,
      city_name: action.payload.city,
      state_name: action.payload.state,
      zip_code: action.payload.zip_code,
      phone_number: action.payload.phoneOrFax,
    })
  );
  yield put(changeHomeStatusAddress());
  yield put(push("/shipping"));
  // try {
  //   const { data } = yield call(() => md.mdPost("save_pharmacy", action.payload));
  //   if(Object.keys(data).length > 0){
  //     yield put(shippingAddressUpdate({
  //       pharmacy_id: data[0].id,
  //       address: data[0].address1,
  //       address2: data[0].address2,
  //       city_name: data[0].city,
  //       state_name:  data[0].state,
  //       zip_code: data[0].zip_code,
  //       phone_number: data[0].primary_phone
  //     }))
  //     yield put(changeHomeStatusAddress())
  //     yield put(push('/shipping'))
  //   }else{
  //     yield put(errorHandler('No pharmacy found'))
  //     yield delay(5000)
  //     yield put(errorHandler(null))
  //   }
  // } catch (e) {
  //   yield put(errorHandler('No pharmacy found'))
  //   yield delay(5000)
  //   yield put(errorHandler(null))
  //   console.log(e);
  // }
}

export default function* userSaga() {
  yield takeEvery(PHARMACIES_SAVE, pharmacySave);
}
