/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { useLoadOnTop } from "@hooks/useLoadOnTop";
import { Button, Text, Title } from "@runwayhealth/runway-components-react";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import Checkbox from "../../elements/Checkbox";
import Header from "../../elements/Header";
import { ConditionTypes } from "@store/../@types/condition";

const FollowingConditions = () => {
  const [variants, setVariants] = useState([]);
  const dispatch = useDispatch();

  const diaVariants = [
    "None",
    "Chronic Kidney Disease",
    "Myasthenia gravis",
    "Untreated gonorrhea",
    "Untreated syphilis",
    "G6PD deficiency",
    "Over 65",
  ];

  const motionVariants = [
    "None",
    "Heart rhythm problems",
    "Liver cirrhosis",
    "Seizure disorder",
    "Myasthenia gravis",
    "Ulcerative colitis",
    "Severe prostate enlargement",
    "Glaucoma",
  ];

  const altitudeVariants = [
    "None",
    "Cirrhosis of the liver",
    "Adrenal insufficiency",
    "Chronic kidney disease",
    "Glaucoma",
    "Sulfa Allergy",
    "Seizures",
  ];

  const insomniaVariants = [
    "None",
    "Heart rhythm problems",
    "Glaucoma",
    "Enlarged prostate that requires medication to treat",
    "COPD",
    "Over 65",
  ];

  const consultations = JSON.parse(sessionStorage.getItem("consultations")) || [];
  const finishedConsultations = JSON.parse(sessionStorage.getItem("finished_consultations")) || [];
  const currentConsultation = sessionStorage.getItem("current_consultation");

  useEffect(() => {
    switch (currentConsultation) {
      case ConditionTypes.INSOMNIA_CONSULTATION: {
        setVariants(insomniaVariants);
        break;
      }
      case ConditionTypes.ALTITUDE_CONSULTATION: {
        setVariants(altitudeVariants);
        break;
      }
      case ConditionTypes.MOTION_CONSULTATION: {
        setVariants(motionVariants);
        break;
      }
      case ConditionTypes.NAUSEA_CONSULTATION: {
        setVariants(motionVariants);
        break;
      }
      case ConditionTypes.DIARRHEA_CONSULTATION: {
        setVariants(diaVariants);
        break;
      }
      case ConditionTypes.MALARIA_CONSULTATION: {
        setVariants([]);
        break;
      }
      case ConditionTypes.VAGINAL_YEAST_INFECTION: {
        setVariants([]);
        break;
      }
      case ConditionTypes.URINARY_TRACT_INFECTION: {
        setVariants([]);
        break;
      }
      case ConditionTypes.TYPHOID_FEVER: {
        setVariants([]);
        break;
      }
      case ConditionTypes.RUNWAY_PLUS:
        setVariants([]);
        break;
      default: {
        console.log("no consultation fixed");
      }
    }
  }, []);

  const history = useHistory();
  const [isNone, setNone] = useState(false);

  const [state, setState] = useState([false, false, false, false, false, false, false, false]);
  const [captureVariants, setCaptureVariants] = useState([]);

  useTitle("Health Conditions");
  useLoadOnTop();

  useEffect(() => {
    if (variants.length === 0) {
      redirectToNextRoute();
    }
  }, []);

  useEffect(() => {
    state.forEach((item, index) => {
      return item === true && captureVariants.indexOf(variants[index]) === -1
        ? setCaptureVariants((prev) => [...prev, variants[index]])
        : null;
    });
  }, [state]);

  let capturedVariant = state
    .map((item, i) => (item ? variants[i] : false))
    .filter((variant) => variant)
    .join(", ");

  const currentNextRoute = (route) => {
    const nextRoute = consultations.includes(route);

    if (nextRoute && currentConsultation !== route && !finishedConsultations.includes(route)) {
      return consultations;
    } else history.push("/allergies");
  };

  const currentConsultationTypeOfQuestion = () => {
    switch (currentConsultation) {
      case ConditionTypes.DIARRHEA_CONSULTATION: {
        return "diarrhea";
      }
      case ConditionTypes.MALARIA_CONSULTATION: {
        return "malaria";
      }
      case ConditionTypes.ALTITUDE_CONSULTATION: {
        return "altitude";
      }
      case ConditionTypes.MOTION_CONSULTATION: {
        return "motion";
      }
      case ConditionTypes.INSOMNIA_CONSULTATION: {
        return "insomnia";
      }
      case ConditionTypes.NAUSEA_CONSULTATION: {
        return "nausea";
      }
      case ConditionTypes.VAGINAL_YEAST_INFECTION: {
        return "vaginalYeastInfection";
      }
      case ConditionTypes.URINARY_TRACT_INFECTION: {
        return "urinaryTractInfection";
      }
      case ConditionTypes.TYPHOID_FEVER: {
        return "typhoid";
      }
      default: {
        console.log("No consultation fixed");
      }
    }
  };

  const setAnswer = () => {
    if (capturedVariant || isNone) {
      dispatch(
        questionsUpdate({
          question: "Do you have any of the following conditions?",
          value: isNone ? "None" : capturedVariant,
          important: !isNone,
          questionType: currentConsultationTypeOfQuestion(),
        })
      );
      redirectToNextRoute();
    }
  };

  const onKeyUpHandler = (e) => {
    e.key == "Enter" && setAnswer();
  };

  const redirectToNextRoute = () => {
    switch (consultations) {
      case currentNextRoute(ConditionTypes.MALARIA_CONSULTATION):
        finishedConsultations.push(currentConsultation);
        sessionStorage.setItem("finished_consultations", JSON.stringify(finishedConsultations));
        history.push("/activities");
        break;

      case currentNextRoute(ConditionTypes.ALTITUDE_CONSULTATION):
        finishedConsultations.push(currentConsultation);
        sessionStorage.setItem("finished_consultations", JSON.stringify(finishedConsultations));
        history.push("/start/altitude");
        break;

      case currentNextRoute(ConditionTypes.MOTION_CONSULTATION):
        finishedConsultations.push(currentConsultation);
        sessionStorage.setItem("finished_consultations", JSON.stringify(finishedConsultations));
        history.push(`/start/motion/${ConditionTypes.MOTION_CONSULTATION}`);
        break;

      case currentNextRoute(ConditionTypes.NAUSEA_CONSULTATION):
        finishedConsultations.push(currentConsultation);
        sessionStorage.setItem("finished_consultations", JSON.stringify(finishedConsultations));
        history.push(`/start/motion/${ConditionTypes.NAUSEA_CONSULTATION}`);
        break;

      case currentNextRoute(ConditionTypes.INSOMNIA_CONSULTATION):
        finishedConsultations.push(currentConsultation);
        sessionStorage.setItem("finished_consultations", JSON.stringify(finishedConsultations));
        history.push("/start/insomnia");
        break;

      case currentNextRoute(ConditionTypes.VAGINAL_YEAST_INFECTION):
        finishedConsultations.push(currentConsultation);
        sessionStorage.setItem("finished_consultations", JSON.stringify(finishedConsultations));
        history.push(`/start/infection/${ConditionTypes.VAGINAL_YEAST_INFECTION}`);
        break;

      case currentNextRoute(ConditionTypes.URINARY_TRACT_INFECTION):
        finishedConsultations.push(currentConsultation);
        sessionStorage.setItem("finished_consultations", JSON.stringify(finishedConsultations));
        history.push(`/start/infection/${ConditionTypes.URINARY_TRACT_INFECTION}`);
        break;

      case currentNextRoute(ConditionTypes.TYPHOID_FEVER):
        finishedConsultations.push(currentConsultation);
        sessionStorage.setItem("finished_consultations", JSON.stringify(finishedConsultations));
        history.push("/start/typhoid");
        break;

      case currentNextRoute(ConditionTypes.RUNWAY_PLUS):
        finishedConsultations.push(currentConsultation);
        sessionStorage.setItem("finished_consultations", JSON.stringify(finishedConsultations));
        history.push("/start/plus");
        break;

      default:
        finishedConsultations.push(currentConsultation);
        sessionStorage.setItem("finished_consultations", JSON.stringify(finishedConsultations));
        history.push("/allergies");
    }
  };

  return (
    <>
      <Header progress={80} />
      <PageConsultation tabIndex={1} onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">Do you have any of the following conditions?</Title>
          <Text size="md">Choose one or more options</Text>
        </PageConsultation.Header>
        <PageConsultation.Body>
          <div className="rw-page-consultation-list-checkboxes">
            {variants.map((variant, index) => (
              <Checkbox
                content={variant}
                checked={state[index]}
                setChecked={() => {
                  if (index === 0) {
                    const newState = state.map((state, i) => {
                      return i === 0 ? !state : false;
                    });
                    setState(newState);
                  } else {
                    const newState = state.map((state, i) => {
                      return i === index ? !state : state;
                    });
                    newState[0] = false;
                    setState(newState);
                  }
                }}
                num={index}
                handleClick={setAnswer}
                key={`condition-${index}`}
              />
            ))}
          </div>
        </PageConsultation.Body>
        <Button
          size="lg"
          iconName="ArrowRight"
          customWidth={440}
          onClick={setAnswer}
          disabled={!capturedVariant && !isNone}
        >
          Next
        </Button>
      </PageConsultation>
    </>
  );
};

export default FollowingConditions;
