import { useTitle } from "@hooks/useTitle";
import { Link, Text, Title } from "@runwayhealth/runway-components-react";

const InformationSharing = () => {
  useTitle("Consent");
  return (
    <>
      <Title size="giant">Authorization for Information Sharing</Title>
      <Text variant="base">
        <strong>
          PLEASE READ THE FOLLOWING TERMS CAREFULLY. THIS IS A LEGALLY BINDING AGREEMENT ALLOWING
          RUNWAY HEALTH, THE TELEHEALTH, AND THE PHARMACY TO SHARE YOUR INFORMATION WITH EACH OTHER
          FOR PURPOSES OF PROVIDING YOUR REQUESTED SERVICES.
        </strong>
      </Text>

      <Text variant="base">
        <strong>The Services:</strong> You have requested that Runway Health coordinate the
        provision of certain services to you, including matching you with a provider for a
        telehealth consult and a pharmacy to fill any prescriptions issued by such provider (the
        “Services).
      </Text>

      <Text variant="base">
        <strong>
          Customer Data: For purposes of this Authorization, “Customer Data” means information about
          you that is (i) collected by Runway Health, including information submitted by you to
          Runway Health in the course of requesting the Services, (ii) information from the
          telehealth provider with whom you are matched by Runway Health (the “Telehealth
          Provider”), including but not limited to information about any prescriptions issued to you
          by the Telehealth Provider, and (iii) information from the pharmacy selected by Runway
          Health (the “Pharmacy”) regarding the filling of requested prescriptions. Some or all of
          the Customer Data may be individually identifiable information that is subject to
          protection from disclosure under state or federal law or may be “Protected Health
          Information” subject to the protections of HIPAA (the Health Insurance Portability and
          Accountability Act of 1996, as amended) against unauthorized use or disclosure.{" "}
        </strong>
      </Text>
      <Text variant="base">
        <strong>
          Authorization: By giving your express written consent below, you hereby authorize Runway
          Health, the Telehealth Provider, and the Pharmacy to share Customer Data with each other
          as necessary in order to provide the Services requested by you.
        </strong>
      </Text>

      <Text variant="base">
        <strong>Your rights regarding this authorization:</strong> You acknowledge that you have the
        right to revoke this authorization at any time, except to the extent that Customer Data has
        already been used and disclosed by Runway Health, the Telehealth Provider, or the Pharmacy
        in reliance on this authorization. You understand that if you wish to revoke this
        authorization in the future, you must do so in writing by sending a dated and signed letter
        stating that you revoke this authorization to Runway Health at the following address: 237 S
        Dixie Hwy, Floor 4 Suite 67, Coral Gables, FL 33133. Unless revoked by you in writing, this
        Authorization shall expire three years from the date it is signed by you, or sooner if
        required by applicable state law. You may inspect a copy of the Customer Data used or
        disclosed pursuant to this Authorization by contacting us via email at
        support@runwayhealth.com. You may click{" "}
        <Link href="https://runwayhealth-legal.s3.amazonaws.com/Authorization+for+Information+Sharing.pdf">
          here
        </Link>{" "}
        to download a copy of this Authorization or email us at{" "}
        <Link href="mailto:support@runwayhealth.com">support@runwayhealth.com</Link> to request a
        copy.
      </Text>
    </>
  );
};

export default InformationSharing;
