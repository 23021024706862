import "./CreditCardIllustration.scss";

import { CardBrands, CardLogoIndex } from "@store/../@types/billing";

interface CardProps {
  last4: string;
  brand: CardBrands;
}

const CreditCardIllustration = ({ last4, brand }: CardProps) => {
  return (
    <div className="card-wrapper">
      <div className="card">
        <div className="header">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6e51db21b4dda8f05374007d1de1f24a2a213b38018b4d7be64ec0ba1cff9e6e?placeholderIfAbsent=true"
            className="chip-illustraiton"
          />
          <img src={CardLogoIndex.get(brand)} className="brand-logo" />
        </div>
        <div className="footer">{`XXXX XXXX XXXX ${last4}`}</div>
      </div>
    </div>
  );
};

export default CreditCardIllustration;
