import { Product } from "./state";

export enum ConditionTypes {
  MALARIA_CONSULTATION = "Malaria Prophylaxis",
  ALTITUDE_CONSULTATION = "Altitude Sickness",
  INSOMNIA_CONSULTATION = "Insomnia",
  MOTION_CONSULTATION = "Motion Sickness",
  DIARRHEA_CONSULTATION = "Traveler's Diarrhea",
  NAUSEA_CONSULTATION = "Nausea Prevention",
  URINARY_TRACT_INFECTION = "Urinary Tract Infection",
  VAGINAL_YEAST_INFECTION = "Vaginal Yeast Infection",
  TYPHOID_FEVER = "Typhoid Fever",
  RUNWAY_PLUS = "Runway+",
}

export enum UrlConditionTypes {
  DIA = "dia",
  MOTION = "motion",
  ALTITUDE = "altitude",
  INSOMNIA = "insomnia",
  MALARIA = "malaria",
  NAUSEA = "nausea",
  URINARY_TRACT_INFECTION = "urinary-tract-infection",
  VAGINAL_YEAST_INFECTION = "vaginal-yeast-infection",
  TYPHOID_FEVER = "typhoid",
  PLUS = "plus",
}

export const conditionTypesIndex: Map<UrlConditionTypes, ConditionTypes> = new Map([
  [UrlConditionTypes.DIA, ConditionTypes.DIARRHEA_CONSULTATION],
  [UrlConditionTypes.MOTION, ConditionTypes.MOTION_CONSULTATION],
  [UrlConditionTypes.ALTITUDE, ConditionTypes.ALTITUDE_CONSULTATION],
  [UrlConditionTypes.INSOMNIA, ConditionTypes.INSOMNIA_CONSULTATION],
  [UrlConditionTypes.MALARIA, ConditionTypes.MALARIA_CONSULTATION],
  [UrlConditionTypes.NAUSEA, ConditionTypes.NAUSEA_CONSULTATION],
  [UrlConditionTypes.URINARY_TRACT_INFECTION, ConditionTypes.URINARY_TRACT_INFECTION],
  [UrlConditionTypes.VAGINAL_YEAST_INFECTION, ConditionTypes.VAGINAL_YEAST_INFECTION],
  [UrlConditionTypes.TYPHOID_FEVER, ConditionTypes.TYPHOID_FEVER],
  [UrlConditionTypes.PLUS, ConditionTypes.RUNWAY_PLUS],
]);

export interface Medication {
  // TODO: Enhance software design.
  /**
   * The id of a medication should be required, however
   * since, those are variables wrapped within a
   * stripe's metadata object, which are optional, then,
   * we need to follow its rules. To avoid this dependency
   * from a Stripe Object, we need to enhance our design.
   */
  id?: string;
  name?: string;
  quantity: number;
}
export interface Condition {
  product: Product;
  suggestedMedication: Medication;
  price?: number;
}
