import "./SyncState.scss";

import { useState } from "react";
import { useHistory } from "react-router-dom";

import Header from "@components/elements/Header";
import { PageConsultation } from "@components/layouts/PageConsultation";
import YesNoQuestion from "@components/templates/YesNoQuestion";
import { useTitle } from "@hooks/useTitle";
import { Text, Title } from "@runwayhealth/runway-components-react";

const SyncState = () => {
  const [answer, setAnswer] = useState("");
  const history = useHistory();
  const updateAnswer = (answer: string) => {
    setAnswer(answer);
    if (answer === "Yes") {
      history.push("/date");
    }
  };
  useTitle("Call required");

  return (
    <>
      <Header progress={2} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">
            Telehealth regulations in your state require your clinician to contact you via phone
            during clinical review. Would you like to proceed?
          </Title>
          <Text size="md" className="choose-option">
            Choose one option
          </Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <YesNoQuestion option="" setAnswer={updateAnswer} isActiveSecond={answer === "No"} />
          {answer === "No" && (
            <Text size="md" className="sync-feedback">
              Please reach out to our support at{" "}
              <a href="mailto:support@runwayhealth.com" className="email-message-text">
                support@runwayhealth.com
              </a>{" "}
              if you have questions.
            </Text>
          )}
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default SyncState;
