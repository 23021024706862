import "./style.scss";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import zipState from "zip-state";

import { State } from "@components/pages/WhatState/stateAvailability";
import { Button, Text, Title } from "@runwayhealth/runway-components-react";
import {
  CardBrands,
  CardFunding,
  PaymentManagerSetup,
  PaymentMethod,
} from "@store/../@types/billing";
import { ConditionTypes } from "@store/../@types/condition";
import * as casesActions from "@store/cases/casesActions";
import { LOGOUT } from "@store/user/userActions";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { toastInfo } from "@utils/utils";

import { Case } from "../../../../@types/case";
import { ErrorEvents, RootEntity, RootState } from "../../../../@types/state";
import { User } from "../../../../@types/user";
import AmexSvg from "../../../../img/card/Amex.svg";
import CvcSvg from "../../../../img/card/Cvc.svg";
import Discover from "../../../../img/card/Discover.svg";
import MasterCardSvg from "../../../../img/card/MasterCard.svg";
import VisaSvg from "../../../../img/card/Visa.svg";
import AutocompleteInput from "../../../elements/AutocompleteInput";
import { SavePayButtonContainer } from "../../../elements/PaymentMethod";

const states = require("../../../pages/WhatState/states.json");

const OPTIONS = {
  style: {
    fonts: [
      {
        src: require("../../../../fonts/Gustavo/Gustavo-Regular.otf"),
        family: "Gustavo",
      },
    ],
    base: {
      iconColor: "black",
      color: "black",
      fontWeight: 500,
      fontFamily: "inherit",
      fontSize: "20px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
        fontSize: "20px",
        lineHeight: "18px",
      },
      "::placeholder": {
        color: "rgba(57, 57, 57, 0.3)",
        fontSize: "18px",
        lineHeight: "18px",
      },
    },
    invalid: {
      iconColor: "black",
      color: "black",
    },
  },
};

const ADD_PAYMENT_FORM_TITLE = "Add a new card";
const EDIT_PAYMENT_FORM_TITLE = "Edit payment information";

type PaymentFormProps = {
  save: (paymentMethod?: PaymentMethod, pendingCase?: boolean) => void;
  paymentMethods: PaymentMethod[];
  managerSetup: PaymentManagerSetup;
  promoCodeId?: string;
  newCase: RootEntity<Case>;
  editablePaymentMethodId?: string;
};

const PaymentForm = ({
  save,
  paymentMethods,
  managerSetup,
  promoCodeId,
  newCase,
  editablePaymentMethodId,
}: PaymentFormProps) => {
  const dispatch = useDispatch();
  const [editablePaymentMethod, setEditablePaymentMethod] = useState<PaymentMethod | undefined>(
    undefined
  );
  const [state, setState] = useState(
    editablePaymentMethod?.billingDetails.address.state_name ?? ""
  );
  const [disabledButton, setDisabledButton] = useState(false);
  const [zipcode, setZipcode] = useState({
    abbreviationZipcode: "",
    value: "",
  });
  const [billingState, setBillingState] = useState({
    email_checkout: "",
    name: "",
    zip_code: "",
  });
  const [errorText, setErrorText] = useState({
    email: "",
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
    name: "",
    state: "",
    zipcode: "",
  });

  const [openStateDropdown, setOpenStateDropdown] = useState(false);

  // Using only abstract data for validation
  const [cardState, setCardState] = useState({
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
  });
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);

  const stripe = useStripe();
  const elements = useElements();
  const pendingCase = newCase.data.id && !newCase.isPending ? true : false;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisabledButton(false);
    const { target } = e;

    if (target.name === "zip_code") {
      const abbreviationZipcode = zipState(target.value) || "";
      setZipcode({ abbreviationZipcode, value: target.value });
    }
    setBillingState((prev) => ({ ...prev, [target.name]: target.value }));
  };

  const handleBlurZip = () => {
    // TODO: Assing type to any.
    const abbreviation = states.find((i: any) => i.name === state);
    if (billingState.zip_code.length < 5) {
      setErrorText((prev) => ({
        ...prev,
        zipcode: "Please enter your ZIP code.",
      }));
      return false;
    }

    if (!abbreviation) {
      setErrorText((prev) => ({
        ...prev,
        state: "Not a valid state",
      }));
      return false;
    }

    if (abbreviation && abbreviation.abbreviation !== zipcode.abbreviationZipcode) {
      setErrorText((prev) => ({
        ...prev,
        zipcode:
          "This ZIP Code does not correspond to the selected state. Please enter a valid ZIP Code",
      }));
      return false;
    }
    setErrorText((prev) => ({
      ...prev,
      zipcode: "",
    }));
    return true;
  };

  const validateEmail = () => {
    const emailFormat =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (!emailFormat.test(billingState.email_checkout)) {
      setErrorText((prev) => ({ ...prev, email: "Please enter your email address" }));
      return false;
    }
    setErrorText((prev) => ({ ...prev, email: "" }));
    return true;
  };

  const validateName = () => {
    if (!billingState.name) {
      setErrorText((prev) => ({
        ...prev,
        name: "Please enter the name on your card.",
      }));
      return false;
    }
    setErrorText((prev) => ({
      ...prev,
      name: "",
    }));
    return true;
  };

  const validateCardNumber = () => {
    if (!cardState.cardNumber) {
      setErrorText((prev) => ({
        ...prev,
        cardNumber: "Please enter a valid card number.",
      }));
      return false;
    } else {
      const { complete, error } = cardState.cardNumber;
      if (complete === false || error !== undefined) {
        setErrorText((prev) => ({
          ...prev,
          cardNumber: "Please enter a valid card number.",
        }));
        return false;
      } else {
        setErrorText((prev) => ({
          ...prev,
          cardNumber: "",
        }));
        return true;
      }
    }
  };

  const validateCardDate = () => {
    if (!cardState.cardExpiry) {
      setErrorText((prev) => ({
        ...prev,
        cardExpiry: "Please enter a valid expiry date.",
      }));
      return false;
    } else {
      const { complete, error } = cardState.cardExpiry;
      if (complete === false || error !== undefined) {
        setErrorText((prev) => ({
          ...prev,
          cardExpiry: "Please enter a valid expiry date.",
        }));
        return false;
      } else {
        setErrorText((prev) => ({
          ...prev,
          cardExpiry: "",
        }));
        return true;
      }
    }
  };

  const validateCardCvc = () => {
    if (!cardState.cardCvc) {
      setErrorText((prev) => ({
        ...prev,
        cardCvc: "Please enter a valid security number.",
      }));
      return false;
    } else {
      const { complete, error } = cardState.cardCvc;
      if (complete === false || error !== undefined) {
        setErrorText((prev) => ({
          ...prev,
          cardCvc: "Please enter a valid security number.",
        }));
        return false;
      } else {
        setErrorText((prev) => ({
          ...prev,
          cardCvc: "",
        }));
        return true;
      }
    }
  };

  const validateState = () => {
    if (state.length < 4) {
      setErrorText((prev) => ({
        ...prev,
        state: "Please enter your state",
      }));
      return false;
    }
    if (billingState.zip_code) {
      handleBlurZip();
    }
    setErrorText((prev) => ({
      ...prev,
      state: "",
    }));
    return true;
  };

  const createPaymentMethod = async (): Promise<PaymentMethod | undefined> => {
    const isValid = formIsValid();
    if (isValid) {
      setDisabledButton(true);
      const card = elements?.getElement(CardNumberElement) || { token: "" };
      const newPaymentMethodResult = await stripe?.createPaymentMethod({
        type: "card",
        card,
        billing_details: {
          name: billingState.name,
          email: billingState.email_checkout,
          address: {
            postal_code: billingState.zip_code,
            state,
          },
        },
      });
      const paymentMethod = newPaymentMethodResult?.paymentMethod;
      return {
        id:
          (managerSetup === PaymentManagerSetup.EDIT
            ? editablePaymentMethod?.id
            : paymentMethod?.id) || "",
        billingDetails: {
          address: {
            address: paymentMethod?.billing_details.address?.line1 || "",
            address2: "",
            city_name: paymentMethod?.billing_details.address?.city || "",
            zip_code: billingState.zip_code,
            state_name: state,
          },
          email: paymentMethod?.billing_details.email || "",
          name: paymentMethod?.billing_details.name || "",
        },
        card: {
          brand: (paymentMethod?.card?.brand as CardBrands) || "",
          expMonth: paymentMethod?.card?.exp_month || 1,
          expYear: paymentMethod?.card?.exp_year || 1,
          funding: (paymentMethod?.card?.funding as CardFunding) || CardFunding.DEBIT,
          last4: paymentMethod?.card?.last4 || "",
        },
      };
    }
  };

  const submitPaymentMethod = async () => {
    switch (managerSetup) {
      case PaymentManagerSetup.ADD:
        const newPaymentMethod = await createPaymentMethod();
        if (newPaymentMethod) {
          save(newPaymentMethod, pendingCase);
        }
        break;
      case PaymentManagerSetup.EDIT:
        const paymentMethodUpdate = await createPaymentMethod();
        if (paymentMethodUpdate) {
          save(paymentMethodUpdate);
        }
        break;
      case PaymentManagerSetup.SET_DEFAULT:
        // Save current selected payment method.
        save(undefined, pendingCase);
        break;
    }

    // If there is a pending case, process it.
    if (pendingCase) {
      processPendingCase();
    }

    setDisabledButton(false);
  };

  const processPendingCase = () => {
    dispatch({
      type: casesActions.PAY_CASE,
      newCase: newCase.data,
      promoCodeId,
      transaction: user.data?.partnerTx,
    });

    const consultations = JSON.parse(sessionStorage.getItem("consultations") ?? "[]");
    dispatch({
      type: casesActions.SEND_CASE,
      newCase: newCase.data,
      isPlus: consultations.includes(ConditionTypes.RUNWAY_PLUS) ?? consultations.length === 0,
    });
  };

  const formIsValid = () => {
    if (!validateEmail()) {
      return false;
    }
    if (!validateCardNumber()) {
      return false;
    }
    if (!validateCardDate()) {
      return false;
    }
    if (!validateCardCvc()) {
      return false;
    }
    if (!validateName()) {
      return false;
    }
    if (!validateState()) {
      return false;
    }
    if (!handleBlurZip()) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (editablePaymentMethodId) {
      // Load payment method
      const paymentMethod = user.data.paymentMethods.find(
        (paymentMethod) => paymentMethod.id === editablePaymentMethodId
      );
      setEditablePaymentMethod(paymentMethod);
    } else {
      setEditablePaymentMethod(undefined);
    }
  }, [editablePaymentMethodId]);

  useEffect(() => {
    const errorMessage = newCase?.error?.message || "";
    const showErrorMessage =
      !errorMessage.includes("Unknown") &&
      !errorMessage.toLocaleLowerCase().includes("sequelize") &&
      errorMessage.length > 0;
    if (newCase.error.event === ErrorEvents.ACCESS_DENIED) {
      sessionExpired();
    } else if (showErrorMessage) {
      if (errorMessage !== ErrorEvents.PENDING_INVOICE) {
        callToast(errorMessage);
      }
    }
  }, [newCase.error]);

  useEffect(() => {
    const errorMessage = user?.error?.message || "";
    const showErrorMessage =
      !errorMessage.includes("Unknown") &&
      !errorMessage.toLocaleLowerCase().includes("sequelize") &&
      errorMessage.length > 0;
    if (user.error.event === ErrorEvents.ACCESS_DENIED) {
      sessionExpired();
    } else if (showErrorMessage) {
      setDisabledButton(false);
      callToast(errorMessage);
    }
  }, [user.error]);

  const sessionExpired = () => {
    toastInfo("Session expired", "Please login again to continue...");
    dispatch({
      type: LOGOUT,
      userId: user.data.id,
    });
  };

  const callToast = (message: string) => {
    toast(message, {
      position: "top-center",
      autoClose: 3000,
      closeButton: true,
      hideProgressBar: true,
    });
  };

  return (
    <>
      {paymentMethods.length === 0 && !pendingCase && (
        <Text>
          You don't have any payment method registered with us. Feel free to add one using the form
          below.
        </Text>
      )}
      {managerSetup !== PaymentManagerSetup.SET_DEFAULT && (
        <Title className="form-title" as="h2" size="md-bold">
          {managerSetup === PaymentManagerSetup.ADD
            ? ADD_PAYMENT_FORM_TITLE
            : EDIT_PAYMENT_FORM_TITLE}
        </Title>
      )}
      <div className={"payment-form"}>
        {!newCase.isPending && managerSetup !== PaymentManagerSetup.SET_DEFAULT && (
          <fieldset className="FormGroup">
            <p className="checkout-email-label">EMAIL</p>
            <div className="FormRow">
              <input
                type="email"
                className={"payment-input"}
                placeholder={"Email"}
                name={"email_checkout"}
                id="email_checkout"
                defaultValue={editablePaymentMethod?.billingDetails.email}
                autoComplete="on"
                onChange={handleChange}
                onBlur={() => validateEmail()}
                onFocus={() => setErrorText((prev) => ({ ...prev, email: "" }))}
              />
            </div>
            <div
              className={
                errorText.email ? "error-message-payment" : "error-message-payment nonactive"
              }
            >
              <p className="message-content">{errorText.email}</p>
            </div>
            <div className="row g-0">
              <div className="col-md-6 card-number">
                <CardNumberElement
                  onChange={(e) => {
                    setDisabledButton(false);
                    setCardState((prev: any) => ({ ...prev, cardNumber: e }));
                  }}
                  onBlur={() => validateCardNumber()}
                  onFocus={() => setErrorText((prev) => ({ ...prev, cardNumber: "" }))}
                  options={OPTIONS}
                />
              </div>
              <div className="col-md-6 cards-svg">
                <img src={VisaSvg} alt="" />
                <img src={MasterCardSvg} alt="" />
                <img src={AmexSvg} alt="" />
                <img src={Discover} alt="" />
              </div>
            </div>
            <div
              className={
                errorText.cardNumber ? "error-message-payment" : "error-message-payment nonactive"
              }
            >
              <p className="message-content">{errorText.cardNumber}</p>
            </div>
            <div className="FormColumn">
              <div className="FormRow card-data-cvc">
                <CardExpiryElement
                  onChange={(e) => {
                    setDisabledButton(false);
                    setCardState((prev: any) => ({ ...prev, cardExpiry: e }));
                  }}
                  onFocus={() => setErrorText((prev) => ({ ...prev, cardExpiry: "" }))}
                  options={OPTIONS}
                />
                <div
                  className={
                    errorText.cardExpiry
                      ? "error-message-payment"
                      : "error-message-payment nonactive"
                  }
                >
                  <p className="message-content">{errorText.cardExpiry}</p>
                </div>
              </div>
              <div className="FormRow card-data-cvc">
                <div className={"cvc"}>
                  <CardCvcElement
                    onChange={(e) => {
                      setDisabledButton(false);
                      setCardState((prev: any) => ({ ...prev, cardCvc: e }));
                    }}
                    onBlur={() => {
                      validateCardCvc();
                    }}
                    onFocus={() => setErrorText((prev) => ({ ...prev, cardCvc: "" }))}
                    options={OPTIONS}
                    className={"cvc-input"}
                  />
                  <img src={CvcSvg} height="30" alt="" />
                </div>
                <div
                  className={
                    errorText.cardCvc ? "error-message-payment" : "error-message-payment nonactive"
                  }
                >
                  <p className="message-content">{errorText.cardCvc}</p>
                </div>
              </div>
            </div>

            <div className="FormRow">
              <input
                type="text"
                autoComplete="off"
                className={"payment-input"}
                placeholder={"Name on card"}
                name={"name"}
                onChange={handleChange}
                onBlur={validateName}
                onFocus={() => setErrorText((prev) => ({ ...prev, name: "" }))}
                defaultValue={editablePaymentMethod?.billingDetails.name}
              />
            </div>
            <div
              className={
                errorText.name ? "error-message-payment" : "error-message-payment nonactive"
              }
            >
              <p className="message-content">{errorText.name}</p>
            </div>
            <p className="checkout-email-label">STATE</p>
            <div className="FormColumn">
              <div className="FormRow state">
                <AutocompleteInput
                  open={openStateDropdown}
                  onKeyUp={() => setOpenStateDropdown(true)}
                  options={states.map((state: State) => state.name)}
                  width={"300px"}
                  placeholder="State"
                  capturedVariant={(e) => {
                    setState(e);
                    setOpenStateDropdown(false);
                  }}
                  handleBlur={validateState}
                  onFocus={() => setErrorText((prev) => ({ ...prev, state: "" }))}
                />
                <div
                  className={
                    errorText.state ? "error-message-payment" : "error-message-payment nonactive"
                  }
                >
                  <p className="message-content">{errorText.state}</p>
                </div>
              </div>
              <div className="FormRow state">
                <input
                  type="number"
                  className={"payment-input"}
                  placeholder={"Zipcode"}
                  name={"zip_code"}
                  autoComplete="off"
                  onChange={handleChange}
                  // TODO: Find event type for TS
                  onInput={(e: any) => (e.target.value = e.target.value.slice(0, 10))}
                  onBlur={handleBlurZip}
                  onFocus={() => setErrorText((prev) => ({ ...prev, zipcode: "" }))}
                  defaultValue={editablePaymentMethod?.billingDetails.address.zip_code}
                />
                <div
                  className={
                    errorText.zipcode ? "error-message-payment" : "error-message-payment nonactive"
                  }
                >
                  <p className="message-content">{errorText.zipcode}</p>
                </div>
              </div>
            </div>
          </fieldset>
        )}
        <SavePayButtonContainer>
          <Button
            size="lg"
            isLoading={newCase.isPending || user.isPending}
            disabled={disabledButton || user.isPending || newCase.isPending}
            onClick={submitPaymentMethod}
            iconName={pendingCase ? "ArrowRight" : ""}
            customWidth={400}
          >
            {pendingCase ? "Place Order" : "Save"}
          </Button>
        </SavePayButtonContainer>
      </div>
    </>
  );
};

export default PaymentForm;
