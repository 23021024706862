import React from "react";
import "./LongInput.scss";

const LongInput = ({ placeholder, onFocus, handleChange, name, setErrorText, value }) => {
  const validationStateBlur = (e) => {
    if (e.target.value.length <= 2) {
      setErrorText("Please enter the country or region you will be going to");
    } else {
      setErrorText(null);
    }
  };

  const validationStateOnChange = (e) => {
    if (e.target.value.length >= 3) {
      setErrorText(null);
    }
    handleChange(e.target.value);
  };

  return (
    <div className="long-input">
      {name ? (
        <input
          name={name}
          type="text"
          className="custom-long-input"
          placeholder={placeholder}
          onFocus={onFocus}
          onChange={handleChange}
          value={value}
        />
      ) : (
        <input
          type="text"
          className="custom-long-input"
          placeholder={placeholder}
          onBlur={(e) => validationStateBlur(e)}
          onChange={(e) => validationStateOnChange(e)}
          value={value}
        />
      )}
    </div>
  );
};

export default LongInput;
