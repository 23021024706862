import "./PhotoPrivacyFooter.scss";

import React from "react";

import { Text } from "@runwayhealth/runway-components-react";

export const PhotoPrivacyFooter = () => {
  return (
    <Text size="sm" className="privacy-footer">
      * Your data will be managed in compliance with HIPAA privacy regulations and won't be stored
      on our servers after your doctor has reviewed it.
    </Text>
  );
};
