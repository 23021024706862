export const ERROR_CHANGE = "ERROR_CHANGE";
export const error_change = (payload) => ({
  type: ERROR_CHANGE,
  payload,
});
export const LOADING_CHANGE = "LOADING_CHANGE";
export const loading_change = (payload) => ({
  type: LOADING_CHANGE,
  payload,
});
