import { useHistory } from "react-router-dom";

interface AuthRouteProps {
  component: React.ComponentType;
}

const AuthRoute = ({ component: Component }: AuthRouteProps) => {
  const history = useHistory();
  const isAuth = localStorage.getItem("token");
  return <>{isAuth ? <Component /> : history.push("/login")}</>;
};

export default AuthRoute;
