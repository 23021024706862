import AmexSvg from "../img/card/Amex.svg";
import Discover from "../img/card/Discover.svg";
import MasterCardSvg from "../img/card/MasterCard.svg";
import VisaSvg from "../img/card/Visa.svg";
import { Address } from "./user";

export interface PaymentMethod {
  id: string;
  billingDetails: {
    address: Address;
    email: string;
    name: string;
  };
  card: {
    brand: CardBrands;
    expMonth: number;
    expYear: number;
    funding: CardFunding;
    last4: string;
  };
}

export enum PaymentManagerSetup {
  ADD = "add",
  EDIT = "edit",
  SET_DEFAULT = "setDefault",
}

export enum CardFunding {
  CREDIT = "credit",
  DEBIT = "debit",
  PREPAID = "prepaid",
  UNKNOWN = "unknown",
}

export enum CardBrands {
  AMEX = "amex",
  DINERS = "diners",
  DISCOVER = "discover",
  MASTERCARD = "mastercard",
  VISA = "visa",
  UNKNOWN = "unknown",
}

export const CardLogoIndex = new Map<string, string>();
CardLogoIndex.set(CardBrands.AMEX, AmexSvg);
CardLogoIndex.set(CardBrands.DISCOVER, Discover);
CardLogoIndex.set(CardBrands.MASTERCARD, MasterCardSvg);
CardLogoIndex.set(CardBrands.VISA, VisaSvg);
