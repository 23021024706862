import "./GenderButtons.scss";

interface GenderButtonProps {
  setAnswer: (answer: string) => void;
}
const GenderButtons = ({ setAnswer }: GenderButtonProps) => {
  return (
    <div className="choice-buttons">
      <button className="choice-male" onClick={() => setAnswer("Male")}>
        Male
      </button>
      <button className="choice-other" onClick={() => setAnswer("Female")}>
        Female
      </button>
    </div>
  );
};

export default GenderButtons;
