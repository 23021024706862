import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Chip, TextField } from "@material-ui/core";
import { Credentials, Partner, RootState, Transaction } from "@store/../@types/state";
import {
  deletePartner,
  generatePartnerCredentials,
  searchPartners,
  searchTransactions,
} from "@store/admin/adminAction";

import { getInvoiceIdLink } from "./CaseSearch";

const PartnerManager = () => {
  const dispatch = useDispatch();
  const credentials = useSelector<RootState, Credentials>((state) => state.admin.credentials);
  const partners = useSelector<RootState, Partner[]>((state) => state.admin.partners);
  const transactions = useSelector<RootState, Transaction[]>((state) => state.admin.transactions);
  const [partnerName, setPartnerName] = useState("");

  useEffect(() => {
    dispatch(searchPartners({}));
  }, []);

  const getCredentials = () => {
    dispatch(generatePartnerCredentials(partnerName));
  };

  const getPartnerTransactions = (partnerId: string) => {
    dispatch(searchTransactions(partnerId));
  };

  const removePartner = (partnerId: string) => {
    dispatch(deletePartner(partnerId));
  };

  return (
    <section>
      <h2>Partner Management 👩‍💼</h2>
      <p>Onboard or remove partners</p>
      <div className="noMargin">
        <h3>Add a new partner</h3>
        <p>A name is required to register a new partner, the server will return its credentials.</p>
        <TextField
          type="text"
          id="partner"
          value={partnerName}
          onChange={(evt) => {
            setPartnerName(evt.target.value);
          }}
          label="Enter partner name"
          required={true}
        />
        {credentials.clientKey && (
          <div id="partner-credentials">
            <div id="credentials-suggestion-message">
              {" "}
              <i>
                Please save the <b>secret key</b> in a safe place, this key will only be visible
                now, and won't be accessible later.
              </i>
            </div>
            <Box>
              <table>
                <thead>
                  <tr>
                    <th>API Key</th>
                    <th>Secret Key</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{credentials.clientKey}</td>
                    <td>{credentials.clientSecret}</td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </div>
        )}
        <div>
          <Button variant="contained" color="primary" onClick={getCredentials}>
            Get Credentials
          </Button>
        </div>
      </div>
      <div className="noMargin">
        <h3>Search partners</h3>
        <Box>
          {partners.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>API Key</th>
                  <th>Transactions</th>
                </tr>
              </thead>
              <tbody>
                {partners.map((partner) => {
                  return (
                    <tr key={partner.clientKey}>
                      <td>{partner.name}</td>
                      <td>{partner.clientKey}</td>
                      <td
                        className="td-pointer"
                        onClick={() => {
                          getPartnerTransactions(partner.clientKey);
                        }}
                      >
                        {partner.transactionsCount} 👁️‍🗨️
                      </td>
                      <td
                        className="td-pointer"
                        onClick={() => {
                          removePartner(partner.clientKey);
                        }}
                      >
                        🗑️
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </Box>
      </div>
      <div className="noMargin">
        <h3>Search transactions</h3>
        <Box>
          {transactions.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>User Email</th>
                  <th>Invoice</th>
                  <th>Partner ID</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction) => {
                  return (
                    <tr key={transaction.id}>
                      <td>{transaction.id}</td>
                      <td>{transaction.userEmail}</td>
                      <td>{getInvoiceIdLink(transaction.invoiceId || "❌")}</td>
                      <td>{transaction.PartnerClientKey}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </Box>
      </div>
    </section>
  );
};

export default PartnerManager;
