import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import Header from "@elements/Header";
import RadioButton from "@elements/RadioButton";
import RadioButtonInput from "@elements/RadioButtonInput";
import { useTitle } from "@hooks/useTitle";
import { Button, Title } from "@runwayhealth/runway-components-react";
import { RootEntity, RootState } from "@store/../@types/state";
import { questionsUpdate } from "@store/questions/questionsSlice";

import { User } from "../../../@types/user";

const MarketingChannels = () => {
  const variants = [
    "Travel agent or supplier",
    "Friend or family member",
    "Google",
    "Bing",
    "Social Media",
  ];
  const [state, setState] = useState([false, false, false, false, false, false]);
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const isAMinor = () => {
    const today = new Date();
    let dateOfBirth = new Date(user.data.date_of_birth) ?? new Date();

    const differenceInMilliSeconds: number = today.getTime() - dateOfBirth.getTime();

    const milliSecondsInAyear = 31536000000;

    const usersAge = differenceInMilliSeconds / milliSecondsInAyear;
    if (usersAge >= 18) {
      return false;
    }

    return true;
  };

  const onKeyUpHandler = (e: React.KeyboardEvent) => {
    e.key == "Enter" && setAnswer();
  };

  const capturedVariant = state
    .map((item, i) => (item ? variants[i] : false))
    .filter((variant) => variant)
    .join("");

  const setAnswer = () => {
    if (capturedVariant || customValue !== "") {
      dispatch(
        questionsUpdate({
          question: "How did you hear about Runway?",
          value: capturedVariant || customValue,
          questionType: "general",
          important: false,
        })
      );
      if (isAMinor()) {
        history.push("/parental_consent");
      } else {
        history.push("/id");
      }
    }
  };

  const [customValue, setCustomValue] = useState("");
  const handleChange = (e: any) => setCustomValue(e.target.value);

  useTitle("How did you hear about Runway?");

  return (
    <>
      <Header progress={10} />
      <PageConsultation tabIndex={1} onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">How did you hear about Runway?</Title>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <div className="rw-page-consultation-list-checkboxes">
            {variants.map((variant, index) => (
              <RadioButton
                key={`option-${index}`}
                content={variant}
                checked={state[index]}
                setChecked={setState}
                num={index}
              />
            ))}
            <RadioButtonInput
              key={`option-${5}`}
              placeholder={"Somewhere else"}
              checked={state[5]}
              setChecked={setState}
              setValue={handleChange}
              value={customValue}
              num={5}
            />
          </div>
        </PageConsultation.Body>

        <Button
          size="lg"
          iconName="ArrowRight"
          customWidth={440}
          onClick={setAnswer}
          disabled={!capturedVariant && customValue === ""}
        >
          Next
        </Button>
      </PageConsultation>
    </>
  );
};

export default MarketingChannels;
