import "./pharmacyFind.scss";
import Header from "../../elements/Header";
import { useEffect, useState } from "react";
import PharmacyBlock from "./components/pharmacyBlock/pharmacyBlock";
import { md } from "../../../api";

import arrowRightSvg from "../../../img/ArrowRightBlack.svg";
import PharmacyAlert from "./components/pharmacyAlert/PharmacyAlert";
import { useDispatch, useSelector } from "react-redux";
import { shippingAddressUpdate, userUpdate } from "../../../store/user/userSlice";
import { questionsUpdate } from "@store/questions/questionsSlice";
import { changeHomeStatusAddress } from "../../../store/ui/uiSlice";
import { useHistory } from "react-router-dom";
import { useTitle } from "@hooks/useTitle";

const PharmacyFind = () => {
  //Checkbox statuses
  const [zipCodeStatus, setZipCodeStatus] = useState(true);
  const [addressStatus, setAddressStatus] = useState(false);
  //State for data storage from input
  const [zipCode, setZipCode] = useState(null);
  const [address, setAddress] = useState(null);
  //state for storing data received from the request
  const [pharmacy, setPharmacy] = useState(null);
  //state for storing error
  const [errorText, setErrorText] = useState("");
  //state for storing states -loading, error, success, ''
  const [status, setStatus] = useState("");
  //State for pharmacy selection and checkbox status
  const [checkboxState, setCheckboxState] = useState([]);
  const history = useHistory();
  const newCase = useSelector((state) => state.case.newCase);
  const dispatch = useDispatch();
  useTitle("Find your Pharmacy");
  const checkoutChange = (type) => {
    if (type === "zip") {
      setZipCodeStatus((prevState) => !prevState);
      setAddressStatus((prevState) => !prevState);
      setStatus("");
    } else {
      setAddressStatus((prevState) => !prevState);
      setZipCodeStatus((prevState) => !prevState);
      setStatus("");
    }
  };
  useEffect(() => {
    setZipCode("");
    setAddress("");
  }, [zipCodeStatus, addressStatus]);
  //Request to get data using zip code

  useEffect(() => {
    if (zipCode?.length === 5 || zipCode?.length === 10) {
      setStatus("loading");
      md.mdPost("save_pharmacy", { zip: zipCode })
        .then((res) => {
          //the server does not return the exact result when searching by one value, I had to add additional filters
          const result = Object.values(res.data).filter((i) => i.zip_code.includes(zipCode));
          setPharmacy(result);
          if (result.length < 1) {
            setStatus("error");
          } else {
            setStatus("success");
          }
        })
        .catch((err) => {
          setStatus("error");
        });
    }
  }, [zipCode]);
  //Request to get data using address
  useEffect(() => {
    if (address?.length > 2) {
      setStatus("loading");
      md.mdPost("save_pharmacy", { address: address })
        .then((res) => {
          //the server does not return the exact result when searching by one value, I had to add additional filters
          const result = Object.values(res.data).filter((i) =>
            i.address1.toLowerCase().includes(address.toLowerCase())
          );
          setPharmacy(result);
          if (result.length < 1) {
            setStatus("error");
          } else {
            setStatus("success");
          }
        })
        .catch((err) => {
          setStatus("error");
        });
    }
  }, [address]);
  const [currentPharmacy, setCurrentPharmacy] = useState();

  useEffect(() => {
    setErrorText("");
  }, [checkboxState]);

  const start = () => {
    if (!checkboxState.includes(true)) {
      return setErrorText("Please select a pharmacy to proceed");
    } else {
      dispatch(
        shippingAddressUpdate({
          address: currentPharmacy?.address1,
          address2: currentPharmacy?.address2,
          city_name: currentPharmacy?.city,
          zip_code: currentPharmacy?.zip_code,
          state_name: currentPharmacy?.state,
          phone_number: currentPharmacy?.primary_phone,
          pharmacy_id: currentPharmacy?.id,
        })
      );
      dispatch(userUpdate({ name: "pharmacy_id", value: currentPharmacy?.id }));
      dispatch(changeHomeStatusAddress());

      if (newCase.data.conditions.length === 0) {
        setPharmacyQuestion();
      }

      history.push("/shipping");
    }
  };

  const setPharmacyQuestion = () => {
    dispatch(
      questionsUpdate({
        question: "If prescribed, where would you like your medication shipped?",
        value: "Local Pharmacy",
        important: true,
        questionType: "general",
      })
    );
  };

  return (
    <div className={"pharmacy-find"}>
      <Header progress={90} />
      <div className="pharmacy-find-container">
        <h1 className={"pharmacy-find-headline"}>Choose Your Pharmacy</h1>
        <p className={"pharmacy-find-subtitle"}>
          If prescribed, we will send your prescription to your local pharmacy of choice.
        </p>
        <span className={"search-title"}>Search by</span>
        <div className="pharmacy-find-checkouts">
          <div className="checkout">
            <div className="checkout-find" onClick={() => checkoutChange("zip")}>
              {zipCodeStatus && <div className="status" />}
            </div>
            <span>Zip code</span>
          </div>
          <div className="checkout">
            <div className="checkout-find" onClick={() => checkoutChange("address")}>
              {addressStatus && <div className="status" />}
            </div>
            <span>Pharmacy Address</span>
          </div>
        </div>
        {zipCodeStatus && (
          <div className="find-input zip">
            <input
              type="number"
              placeholder="Zip code"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
            />
          </div>
        )}
        {addressStatus && (
          <div className="find-input name">
            <input
              type="text"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
        )}
        {(status === "loading" || status === "error") && (
          <div className="loading-container">
            <PharmacyAlert status={status} />
          </div>
        )}
        {status === "success" && (
          <PharmacyBlock
            pharmacy={pharmacy}
            checkboxState={checkboxState}
            setCheckboxState={setCheckboxState}
            setCurrentPharmacy={setCurrentPharmacy}
            currentPharmacy={currentPharmacy}
          />
        )}
        <div className="pharmacy-find-button-wrapper">
          <div
            className={
              errorText ? "error-message-pharmacy-find" : "error-message-pharmacy-find nonactive"
            }
          >
            <p className="message-content">{errorText}</p>
          </div>
          <button className="pharmacy-find-button" onClick={start}>
            <span className="button-title">Save and Continue</span>{" "}
            <img src={arrowRightSvg} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PharmacyFind;
