import React, { useEffect, useState } from "react";

import { Feedback, FormGroupMessage } from "@runwayhealth/runway-components-react";
import { DateFieldValue } from "@store/../@types/state";
import { validateDate } from "@utils/utilsFormvalidation";

import ShortInput from "../ShortInput";

const dateFieldValues: DateFieldValue = {
  1: "",
  2: "",
  3: "",
  4: "",
  5: "",
  6: "",
  7: "",
  8: "",
};

interface IDatePickerProps {
  id: string;
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
}

const DatePicker = ({ id, date, setDate }: IDatePickerProps) => {
  const inputsShort = [
    { placeholder: "M", name: `field-1-${id}`, id: `${id}-1`, position: 1 },
    { placeholder: "M", name: `field-2-${id}`, id: `${id}-2`, position: 2 },
    { placeholder: "D", name: `field-3-${id}`, id: `${id}-3`, position: 3 },
    { placeholder: "D", name: `field-4-${id}`, id: `${id}-4`, position: 4 },
    { placeholder: "Y", name: `field-5-${id}`, id: `${id}-5`, position: 5 },
    { placeholder: "Y", name: `field-6-${id}`, id: `${id}-6`, position: 6 },
    { placeholder: "Y", name: `field-7-${id}`, id: `${id}-7`, position: 7 },
    { placeholder: "Y", name: `field-8-${id}`, id: `${id}-8`, position: 8 },
  ];

  const [dateFields, setDateFields] = useState<DateFieldValue>(dateFieldValues);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    // If date and date is not today, load date in fields.
    const today = new Date().toDateString() === date.toDateString();
    if (date && !today) {
      const parsedDate = date
        .toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })
        .replaceAll("/", "")
        .split("");
      const dateMap = new Map<number, string>();
      parsedDate.forEach((value, key) => dateMap.set(key + 1, value));

      dateMap.forEach((value, key) => {
        setDateFields((prev) => ({ ...prev, [key as keyof DateFieldValue]: value }));
      });
    }
  }, []);

  const dateIsValid = () => {
    setError("");
    if (!validateDate(dateFields)) {
      setError("Invalid date");
      return;
    }

    const objToArr = Object.values(dateFields);

    let month = objToArr.join("").substring(0, 2);
    let day = objToArr.join("").substring(2, 4);
    let year = objToArr.join("").substring(4);

    setDate(new Date(`${year}-${month}-${day}`));
  };

  return (
    <>
      <div className="date-inputs">
        <div className="short-inputs-container">
          {inputsShort.map((item, index) => (
            <div
              key={`date-input-${index}`}
              className={"date-input-map"}
              onKeyUp={dateIsValid}
              onBlur={dateIsValid}
            >
              <ShortInput
                id={item.id}
                position={item.position}
                placeholder={item.placeholder}
                value={dateFields[(index + 1) as keyof DateFieldValue]}
                date={dateFields}
                setDate={setDateFields}
                setError={setError}
              />
              {item.position === 2 || item.position === 4 ? "-" : null}
            </div>
          ))}
        </div>
        <FormGroupMessage>
          {error && (
            <Feedback size="sm" isInvalid>
              {error}
            </Feedback>
          )}
        </FormGroupMessage>
      </div>
    </>
  );
};

export default DatePicker;
