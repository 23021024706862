import { all } from "redux-saga/effects";
import adminSaga from "./admin/adminSaga";
import userSaga from "./user/userSaga";
import pharmacySaga from "./pharmacy/pharmacySaga";
import caseSaga from "./cases/casesSaga";
import priceSaga from "./prices/pricesSaga";
import messageSaga from "./messages/messagesSaga";
import uiSaga from "./ui/uiSaga";
import socketSaga from "./socket/socketSaga";

function* saga() {
  yield all([
    userSaga(),
    caseSaga(),
    priceSaga(),
    messageSaga(),
    pharmacySaga(),
    uiSaga(),
    adminSaga(),
    socketSaga(),
  ]);
}

export default saga;
