import styled from "styled-components";

import { color, from, spacing } from "@runwayhealth/runway-components-react";

export const ShippingContainer = styled.div`
  width: 100%;

  article {
    margin-bottom: ${spacing.nano};
  }
`;

export const ShippingBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: ${spacing.xxs};

  @media ${from.mobileS} {
    justify-content: space-between;
    align-items: center;
    gap: ${spacing.xs};
  }

  .shipping-body {
    &-info {
      display: flex;
      flex-direction: column;
      gap: ${spacing.tiny};
      width: inherit;

      @media ${from.mobileS} {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 18%;
      }

      .info-second-column {
      }
    }

    &-price {
      width: fit-content;
      min-width: 76px;
      margin-right: ${spacing.xxs};

      .free-price {
        color: ${color.brand.primary.dark};
      }
    }
  }
`;
