// US Phone Area Codes
import areaCodes from "./areaCodesUS.json";
import { DateFieldValue } from "@store/../@types/state";
import dayjs from "dayjs";

export const validatePassword = (passwordValue: string) => passwordValue.length > 7;

export const validateFullname = (firstName: string, lastName: string) => {
  const validFormat = /^[A-Za-z@#$%^&*() !_-]+$/;
  if (!validFormat.test(firstName)) {
    return false;
  }

  if (!firstName || firstName === "") {
    return false;
  }
  if (!lastName || lastName === "") {
    return false;
  }
  return true;
};

export const validateName = (name: string) => {
  const validFormat = /^[A-Za-z@#$%^&*() !_-]+$/;
  if (!validFormat.test(name)) {
    return false;
  }

  if (!name) {
    return false;
  }

  return true;
};

export const validatePhone = (phoneValue: string) => {
  if (!phoneValue) {
    return false;
  }

  if (phoneValue.length < 13) {
    return false;
  }

  // Check correct format.
  const phoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
  const matchResult = phoneNumberRegex.test(phoneValue);

  if (!matchResult) {
    return false;
  }

  // Check correct area code.
  const parenthesesRegex = /\(([^)]+)\)/;
  const areaCodeMatch = parenthesesRegex.exec(phoneValue);
  const areaCode = parseInt(areaCodeMatch !== null ? areaCodeMatch[1] : "");

  if (!areaCodes.find((item) => item.NPA === areaCode)) {
    return false;
  }
  return true;
};

export const validateEmail = (email: string) => {
  const emailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (emailFormat.test(email)) {
    return true;
  } else {
    return false;
  }
};

export const validateDate = (date: DateFieldValue): dayjs.Dayjs | undefined => {
  const dateIsFilled = Object.values(date).every((el) => !!el === true);
  let localDate = { ...date };
  const objToArr = Object.values(localDate);

  let month = objToArr.join("").substring(0, 2);
  let day = objToArr.join("").substring(2, 4);
  let year = objToArr.join("").substring(4);

  const formattedDate = `${year}-${month}-${day}`;

  const today = dayjs(new Date());

  const passedDate = dayjs(formattedDate);

  // Missing or invalid (i.e. past) date
  if (objToArr.length !== 8 || objToArr.some((item) => item === "")) {
    return undefined;
  }

  if (new Date(formattedDate).toString() === "Invalid Date" || parseInt(year) > 2100) {
    return undefined;
  }

  if (passedDate.format("YYYY/MM/DD") < today.format("YYYY/MM/DD")) {
    return undefined;
  }

  if (!dateIsFilled) {
    return undefined;
  }

  return passedDate;
};
