import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import YesNoQuestion from "@components/templates/YesNoQuestion";
import { useTitle } from "@hooks/useTitle";
import { Button, FormGroup, Label, TextField, Title } from "@runwayhealth/runway-components-react";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import Header from "../../elements/Header";

const SideEffects = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [hasIssues, setHasIssues] = useState(false);
  const [issuesDetails, setIssuesDetails] = useState("");
  const currentQuestion = "Have you ever experienced issues with malaria medication in the past?";
  useTitle("Malaria Medication Issues");

  const setAnswer = () => {
    if (issuesDetails.length > 0) {
      dispatch(
        questionsUpdate({
          question: currentQuestion,
          value: issuesDetails,
          important: true,
          questionType: "malaria",
        })
      );
      history.push("/medications_plan");
    }
  };

  const setNo = (value: string) => {
    dispatch(
      questionsUpdate({
        question: currentQuestion,
        value,
        important: false,
        questionType: "malaria",
      })
    );

    history.push("/medications_plan");
  };

  const onKeyUpHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    e.key == "Enter" && setAnswer();
  };

  const YesAnswer = () => {
    setHasIssues(true);
  };

  const collectIssuesDetails = (e: React.FormEvent<HTMLInputElement>) => {
    setIssuesDetails(e.currentTarget.value);
  };

  return (
    <>
      <Header progress={5} />
      <PageConsultation tabIndex={1} onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">{currentQuestion}</Title>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <YesNoQuestion setAnswer={YesAnswer} setNo={setNo} problems={true} isActive={hasIssues} />

          {hasIssues && (
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-9 col-sm-10">
                <FormGroup className="rwa-page-any-problems-form-group">
                  <Label>
                    Please share additional details about the medication used and issues
                    encountered.
                  </Label>
                  <TextField
                    as="textarea"
                    value={issuesDetails}
                    onChange={collectIssuesDetails}
                    placeholder="Medication used and issues encountered."
                  />
                </FormGroup>
              </div>
            </div>
          )}
        </PageConsultation.Body>
        {hasIssues && (
          <Button
            size="lg"
            iconName="ArrowRight"
            customWidth={440}
            onClick={setAnswer}
            disabled={issuesDetails.trim().length === 0}
          >
            Next
          </Button>
        )}
      </PageConsultation>
    </>
  );
};

export default SideEffects;
