import { call, put, takeEvery } from "redux-saga/effects";

import { Condition } from "@store/../@types/condition";

import { stripe } from "../../api";
import * as pricesActions from "./pricesActions";
import * as pricesSlice from "./pricesSlice";

function* requestCasePrice({ priceId }: pricesActions.RequestCasePrice) {
  yield put(pricesSlice.requestCasePriceFailure({}));
  yield put(pricesSlice.requestCasePricePending());
  try {
    const { data } = yield call(() =>
      stripe.stripePost("prices", {
        pricesIds: [priceId],
      })
    );
    yield put(pricesSlice.requestCasePriceSuccess(data));
  } catch (error) {
    yield put(pricesSlice.requestCasePriceFailure(error));
  }
}

export function* requestPrescriptionsPrices({
  conditions,
}: pricesActions.RequestPrescriptionsPrices) {
  yield put(pricesSlice.requestPrescriptionsPricesFailure({}));
  yield put(pricesSlice.requestPrescriptionsPricesPending());
  const pricesIds = conditions.map((condition: Condition) => {
    return condition.product.metadata?.medication || condition.product.metadata?.medication_1;
  });

  try {
    const { data } = yield call(() =>
      stripe.stripePost("prices", {
        pricesIds,
      })
    );
    yield put(pricesSlice.requestPrescriptionsPricesSuccess(data));
  } catch (error) {
    yield put(pricesSlice.requestPrescriptionsPricesFailure(error));
  }
}

function* requestShippingPrice({ shippingRatesIds }: pricesActions.RequestShippingPrice) {
  yield put(pricesSlice.requestShippingPriceFailure({}));
  yield put(pricesSlice.requestShippingPricePending());
  try {
    const { data } = yield call(() =>
      stripe.stripePost("shipping-rates", {
        shippingRatesIds,
      })
    );
    yield put(pricesSlice.requestShippingPriceSuccess(data));
  } catch (error) {
    yield put(pricesSlice.requestShippingPriceFailure(error));
  }
}

export default function* pricesSaga() {
  yield takeEvery(pricesActions.REQUEST_CASE_PRICE, requestCasePrice);
  yield takeEvery(pricesActions.REQUEST_PRESCRIPTIONS_PRICES, requestPrescriptionsPrices);
  yield takeEvery(pricesActions.REQUEST_SHIPPING_PRICE, requestShippingPrice);
}
