import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import Checkbox from "@elements/Checkbox";
import CheckboxInput from "@elements/CheckboxInput";
import Header from "@elements/Header";
import { useTitle } from "@hooks/useTitle";
import { Button, Text, Title } from "@runwayhealth/runway-components-react";
import { questionsUpdate } from "@store/questions/questionsSlice";

export const RUNWAY_PLUS = "Runway+";

const RunwayPlus = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const visitReasons = [
    "General travel health information",
    "Medication needs",
    "Managing existing conditions abroad",
  ];

  const [ownAnswer, setOwnAnswer] = useState("");
  const [formState, setFormState] = useState([false, false, false, false]);
  const [captureVariants, setCaptureVariants] = useState<string[]>([]);
  const captureFromInput = (value: any) => {
    //@ts-ignore
    setCaptureVariants((prev) => [...prev, value]);
  };

  useEffect(() => {
    sessionStorage.setItem("current_consultation", "Runway+");
  }, []);

  useEffect(() => {
    formState?.forEach((item, index) => {
      return item === true && captureVariants.indexOf(visitReasons[index]) === -1
        ? setCaptureVariants((prev) => [...prev, visitReasons[index]])
        : null;
    });
  }, [formState]);

  useTitle("Runway+");

  let capturedVariant = formState
    ?.map((item, i) => (item ? visitReasons[i] : false))
    .filter((variant) => variant)
    .join(", ");

  const setAnswer = () => {
    if (capturedVariant || ownAnswer) {
      dispatch(
        questionsUpdate({
          question: "What would you like to talk about with your doctor?",
          value: ownAnswer ? `${ownAnswer}, ${capturedVariant}` : capturedVariant,
          important: true,
          questionType: "general",
        })
      );

      history.push("/allergies");
    }
  };

  const onClick = () => {
    formState?.forEach((item, index) => {
      return item === true && captureVariants.indexOf(visitReasons[index]) === -1
        ? setCaptureVariants((prev) => [...prev, visitReasons[index]])
        : null;
    });
  };

  const onKeyUpHandler = (e: { key: string }) => {
    e.key == "Enter" && setAnswer();
  };

  return (
    <>
      <Header progress={60} />
      <PageConsultation tabIndex={1} onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">What would you like to talk about with your doctor?</Title>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <div className="rw-page-consultation-list-checkboxes">
            {visitReasons.map((visitReason, index) => (
              <React.Fragment key={`plus-${index}`}>
                <Checkbox
                  content={visitReason}
                  checked={formState[index]}
                  setChecked={setFormState}
                  num={index}
                  disabled={false}
                  key={`checkbox-${index}`}
                  required={false}
                  onClick={onClick}
                />
              </React.Fragment>
            ))}
            <CheckboxInput
              disabled={false}
              isNone={false}
              checked={ownAnswer}
              setChecked={captureFromInput}
              num={captureVariants.length}
              setOwnAnswer={setOwnAnswer}
              capture={captureFromInput}
              key={"own-answer"}
              required={false}
            />
          </div>
        </PageConsultation.Body>

        <Button
          size="lg"
          iconName="ArrowRight"
          customWidth={440}
          onClick={setAnswer}
          disabled={!capturedVariant && !ownAnswer}
        >
          Next
        </Button>
      </PageConsultation>
    </>
  );
};

export default RunwayPlus;
