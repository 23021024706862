import useAmplitude from "../../../hooks/useAmplitude";

interface DestinationsVisitingProps {
  title?: string;
  question?: string;
  setAnswer: (answer: string) => void;
  option?: string;
}

const DestinationsVisiting = ({
  title,
  question,
  setAnswer,
  option = "Choose one option",
}: DestinationsVisitingProps) => {
  const { logEvent } = useAmplitude();
  const reportAndSetAnswer = (answer: string) => {
    logEvent("DESTINATION_TYPE_SELECTED", { answer });
    setAnswer(answer);
  };

  return (
    <div className="question-container">
      {title && <span className="slide-title">{title}</span>}
      {question && <div className="slide-question">{question}</div>}
      {option && <span className="slide-choice">{option}</span>}
      <div className="slide-buttons">
        <div className="choice-buttons">
          <button className="choice-male" onClick={() => reportAndSetAnswer("Cities")}>
            Cities
          </button>
          <button className="choice-female" onClick={() => reportAndSetAnswer("Rural areas")}>
            Rural areas
          </button>
          <button className="choice-other" onClick={() => reportAndSetAnswer("Both")}>
            Both
          </button>
        </div>
      </div>
    </div>
  );
};

export default DestinationsVisiting;
