import "./assets/scss/ReferralInviteForm.scss";

import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

import { Card, Title } from "@runwayhealth/runway-components-react";
import { RootEntity, RootState } from "@store/../@types/state";
import { User } from "@store/../@types/user";
import { SEND_TRAVEL_GROUP_EMAIL } from "@store/user/userActions";
import { validateEmail } from "@utils/utilsFormvalidation";

import RewardView from "./components/RewardView";
import TravelGroupView from "./components/TravelGroupView";

export enum ViewType {
  TRAVEL_GROUP = "travel_group",
  REWARD = "reward",
}

interface IReferralFormProps {
  isOpen: boolean;
  view: ViewType;
  closeForm?: () => void;
}

const ReferralInviteForm = ({ isOpen, view, closeForm }: IReferralFormProps) => {
  const dispatch = useDispatch();
  const [emailsSentSuccess, setEmailsSentSuccess] = useState(false);
  const [emails, setEmails] = useState<string>("");
  const [isInvalid, setIsInvalid] = useState(false);
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);

  useEffect(() => {
    if (user.error.event) {
      setEmailsSentSuccess(false);
    }
  }, [user.error]);

  const sendEmails = () => {
    let invalidEmails = false;
    const emailsArray = emails.replace(/\s/g, "").split(",");
    emailsArray.forEach((email) => {
      if (!validateEmail(email)) {
        setIsInvalid(true);
        invalidEmails = true;
        return;
      }
    });

    if (!invalidEmails) {
      dispatch({
        type: SEND_TRAVEL_GROUP_EMAIL,
        emails: emailsArray,
      });
      setEmailsSentSuccess(true);
    }
  };
  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsInvalid(false);
    setEmails(e.target.value);
  };
  return (
    <>
      {isOpen && (
        <Card>
          <Container>
            <Row>
              <Col xs={12} sm={12} lg={8}>
                <Title>
                  {view === ViewType.TRAVEL_GROUP
                    ? "Refer your fellow travelers"
                    : "Refer friends and earn!"}
                </Title>
              </Col>
            </Row>
          </Container>
          <hr></hr>
          {view === ViewType.TRAVEL_GROUP ? (
            <TravelGroupView
              emailsSentSuccess={emailsSentSuccess}
              emails={emails}
              isInvalid={isInvalid}
              sendingEmails={user.isPending}
              sendEmails={sendEmails}
              closeForm={closeForm ? closeForm : () => {}}
              handleFieldChange={handleFieldChange}
              setEmailsSentSuccess={setEmailsSentSuccess}
              setEmails={setEmails}
            />
          ) : (
            <RewardView affiliateLink={user.data.affiliateProfile?.links[0].url} />
          )}
        </Card>
      )}
    </>
  );
};

export default ReferralInviteForm;
