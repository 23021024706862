import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  general: [],
  diarrhea: [],
  malaria: [],
  altitude: [],
  motion: [],
  nausea: [],
  insomnia: [],
  vaginalYeastInfection: [],
  urinaryTractInfection: [],
  typhoid: [],
  consent: [],
};

export const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    questionsUpdate: (state, action) => {
      let { question, value, type, important, questionType } = action.payload;

      switch (value) {
        case "Yes":
          value = "true";
          type = "boolean";
          break;
        case "No":
          value = "false";
          type = "boolean";
          break;
        default:
          value = value;
          type = "string";
      }

      const questionIndex = state[questionType].findIndex((el) => el.question === question);

      if (questionIndex > -1) {
        state[questionType][questionIndex].answer = value;
      } else {
        state[questionType].push({
          question,
          answer: value,
          type,
          important,
        });
      }
    },
    recoverQuestions: (state, action) => {
      return (state = action.payload);
    },
    clearQuestions: (state) => {
      return (state = initialState);
    },
  },
});

export const { questionsUpdate, recoverQuestions, clearQuestions } = questionsSlice.actions;

export default questionsSlice.reducer;
