import { React } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Slide from "@material-ui/core/Slide";
import DocViewer from "../../pages/LoginPage/docs/docViewer";

function getModalStyle() {
  const top = 0;
  const right = 0;

  return {
    top: `${top}%`,
    margin: "auto",
    right: `${right}%`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: window.innerWidth < 768 ? "90%" : "50%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 32,
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  },
}));

const PharmacyModal = ({ open, close, name }) => {
  const classes = useStyles();

  return (
    <div className="modalVisible">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => close(false)}
        scroll="true"
        style={{ alignItems: "center", justifyContent: "center" }}
      >
        <Slide direction="left" in={open} mountOnEnter unmountOnExit>
          <div style={getModalStyle()} className={classes.paper}>
            <DocViewer name={name} />
          </div>
        </Slide>
      </Modal>
    </div>
  );
};

export default PharmacyModal;
