import logo from "@img/RunwayLogo.svg";
import { Link } from "@runwayhealth/runway-components-react";
import { LANDING_PAGE } from "@utils/constantsEnv";

export const Logo = () => {
  return (
    <Link href={LANDING_PAGE} isBlank className="rw-logo">
      <img src={logo} alt="Runway Health" />
    </Link>
  );
};
