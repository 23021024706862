import { useState } from "react";
import "./ShortInput.scss";

const ShortInput = ({ placeholder, setDate, date, value = "", setError, id, position }) => {
  const [symbol, setSymbol] = useState("");

  const nextField = document.getElementById(id.replace(position, position + 1));
  const backField = document.getElementById(id.replace(position, position - 1));
  const curField = document.getElementById(id);

  const setFieldFocus = (e) => {
    if (e.keyCode === 39) {
      nextField?.focus();
    }
    if (e.keyCode === 37) {
      backField?.focus();
    }
    if (e.keyCode !== 39 && e.keyCode !== 37 && e.target.value && curField) {
      nextField?.focus();
    }
  };
  const backFieldFocus = (e) => {
    setSymbol("");
    if (curField) {
      backField?.focus();
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSymbol(value.replace(/\D/g, ""));
    if (!isNaN(value)) {
      setDate({ ...date, [position]: value });
    }
  };

  return (
    <div className="short-input">
      <input
        type="tel"
        autoComplete="off"
        className="custom-input"
        placeholder={placeholder}
        value={symbol || value}
        onChange={(e) => handleChange(e)}
        onKeyDown={(e) => e.keyCode !== 8 && setFieldFocus(e)}
        onKeyUp={(e) => e.keyCode === 8 && backFieldFocus(e)}
        onFocus={() => setError(false)}
        maxLength={1}
        id={id}
      />
    </div>
  );
};

export default ShortInput;
