import styled from "styled-components";

import icon from "@img/miniLogo.svg";
import { from, Text } from "@runwayhealth/runway-components-react";

interface ConsultationProps {
  text: string;
}

const ConsultationBase = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 0;
  width: fit-content;
  gap: 8px;
  margin-bottom: 8px;
  cursor: pointer;

  @media ${from.mobile} {
    margin-bottom: 16px;
  }

  img {
    width: 20px;
    height: auto;
  }

  span {
    margin-bottom: 0;
  }
`;

const Consultation = ({ text }: ConsultationProps) => {
  return (
    <ConsultationBase>
      <img src={icon} alt={text} />
      <Text as="span">{text}</Text>
    </ConsultationBase>
  );
};

export default Consultation;
