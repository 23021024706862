import { useEffect } from "react";

const SUFFIX = "Travel Medicine - Consultation & Medications | Runway Health App";

export const useTitle = (title: string, suffix = true) => {
  const finalTitle = `${title}${suffix ? ` | ${SUFFIX}` : ""}`;
  useEffect(() => {
    document.title = finalTitle;
  }, [title]);
};
