import { useTitle } from "@hooks/useTitle";
import { Link, Text, Title } from "@runwayhealth/runway-components-react";

const Consent = () => {
  useTitle("Consent");
  return (
    <>
      <Title size="giant">Clinical Services and Practice Policies Agreement</Title>
      <Text variant="base">Last revised: 7/5/2022</Text>
      <Text variant="base">
        <strong>General Information</strong>Runway Health, Inc., d/b/a Runway (“Runway”, “we”, “us”,
        “our”) connects its customers to technology enabled digital clinical care through its
        contracted clinical provider, M&amp;D Integrations, Inc., (the “Clinical Services Provider”)
        to assist our customers with their travel-related medical needs. This Agreement describes
        services provided by Runway and the Clinical Services Provider. It is important for you to
        read this document and discuss any questions you might have with us.{" "}
      </Text>

      <Text variant="base">
        <strong>Our Services and Technology</strong>
        When you become a customer of Runway, you will be given access to our mobile or desktop
        application (the “Runway App”), through which you will be connected with the Clinical
        Services Provider . The Runway App allows you to schedule appointments and provides access
        to your information. The Clinical Services Provider will pair you with a licensed clinician
        who will evaluate your travel-related medical needs and issue prescriptions that such
        licensed clinician deems appropriate. Runway will then transfer your prescriptions to be
        filled by your selected pharmacy provider.
      </Text>

      <Text variant="base">
        <strong>Payment and Billing</strong>
        Runway will pass through all payment amounts collected for the Clinical Services Provider’s
        services and pharmacy fulfillment services directly to such providers. All other amounts
        paid for Runway's services will be retained by us. By signing this agreement, you agree to
        pay amounts you owe to us in accordance with our payment policies as further detailed in the
        Terms of Use. Your submission of credit card information upon signing this Agreement
        authorizes Runway to charge the credit card on file for agreed upon purchases. You
        understand that your credit card information will be saved on file for future transactions
        on your account.
      </Text>

      <Text variant="base">
        <strong>No Emergency Services</strong>
        <strong style={{ textDecoration: "underline" }}>
          Runway’s contracted clinical providers do not address medical emergencies via telehealth.
          If you are experiencing a medical emergency or are otherwise in imminent danger, you
          should dial 9-1-1 and/or go to the nearest emergency room.{" "}
        </strong>
      </Text>

      <Text variant="base">
        <strong>Payment and Billing</strong>
        Runway will pass through all payment amounts collected for the Clinical Services Provider’s
        services and pharmacy fulfillment services directly to such providers. All other amounts
        paid for Runway's services will be retained by us. By signing this agreement, you agree to
        pay amounts you owe to us in accordance with our payment policies as further detailed in the
        Terms of Use. Your submission of credit card information upon signing this Agreement
        authorizes Runway to charge the credit card on file for agreed upon purchases. You
        understand that your credit card information will be saved on file for future transactions
        on your account.
      </Text>

      <Text variant="base">
        <strong>Telehealth Informed Consent - Risks and Benefits</strong>
        When appropriate, the assigned licensed clinician will provide you with travel-related
        clinical assessments and treatment via telehealth. The flexibility of telehealth, including
        communication over video, audio and messaging, allows for a greater degree of convenience
        for you, but it also introduces some limitations. Telehealth may not be appropriate for the
        treatment of certain symptoms or diagnoses that would benefit from an in-person appointment,
        and it is your licensed clinician's role to make that determination. Your licensed clinician
        will make every effort to discuss these concerns with you, but your licensed clinician
        reserves the sole discretion to discontinue treatment via telehealth if necessary. The
        systems we use for services delivered via telehealth will incorporate network and software
        security protocols to protect the confidentiality and safety of patient information. The
        telehealth services will be delivered over a secure connection that complies with the
        requirements of the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”).
        Notwithstanding these security measures, in very rare events, security protocols could fail,
        causing a breach of privacy of protected health information. Additionally, as described
        below and with your consent, we may at times communicate with you through unsecure SMS text
        messaging and email.
      </Text>

      <Text variant="base">
        <strong>Video and Audio Recordings</strong>
        We may make video or audio recordings of your telehealth session. Those recordings will be
        used internally by the Clinical Services Provider for treatment, quality improvement and
        care coordination purposes in accordance with applicable law and privacy policies.{" "}
      </Text>

      <Text variant="base">
        <strong>Privacy and Disclosures of Health Information</strong>
        In general, the privacy of all communications between a clinical provider and patient is
        protected by law, and the Clinical Services Provider can only release information about your
        treatment to others with your written permission or as otherwise permitted by law.
        Therefore, Runway and the Clinical Services Provider cannot provide the requested services
        unless you agree to the enclosed Authorization for Information Sharing that allows the
        Clinical Services Provider, Runway, and the selected pharmacy to share your information with
        each other. Please see Runway’s Privacy Policy and for more information about how your
        information may be used.
      </Text>

      <Text variant="base">
        <strong>Text Messaging and Email</strong>
        From time to time, we may provide you with messages such as appointment reminders, service
        announcements, privacy notices, administrative messages, and other communications relating
        to your care. This may include <em>SMS text messages</em> and <em>emails</em>. Text messages
        and emails are not always secure because they travel over networks that we do not control.
        By agreeing to these terms, you are authorizing us to send you SMS text messages and emails.
        You are under no obligation to give us this authorization and may opt-out at any time by
        following the opt-out instructions provided to you by Runway.
      </Text>

      <Text variant="base">
        <strong>Minor Children</strong>
        Minor Children must be at least 13 years of age to use the Runway App or otherwise access
        services provided by Runway or the Clinical Services Provider. Runway does not knowingly
        provide services to or collect information from minors under the age of 13. If you are
        signing this agreement as the parent or guardian of a minor child, your agreement below also
        constitutes your consent to the collection and use of your child’s information, as described
        in our Privacy Policy and this Clinical Services Agreement. A parent or guardian may revoke
        this consent at any time. However, once consent is revoked, a child may not use the Runway
        App or any other services of Runway again unless a new Clinical Services Agreement is
        signed.
      </Text>

      <Text variant="base">
        <strong>Complaint Policy</strong>
        Should you wish to make a formal complaint about the services you receive, you may do so by
        sending an email to{" "}
        <Link href="mailto:support@runwayhealth.com">support@runwayhealth.com</Link>
      </Text>

      <Text variant="base">
        <strong>Agreement and Consent</strong>
        By selecting this box you indicate that you have read and understood this document, and that
        you agree to abide by its terms. Further, you certify that if you are signing as a parent or
        guardian of a minor child, or a personal representative of the customer, you have legal
        authority to provide consent for the treatment of the customer.{" "}
      </Text>
    </>
  );
};

export default Consent;
