import { React } from "react";
import MediumInput from "../MediumInput";
import "./CheckboxInput.scss";
import ArrowButton from "../../../img/ArrowButton.svg";

const CheckboxInput = ({
  checked,
  isNone,
  setChecked,
  setOwnAnswer,
  required,
  num,
  capture,
  disabled,
}) => {
  const changeState = () => {
    setChecked((prev) =>
      prev.map((item, index) => {
        if (index === num) {
          return !item;
        }
        return item;
      })
    );
  };

  return (
    <div className="checkbox-form">
      <div className="items">
        <label className="custom-checkbox">
          <input
            type="checkbox"
            className="checkbox-input"
            checked={checked}
            onChange={changeState}
            required={required}
            num={num}
            disabled={disabled}
          />
          <span className="checkmark-primary" />
          <div className="checkbox-variant">
            <MediumInput
              isNone={isNone}
              setOwnAnswer={setOwnAnswer}
              num={num}
              disabled={isNone}
              onFocus={() => changeState(true)}
              onChange={capture}
              name={"ownAnswer"}
              placeholder={"Your own answer"}
            />
          </div>
        </label>
      </div>
    </div>
  );
};

export default CheckboxInput;
