import "./pharmacyElement.scss";

const PharmacyElement = ({ pharmacy, status, change, index }) => {
  //pharmacy - pharmacy data
  //status - checkbox status
  //change - function to change checkbox status
  //index - current pharmacy index
  const { state } = pharmacy;
  const name = pharmacy.name.toLowerCase();
  const address1 = pharmacy.address1.toLowerCase();
  const city = pharmacy.city.toLowerCase();
  return (
    <div className="pharmacy-find-element">
      <div className="checkbox" onClick={() => change(index)}>
        {status && <div className="checkbox-status" />}
      </div>
      <div className="pharmacy-info">
        <h2>{name}</h2>
        <p>
          {address1}, {city}, {state}
        </p>
      </div>
    </div>
  );
};

export default PharmacyElement;