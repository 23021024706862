import { EventChannel } from "redux-saga";
import { call, fork, put, take, takeEvery } from "redux-saga/effects";
import { Socket } from "socket.io-client";

import { pushMessages } from "@store/messages/messagesSlice";
import { setNotification } from "@store/ui/uiSlice";

import { Notification } from "../../@types/user";
import * as socketActions from "./socketActions";
import * as SocketChannel from "./socketChannel";

function* socketWatcher(socketChannel: EventChannel<Socket>) {
  while (true) {
    try {
      // Receives sent events.
      const event: SocketChannel.SocketEvent<Notification> = yield take(socketChannel);
      /* Depending on the event received, we should be able to address
       * the reducer with the right data.
       */

      if (event.eventName === SocketChannel.SocketEvents.MESSAGE_CREATED) {
        yield put(pushMessages(event.payload.message));
        yield put(setNotification({ message: true }));
      }
    } catch (err) {
      console.log(err);
      // Handle error.
    }
  }
}

function* writeSocket(socket: Socket) {
  while (true) {
    const {
      event: { eventName, payload },
    } = yield take(socketActions.WEBSOCKET_SEND);

    if (eventName === SocketChannel.SocketEvents.DISCONNECT) {
      socket.emit(SocketChannel.SocketEvents.SOCKET_WITHDRAWAL, payload);
      socket.disconnect();
    } else {
      socket.emit(eventName, payload);
    }
  }
}

export function* setSocketConnection(authToken: string) {
  // Yield socket connection.
  const socket: Socket = yield call(SocketChannel.createWebSocketConnection, authToken);
  const socketChannel: EventChannel<Socket> = yield call(SocketChannel.createSocketChannel, socket);
  // Call the event watcher.
  yield fork(socketWatcher, socketChannel);
  // Have the socket emitter ready.
  yield fork(writeSocket, socket);
}

function* restoreSocketConnection({ authToken }: socketActions.RestoreConnection) {
  yield call(setSocketConnection, authToken);
}

export default function* socketSaga() {
  yield takeEvery(socketActions.RESTORE_CONNECTION, restoreSocketConnection);
}
