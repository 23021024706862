import { useState } from "react";

import useAmplitude from "@hooks/useAmplitude";
import HelpButton from "@img/HelpButton.svg";

import Modal from "./Modal";

const Container = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { logEvent } = useAmplitude();
  const toggleOpen = () => {
    logEvent("HELP_MODAL_TOGGLE", { ACTION_OPEN: !isOpen });
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isOpen && <Modal setOpenHelpModal={toggleOpen} />}
      <div className="help-button" onClick={toggleOpen}>
        <img src={HelpButton} alt="Help" />
        <span>Help</span>
      </div>
    </>
  );
};

export default Container;
