export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const LANDING_PAGE = process.env.REACT_APP_LANDING_PAGE;
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
export const ENV = process.env.REACT_APP_ENV;

// routes
export const USER_URL = "user/";
export const AUTH_URL = "/v1/auth/";
export const STRIPE_URL = "stripe/";
export const MD_URL = "md/";
export const ADMIN_URL = "admin/";
export const COUPONS_URL = "/coupons";
export const ORDER_URL = "/order";
export const CASE_URL = "/v1/case";
export const PARTNER_URL = "/v1/partner";

// prices
export const VISIT_FEE_ID = process.env.REACT_APP_VISIT_FEE_ID;
export const PRIORITY_SHIPPING_ID = process.env.REACT_APP_PRIORITY_SHIPPING_ID;
export const OVERNIGHT_SHIPPING_ID = process.env.REACT_APP_OVERNIGHT_SHIPPING_ID;

// maps
export const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY;
