import "./Security-Code.scss";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { ErrorEvents, RootEntity, RootState } from "@store/../@types/state";
import { User } from "@store/../@types/user";
import { VERIFY_SECURITY_CODE } from "@store/user/userActions";
import { toastError } from "@utils/utils";

import Loader from "../../../img/Loader.svg";
import { LANDING_PAGE } from "../../../utils/constantsEnv";
import HelpModal from "../../elements/HelpModal";
import BackButton from "./img/BackButton.svg";
import RunwayLogo from "./img/RunwayLogo.svg";
import SecutryIcon from "./img/securty.svg";

type CodeParams = {
  token: string;
};

const TOKEN_EXPIRED = "jwt expired";

const SecurityCodePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);

  const { token } = useParams<CodeParams>();

  useEffect(() => {
    dispatch({
      type: VERIFY_SECURITY_CODE,
      token,
    });
  }, [token]);

  useEffect(() => {
    if (user.error.event === ErrorEvents.SERVER_ERROR) {
      toastError(
        "Error",
        user.error.message === TOKEN_EXPIRED
          ? "Recovery link expired"
          : "Could not recover password."
      );
      history.push("/login");
    }
  }, [user.error]);

  return (
    <div className="SecurityCode-page">
      <div className="rw-legacy-2-container">
        <header className="SecurityCode-header">
          <div className="back-button" onClick={() => history.goBack()}>
            <img src={BackButton} alt="Go back" />
            <span>Back</span>
          </div>
          <a className="runway-logo" href={LANDING_PAGE} target="_blank" rel="noopener noreferrer">
            <img src={RunwayLogo} alt="Runway" />
          </a>
          <HelpModal />
        </header>

        <div className="SecurityCode-container">
          <div className="SecurityCode-content">
            <div className="SecurityCode-icon">
              <img style={{ width: "100%", height: "100%" }} src={SecutryIcon} alt="aa" />
            </div>
            {user.isPending && (
              <label className="SecurityCode">
                Please wait while we are processing your request
              </label>
            )}
            {user.error.event && (
              <div className="error-message-login">
                <p className="message-content-login">{user.error.message}</p>
              </div>
            )}
            {user.isPending && (
              <div className="SecurityCode-form">
                <div className="form">
                  <img className="loader-img" src={Loader} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityCodePage;
