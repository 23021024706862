import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import Header from "@components/elements/Header";
import { PageConsultation } from "@components/layouts/PageConsultation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTitle } from "@hooks/useTitle";
import {
  Button,
  Feedback,
  FormGroup,
  Label,
  Text,
  TextField,
  Title,
} from "@runwayhealth/runway-components-react";
import { userUpdate } from "@store/user/userSlice";

interface IFormInputs {
  feet: number | null;
  inches: number | null;
  weight: number | null;
}

const schema = yup
  .object()
  .shape({
    feet: yup.number().positive().max(10).typeError("Feets must be a number").required("Required"),
    inches: yup
      .number()
      .positive()
      .max(11)
      .typeError("Inches must be a number")
      .required("Required"),
    weight: yup
      .number()
      .positive()
      .max(2000)
      .typeError("Weight must be a number")
      .required("Required"),
  })
  .required();

const defaultValues: IFormInputs = {
  feet: null,
  inches: null,
  weight: null,
};

const HeightAndWeight = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormInputs>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues,
  });
  useTitle("Height and Weight");

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    // Convert height to CM.
    const ftToCm = (data.feet || 0) * 30.48;
    const inToCm = (data.inches || 0) * 2.54;
    // Set user height in cm.
    dispatch(userUpdate({ name: "height", value: ftToCm + inToCm }));

    // Set user weight in kilo.
    dispatch(userUpdate({ name: "weight", value: (data.weight || 0) * 0.453592 }));

    history.push("/assigned_sex");
  };

  return (
    <div>
      <Header progress={13} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">Height and Weight</Title>
          <Text size="md">
            In order to process your prescription, your clinician requires your height and weight.
            Please fill out the details below.
          </Text>
        </PageConsultation.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PageConsultation.Body>
            <FormGroup>
              <Row>
                <Col md={2}></Col>
                <Col md={2} style={{ textAlign: "left" }}>
                  <Label htmlFor="feet">Feet</Label>
                  <TextField
                    id="feet"
                    placeholder="5"
                    isInvalid={errors.feet}
                    type="number"
                    {...register("feet")}
                  />
                  <Feedback size="sm" isInvalid>
                    {errors.feet?.message}
                  </Feedback>
                </Col>
                <Col md={2} style={{ textAlign: "left" }}>
                  <Label htmlFor="inches">Inches</Label>
                  <TextField
                    id="inches"
                    placeholder="6"
                    isInvalid={errors.inches}
                    type="number"
                    {...register("inches")}
                  />
                  <Feedback size="sm" isInvalid>
                    {errors.inches?.message}
                  </Feedback>
                </Col>
                <Col md={4} style={{ textAlign: "left" }}>
                  <Label htmlFor="weight">Weight</Label>
                  <TextField
                    id="weight"
                    placeholder="145"
                    isInvalid={errors.weight}
                    type="number"
                    {...register("weight")}
                  />
                  <Feedback size="sm" isInvalid>
                    {errors.weight?.message}
                  </Feedback>
                </Col>
              </Row>
            </FormGroup>
          </PageConsultation.Body>
          <Button size="lg" iconName="ArrowRight" type="submit" disabled={!isDirty || !isValid}>
            Continue
          </Button>
        </form>
      </PageConsultation>
    </div>
  );
};

export default HeightAndWeight;
