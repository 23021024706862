import styled from "styled-components";

import { spacing } from "@runwayhealth/runway-components-react";

const PageConsultationHeader = styled.div`
  padding-bottom: ${spacing.xs};
  text-align: center;
`;

export default PageConsultationHeader;
