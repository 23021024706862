import amplitude from "amplitude-js";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { Feedback, Text, Title } from "@runwayhealth/runway-components-react";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import Header from "../../elements/Header";
import YesNoQuestion from "../../templates/YesNoQuestion";

const CurrentPregnant = () => {
  const [answer, setAnswers] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  useTitle("Are you pregnant?");
  const setAnswer = (value: string) => {
    if (value === "Yes") {
      setAnswers(value);
      amplitude.getInstance().logEvent("PREGNANT_YES");
    }
    if (value === "No") {
      setAnswers(value);
      dispatch(
        questionsUpdate({
          question:
            "Are you currently pregnant or may you become pregnant prior to or while traveling?",
          value,
          questionType: "general",
        })
      );
      history.push("/feeding");
    }
  };

  return (
    <>
      <Header progress={6} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">
            Are you currently pregnant or may you become pregnant while traveling?
          </Title>
          <Text size="md">Choose one option</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <YesNoQuestion
            option=""
            setAnswer={setAnswer}
            isActive={answer === "Yes" ? true : false}
          />
          {answer === "Yes" && (
            <Feedback isInvalid size="md" className="rw-page-consultation-feedback-message">
              Our services are not currently available for pregnant or breast feeding women. <br />
              Please consult your physician.
            </Feedback>
          )}
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default CurrentPregnant;
