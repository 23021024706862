import { Button, Text, TextField, Title } from "@runwayhealth/runway-components-react";
import { toastInfo } from "@utils/utils";

interface IRewardView {
  affiliateLink?: string;
}

const RewardView = ({ affiliateLink }: IRewardView) => {
  return (
    <>
      <Text>
        Spread the word and get rewarded! When you refer your friends to our services, you'll earn a
        complimentary credit on your next consultation for each successful referral. It's our way of
        saying thank you for your trust and support.
      </Text>
      <br></br>
      <br></br>
      <Title size="md">1. Share the link with your friends.</Title>
      <Title size="md">
        2. They register and complete a consultation for Runway via your referral link.
      </Title>
      <Title size="md">3. You both get a 15$ credit on the next consultation.</Title>
      <br></br>
      <br></br>
      <Text>Start enjoying the benefits of helping others discover us.</Text>
      <div className="reward-view-actions-wrapper">
        <TextField className="referral-link-text-field" value={affiliateLink ?? "..."}></TextField>
        <Button
          iconName="LinkSimple"
          size="md"
          variant="primary"
          onClick={() => {
            navigator.clipboard.writeText(affiliateLink ?? "");
            toastInfo("Copied to clipboard!", affiliateLink);
          }}
          isIconOnly
        />
      </div>
    </>
  );
};

export default RewardView;
