import { useTitle } from "@hooks/useTitle";
import { font, Link, Text, Title } from "@runwayhealth/runway-components-react";

import { LANDING_PAGE } from "../../../../utils/constantsEnv";

const Privacy = () => {
  useTitle("Privacy Policy");

  return (
    <>
      <Title size="giant">Runway's Privacy Policy</Title>
      <Text variant="base">Last revised: June 23, 2022</Text>

      <Title size="md" as="h2">
        1. INTRODUCTION
      </Title>

      <Text variant="base">
        This privacy policy (“Privacy Policy”) is part of Runway’s Terms of Use agreement, which
        governs the online interfaces and digital properties (e.g., websites) owned and controlled
        by Runway Health, Inc. (“Runway”), including the website located at{" "}
        <Link href="http://www.runwayhealth.com" isBlank>
          www.runwayhealth.com
        </Link>{" "}
        (the “Site”), (collectively, referred to as the “Digital Services”).
      </Text>

      <Text variant="base">
        The purpose of this Privacy Policy is to describe how Runway collects, uses, and shares
        information about you through its Digital Services. Runway respects your privacy and is
        committed to protecting it as outlined in this Privacy Policy. If you do not agree with our
        policies and practices, you may not use our services, including any Digital Services. By
        accessing or using our services, you agree to this Privacy Policy. This Privacy Policy may
        change from time to time. Your continued use of our services after we make changes is deemed
        to be acceptance of those changes. Please read this notice carefully to understand what we
        do. If you do not understand any aspects of our Privacy Policy, please feel free to Contact
        Us as described at the end of this Policy.
      </Text>

      <Text variant="base">Our Privacy Policy explains:</Text>

      <ul>
        <li>
          <Text variant="base" as="span">
            Information That We Collect
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            How We Use and Share Your Information
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Access to Your Information and Choices
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Protected Information
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Security of Your Information
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Important Notice to Non-US Residents
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            External Websites
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Data Retention
          </Text>
        </li>
        <li>
          <Link
            fontFamily={`${font.family.base.regular}, ${font.family.fallback}`}
            href="https://runwayhealth.com/california-privacy-policy/"
          >
            California Privacy Rights
          </Link>
        </li>
        <li>
          <Text variant="base" as="span">
            Nevada Privacy Rights
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Changes to Our Privacy Policy
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Questions and How Contact Us
          </Text>
        </li>
      </ul>

      <Text variant="base">
        Capitalized terms not defined in this Privacy Policy shall have the meanings defined in the
        Terms of Use.
      </Text>

      <Text variant="base">
        <strong>Scope; Third Party Sites.</strong>
        This Privacy Policy applies only to information we collect at and through the Site or from
        or through any use of our services, including any Digital Services as defined in our Terms
        of Use (referenced above). Our Site also contains links to third-party sites that are not
        owned or controlled by Runway. Please be aware that we are not responsible for the privacy
        practices of such other sites. We encourage you to be aware when you leave our Site and to
        read the privacy statements of each and every website that collects personal information.
      </Text>

      <Title size="md" as="h3">
        2. INFORMATION THAT WE COLLECT
      </Title>
      <Title size="sm" as="h4">
        Information You Provide to Us
      </Title>

      <Text variant="base">
        We collect information that you directly provide to us when you seek to use any of our
        services, including the Digital Services, request information from us, contact customer
        support, or otherwise communicate with us or use our Site in a commercial manner. Our
        services may present preliminary questions to customers, which may include:
      </Text>

      <ul>
        <li>
          <Text variant="base" as="span">
            Name
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Address
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Email address
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Telephone number
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Payment information (credit or debit card number, expiration date, and security code)
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Date of birth
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Username and password
          </Text>
        </li>
      </ul>

      <Text variant="base">
        Please note that Runway does not practice medicine or provide medical care. If Runway
        connects you with a Provider, any information that you give to such Provider is subject to
        privacy laws applicable to such Provider, as well as your Provider’s Notice of Privacy
        Practices.
      </Text>

      <Title size="sm" as="h4">
        Information We Collect Automatically Through Your Use of our Site
      </Title>
      <Title size="sm" as="h4">
        Google Analytics
      </Title>

      <Text variant="base">
        We use Google Analytics, a web analytics service provided by Google, Inc. (“Google”) to
        collect certain information relating to your use of the Site. Google Analytics uses
        “cookies”, which are text files placed on your computer, to help the Site analyze how users
        use the site. You can find out more about how Google uses data when you visit our Site by
        visiting:
      </Text>

      <ul>
        <li>
          <Text variant="base" as="span">
            "How Google uses data when you use our partners' sites or apps" (located at{" "}
            <Link href="https://policies.google.com/privacy" isBlank>
              www.google.com/policies/privacy
            </Link>
            ).
          </Text>
        </li>
      </ul>

      <Text variant="base">
        We may also use Google Analytics Advertising Features or other advertising networks to
        provide you with interest-based advertising based on your online activity. For more
        information regarding Google Analytics please visit Google's website, and pages that
        describe Google Analytics, such as{" "}
        <Link href="https://www.google.com/analytics/learn/privacy.html" isBlank>
          www.google.com/analytics/learn/privacy.html
        </Link>
      </Text>

      <Title size="sm" as="h4">
        Information Collected Through Cookies and Similar Technologies
      </Title>
      <Text variant="base">
        We and our service providers use cookies, web beacons, and other technologies to receive and
        store certain types of information whenever you interact with our Site through your computer
        or mobile device. A cookie is a small file containing a string of characters that is sent to
        your computer when you visit a website. When you visit the Site again, the cookie allows the
        Site to recognize your browser. Cookies may store unique identifiers, user preferences and
        other information. You can reset your browser to refuse all cookies or to indicate when a
        cookie is being sent. However, some Site features or services may not function properly
        without cookies. We use cookies to improve the quality of our service, including for storing
        user preferences, tracking user trends, and providing relevant advertising to you. (See,
        “Advertising” below).
      </Text>

      <Title size="sm" as="h4">
        Parent/Guardian Consent Required for Minor Children; No Information Collected From Minors
        Under the Age of 13
      </Title>
      <Text variant="base">
        If you are under the age of 18, you may only access Runway’s services if your parent or
        legal guardian must registers you from an account and consents on your behalf. A parent or
        legal guardian of a minor child who is at least 13 years of age may resgiter and consent to
        receive services from Runway on the child’s behalf. Runway does not knowingly provide
        services to or collect information from minors under the age of 13. If you have questions
        about this policy or believe we have collected personal information from someone under the
        age of 13, please Contact Us as described below.
      </Text>

      <Title size="md" as="h3">
        3. HOW WE USE AND SHARE YOUR INFORMATION
      </Title>

      <Text variant="base">
        <strong>To Provide Products, Services, and Information.</strong> We collect information from
        you and use the information to:
      </Text>

      <ul>
        <li>
          <Text variant="base" as="span">
            provide products and services that you order using the Site;
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            provide information that you request from us;
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            contact you about our services or information you requested;
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            process credit card and debit card transactions;
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            to allow you to participate in interactive features of our services;
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            send you promotional materials or advertisements about our products and services, as
            well as new features and offerings;
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            enforce our Terms of Use or other legal rights and remedies;
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            administer surveys;
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            provide interest-based targeted advertising to you; and,
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            any other purposes disclosed to you at the time we collect your information or pursuant
            to your consent.
          </Text>
        </li>
      </ul>

      <Title size="sm" as="h4">
        Sharing with Third Parties
      </Title>
      <Text variant="base">
        <strong>Healthcare Providers.</strong> We share your personal information with various
        healthcare affiliates, e.g., pharmacies, doctors, and relevant medical staff in connection
        with your diagnosis and treatment.
      </Text>

      <Text variant="base">
        <strong>Vendors and Services Providers.</strong> We may provide information to third-party
        vendors and service providers that help us operate and manage our Digital Services, process
        orders, and fulfill and deliver products and services that you request from us. These
        vendors and service providers will have access to your personal information in order to
        provide these services, but when this occurs, we implement reasonable contractual and
        technical protections to limit their use of that information to helping us provide the
        service.
      </Text>

      <Text variant="base">
        <strong>Corporate Affiliates.</strong> We may share de-identified and aggregated information
        with our other companies and people with whom we do business (namely, our customers or other
        affiliates).
      </Text>

      <Text variant="base">
        <strong>Advertising.</strong> We may use how you browse and shop in order to show you ads
        for Runway or our advertising partners that are more relevant to your interests. We may use
        cookies and other information to provide relevant interest-based advertising to you.
        Interest-based ads are ads presented to you based on your browsing behavior in order to
        provide you with ads more tailored to your interests. These interest-based ads may be
        presented to you while you are browsing our site or third-party sites not owned by Runway.
      </Text>

      <Text variant="base">
        We belong to ad networks that may use your browsing activity across participating websites
        to show you interest-based advertisements on those websites. Currently, our Site does not
        recognize if your browser sends a “do not track” signal or similar mechanism to indicate you
        do not wish to be tracked or receive interest-based ads. To learn more about interest-based
        advertisements and opt-out rights and options, visit the following websites:
      </Text>

      <ul>
        <li>
          <Text variant="base" as="span">
            <Link href="http://www.aboutads.info/" isBlank>
              Digital Advertising Alliance
            </Link>{" "}
            (www.aboutads.info)
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            <Link href="https://www.networkadvertising.org/" isBlank>
              Network Advertising Initiative
            </Link>{" "}
            (www.networkadvertising.org)
          </Text>
        </li>
      </ul>

      <Text variant="base">
        Please note that if you choose to opt out, you will continue to see ads, but they will not
        be based on your online activity.
      </Text>

      <Text variant="base">
        <strong>Social Media.</strong> If you use third-party services, such as Facebook, Google, or
        Twitter, to share information about your experience on the Site with others, these
        third-party services may be able to collect information about you, including information
        about your activity on the Site, and they may notify your connections on the third-party
        services about your use of the Site, in accordance with their own privacy policies.
      </Text>

      <Text variant="base">
        <strong>Public Posts and Activities.</strong> If you choose to engage in public activities
        on the Site, you should be aware that any information you share there can be read,
        collected, or used by other users of these areas. You should use caution in disclosing
        personal information while participating in these areas. We are not responsible for the
        information you choose to submit in these public areas. Any user posting in these public
        areas understands and acknowledges that there is no expectation of privacy for the
        information in these public areas.
      </Text>

      <Text variant="base">
        <strong>Your Consent.</strong> In addition to the sharing described elsewhere in this
        Policy, we will share personal information with companies, organizations, or individuals
        outside of Runway when we have your consent to do so.
      </Text>

      <Text variant="base">
        <strong>Legal Proceedings.</strong> We will share personal information with third-party
        companies, organizations, or individuals outside of Runway if we have a good-faith belief
        that access, use, preservation or disclosure of the information is reasonably necessary to:
      </Text>

      <ul>
        <li>
          <Text variant="base" as="span">
            Meet any applicable law, regulation, subpoena, legal process, or enforceable
            governmental request.
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Enforce applicable Terms of Use, including investigation of potential violations.
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Detect, prevent, or otherwise address fraud, security, or technical issues.
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Protect against harm to the rights, property or safety of Runway, our users, customers,
            or the public as required or permitted by law.
          </Text>
        </li>
      </ul>

      <Text variant="base">
        <strong>Transfer in the Event of Sale or Change of Control.</strong> If the ownership of all
        or substantially all of our business changes or we otherwise transfer assets relating to our
        business or the Site to a third party, such as by merger, acquisition, bankruptcy proceeding
        or otherwise, we may transfer or sell your personal information to the new owner. In such a
        case, unless permitted otherwise by applicable law, your information would remain subject to
        the promises made in the applicable privacy policy unless you agree differently.
      </Text>

      <Title size="md" as="h3">
        4. ACCESS TO YOUR INFORMATION AND CHOICES
      </Title>
      <Text variant="base">
        You can access and update certain information we have relating to your online account by the
        contacting us at <strong>support@runwayhealth.com</strong>.
      </Text>

      <Title size="md" as="h3">
        5. PROTECTED INFORMATION
      </Title>
      <Text variant="base">
        By setting up an account with Runway Health, you are entering into a direct customer
        relationship that grants you access to the Digital Services and other services provided by
        us. As part of that relationship, you provide information to Runway health that it may
        collect, use, and disclose in the course of providing services requested by you and in
        accordance with our Privacy Policy, and that we do not consider to be “health” or “medical”
        information. However, in the course of requesting services you may also provide certain
        medical information that may be protected under applicable laws.
      </Text>

      <Text variant="base">
        Runway Health is not a “covered entity” under the Health Insurance Portability and
        Accountability Act of 1996, Public Law 104-191, and its related regulations and amendments
        (collectively, “HIPAA”). One or more of the Providers or pharmacies may or may not be a
        “covered entity” or “business associate” under HIPAA, and Runway Health may in some cases be
        a “business associate” of a Provider or pharmacy. You should be aware that information you
        provide us may or may not be subject to state-specific privacy laws or HIPAA. HIPAA does not
        necessarily apply to an entity or person simply because there is health information
        involved, and HIPAA may not apply to your transactions or communications with Runway Health,
        the Providers, or the pharmacies. To the extent Runway Health is deemed a “business
        associate” of a Provider or pharmacy, however, it may be subject to certain provisions of
        HIPAA with respect to “protected health information”, as defined under HIPAA, that you
        provide to usor to a Provider. In addition, any medical or health information that you
        provide that is subject to specific protections under applicable state laws (collectively,
        with protected health information, “Protected Information”), will be used and disclosed only
        in accordance with such applicable laws. However, any information that does not constitute
        Protected Information under applicable laws may be used or disclosed in any manner permitted
        under our Privacy Policy. Protected Information does not include information that has been
        de-identified in accordance with HIPAA.
      </Text>

      <Text variant="base">
        You should also be aware that the Providers and pharmacies have adopted their own privacy
        practices to describe how they use and disclose Protected Information and Runway Health has
        no responsibility for or control over such third parties’ privacy practices.
      </Text>

      <Title size="md" as="h3">
        6. SECURITY OF YOUR INFORMATION
      </Title>
      <Text variant="base">
        We use industry standard physical, technical, and administrative security measures and
        safeguards to protect the confidentiality and security of your personal information.
        However, since the Internet is not a 100% secure environment, we cannot guarantee, ensure,
        or warrant the security of any information you transmit to us. There is no guarantee that
        information may not be accessed, disclosed, altered, or destroyed by breach of any of our
        physical, technical, or managerial safeguards. Please note that emails and other
        communications you send to us through our Site are not encrypted, and we strongly advise you
        not to communicate any confidential information through these means.
      </Text>

      <Title size="md" as="h3">
        7. IMPORTANT NOTICE TO NON-US RESIDENTS
      </Title>
      <Text variant="base">
        The Digital Services and its servers are operated in the United States. Please be aware that
        your information may be transferred to, processed, maintained, and used on computers,
        servers, and systems located outside of your state, province, country, or other governmental
        jurisdiction where the privacy laws may not be as protective as those in your jurisdiction.
        If you are located outside the United States and choose to use the Website and/or the
        Digital Services, you hereby irrevocably and unconditionally consent to such transfer,
        processing, and use in the United States and elsewhere.
      </Text>

      <Title size="md" as="h3">
        8. EXTERNAL WEBSITES
      </Title>
      <Text variant="base">
        The Digital Services may contain links to external sites operated by third parties. Runway
        has no control over the privacy practices or the content of these external sites. As such,
        we are not responsible for the content or the privacy policies of those external sites. You
        should check the applicable third-party privacy policy and terms of use when visiting any
        other websites.
      </Text>

      <Title size="md" as="h3">
        9. DATA RETENTION
      </Title>
      <Text variant="base">
        Runway will retain your information for as long as your account is active or as needed to
        provide you services. If you wish to cancel your account or request that we no longer use
        your information to provide you services, please contact us using the information below. We
        will retain and use your information as necessary to comply with our legal obligations,
        resolve disputes, and enforce our agreements.
      </Text>

      <Title size="md" as="h3">
        10. CALIFORNIA RESIDENTS
      </Title>

      <Text variant="base">
        If you are a California resident, please <Link>click here</Link> for a supplemental
        California Privacy Notice containing additional information about the processing of your
        personal information and the rights granted to you by California law.
      </Text>

      <Title size="md" as="h3">
        11. NEVADA RESIDENTS
      </Title>
      <Text variant="base">
        Chapter 603A of the Nevada Revised Statutes permits a Nevada resident to opt out of future
        sales of certain covered information that a website operator has collected or will collect
        about the resident. To submit such a request, please contact us via email at{" "}
        <strong>support@runwayhealth.com</strong> with{" "}
        <strong>“Nevada Opt-Out” in the subject line.</strong>
      </Text>

      <Title size="md" as="h3">
        12. CHANGES TO OUR PRIVACY POLICY
      </Title>
      <Text variant="base">
        Our Privacy Policy may change from time to time. We will not reduce your rights under this
        Privacy Policy without your consent in accordance with applicable law. We will post any
        privacy policy changes on this page and, if the changes are significant, we will provide a
        more prominent notice (including, for certain services, email notification of privacy policy
        changes). We will also keep prior versions of this Privacy Policy in an archive for your
        review.
      </Text>

      <Title size="md" as="h3">
        13. QUESTIONS AND HOW TO CONTACT US
      </Title>
      <Text variant="base">
        If you have any questions, concerns, complaints, or suggestions regarding our Privacy Policy
        or otherwise need to contact us, please email us at{" "}
        <Link href="mailto:support@runwayhealth.com">support@runwayhealth.com</Link>.
      </Text>
    </>
  );
};

export default Privacy;
