import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, FormGroup, Label, Link, TextField } from "@runwayhealth/runway-components-react";
import { PromoCode, Redemption, RootState } from "@store/../@types/state";
import { validateEmail } from "@store/../utils/utilsFormvalidation";
import { getPromoCode, getRedemptions } from "@store/admin/adminAction";

interface PromoCodeSearch {
  userEmail?: string;
}

const PromoCodeResults = ({
  promoCodes,
  setRedemptionsResult,
  filterPromoCodeResults,
}: {
  promoCodes: PromoCode[];
  setRedemptionsResult?: React.Dispatch<React.SetStateAction<boolean>>;
  filterPromoCodeResults?: (promoCodeId: string) => void;
}) => (
  <table>
    <thead>
      <tr>
        <th>Promo Code ID</th>
        <th>Code</th>
        <th># Redemptions</th>
        <th>First used</th>
        <th>Last used</th>
      </tr>
    </thead>
    <tbody>
      {promoCodes.map((promoCode: PromoCode) => {
        return (
          <tr key={`promoCode-${promoCode.promoCodeId}`}>
            <td>
              <Link href={`https://dashboard.stripe.com/promotion_codes/${promoCode.promoCodeId}`}>
                {promoCode.promoCodeId}
              </Link>
            </td>
            <td>{promoCode.code}</td>
            <td>
              <Link
                href="#"
                onClick={() => {
                  if (setRedemptionsResult) setRedemptionsResult(true);
                  if (filterPromoCodeResults) filterPromoCodeResults(promoCode.promoCodeId);
                }}
              >
                {promoCode.times_redeemed}
              </Link>
            </td>
            <td>{promoCode.first_time_redeemed}</td>
            <td>{promoCode.last_time_redeemed}</td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

const RedemptionsResult = ({
  redemptions,
  promoCodes,
}: {
  promoCodes: PromoCode[];
  redemptions: Redemption[];
}) => (
  <div>
    <PromoCodeResults promoCodes={promoCodes} />
    <table>
      <thead>
        <tr>
          <th>Redeemed on</th>
          <th>User</th>
        </tr>
      </thead>
      <tbody>
        {redemptions.map((redemption: Redemption) => (
          <tr>
            <td>{redemption.createdAt}</td>
            <td>{redemption.User.email}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
const PromoCodeSearch = ({ userEmail }: PromoCodeSearch) => {
  const dispatch = useDispatch();
  const promoCodes = useSelector<RootState, PromoCode[]>((state) => state.admin.promoCodes);
  const [textFieldValue, setTextFieldValue] = useState(userEmail || "");
  const redemptions = useSelector<RootState, Redemption[]>((state) => state.admin.redemptions);
  const [promoCodeId, setPromoCodeId] = useState("");
  const [email, setEmail] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [redemptionsResult, setRedemptionsResult] = useState(false);
  const [selectedPromoCodes, setSelectedPromoCodes] = useState<PromoCode[]>([]);
  // If user email is present as search param, execute search on mount.
  useEffect(() => {
    if (userEmail) {
      findPromoCode();
    }
  }, []);

  const findPromoCode = () => {
    // Checks if payload is an email.
    setRedemptionsResult(false);
    const isAnEmail = validateEmail(textFieldValue);

    if (isAnEmail) {
      setEmail(textFieldValue);
    } else {
      setPromoCodeId(textFieldValue);
    }

    const emailParam = userEmail ? userEmail : email;

    dispatch(getPromoCode({ promoCodeId, from, to, email: emailParam }));
  };

  const filterPromoCodeResults = (promoCodeId: string) => {
    const filteredPromoCodes = promoCodes.filter(
      (promoCode: PromoCode) => promoCode.promoCodeId === promoCodeId
    );
    setSelectedPromoCodes(filteredPromoCodes);

    dispatch(getRedemptions({ promoCodeId }));
  };

  return (
    <section>
      <h2>Promo Code Search 🛍️</h2>
      <div className="noMargin">
        <div className="row">
          <FormGroup>
            <p>Enter the promo code ID, or the user email, to track repemption of promo codes.</p>
            <div className="col-md-4">
              <TextField
                placeholder="Code ID or User Email"
                value={textFieldValue}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                  setTextFieldValue(evt.target.value)
                }
              />
            </div>
            <div className="col-md-4">
              <Label>From</Label>
              <TextField
                type="date"
                value={from}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setFrom(evt.target.value)}
              />
            </div>
            <div className="col-md-4">
              <Label>To</Label>
              <TextField
                type="date"
                value={to}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setTo(evt.target.value)}
              />
            </div>
          </FormGroup>
        </div>
        <div>
          <Button color="primary" className="nonMaterialAlignment" onClick={findPromoCode}>
            Search 🔍
          </Button>
        </div>
      </div>
      {!redemptionsResult && (
        <PromoCodeResults
          promoCodes={promoCodes}
          setRedemptionsResult={setRedemptionsResult}
          filterPromoCodeResults={filterPromoCodeResults}
        />
      )}
      {redemptionsResult && (
        <div>
          <RedemptionsResult promoCodes={selectedPromoCodes} redemptions={redemptions} />
          <button onClick={() => setRedemptionsResult(false)}>Go back</button>
        </div>
      )}
    </section>
  );
};

export default PromoCodeSearch;
