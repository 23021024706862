export let registration = {};

export const updateRegistrationObj = (name, value) => {
  return (registration[name] = value);
};

export let questions = [];

export const updateQuestions = (question, value, type) => {
  switch (value) {
    case "Yes":
      value = "true";
      type = "boolean";
      break;
    case "No":
      value = "false";
      type = "boolean";
      break;
    default:
      value = value;
      type = "string";
  }
  questions = [
    ...questions,
    {
      question: question,
      answer: value,
      type,
      important: true,
    },
  ];
};

export const shipping = {};
export const updateShipping = (name, value) => {
  return (shipping[name] = value);
};
