import { Link, Text } from "@runwayhealth/runway-components-react";

export const MemberLogin = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div>
      <Text className="login-content">
        Already a member?{" "}
        <Link as="span" onClick={onClick}>
          Log in
        </Link>
      </Text>
    </div>
  );
};
