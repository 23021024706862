import "./ForgotPassword.scss";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTitle } from "@hooks/useTitle";
import { Button } from "@runwayhealth/runway-components-react";
import { RootEntity, RootState } from "@store/../@types/state";
import { User } from "@store/../@types/user";
import { RESTORE_PASSWORD } from "@store/user/userActions";
import { toastError } from "@utils/utils";

import Header from "../../elements/Header";

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();

  const [errorText, setErrorText] = useState("");
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);
  useTitle("Password Reset");

  const [email, setEmail] = useState("");

  const submitEmail = () => {
    if (email !== "") {
      dispatch({
        type: RESTORE_PASSWORD,
        email,
      });
    } else {
      setErrorText("Please enter your email address.");
    }
  };
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setErrorText("");
    setEmail(e.currentTarget.value);
  };

  useEffect(() => {
    if (user.error.event) {
      setErrorText(user.error.message || "Unknown error.");
      toastError("Error", user.error.message);
    }
  }, [user.error]);

  return (
    <>
      <Header hasProgressBar={false} />
      <div className="forgot-password-page">
        <div className="password-container">
          <div className="forgot-password-container">
            <div className="forgot-password-content">
              <div className="forgot-password-form">
                <label className="forgot-password"> Forgot your password? </label>
                <p>Enter your email address and we’ll email you a code to reset your password.</p>
                <div className="form">
                  <div className="forgot-password-input">
                    <input
                      placeholder="Email Address"
                      name={"email"}
                      id="email"
                      type="email"
                      value={email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="button-div">
                    <Button
                      size="lg"
                      onClick={submitEmail}
                      iconName="ArrowRight"
                      isLoading={user.isPending}
                      disabled={user.isPending}
                      isBlock
                    >
                      Send recovery link
                    </Button>
                  </div>
                </div>
                <div className="error-message-forgot">
                  <span className="message-content-forgot">{errorText}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
