import { ReactNode } from "react";

import Modal from "@material-ui/core/Modal";
import Slide from "@material-ui/core/Slide";
import { Button } from "@runwayhealth/runway-components-react";

import { CloseButtonWrapper, ContentWrapper } from "./components/ContentWrapper";

type SliderProps = {
  open: boolean;
  close: () => void;
  content: ReactNode;
};

const InfoSlider = ({ open, close, content }: SliderProps) => {
  return (
    <div className="modalVisible">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => close()}
        style={{ alignItems: "center", justifyContent: "center" }}
      >
        <Slide direction="left" in={open} mountOnEnter unmountOnExit>
          <ContentWrapper>
            <CloseButtonWrapper>
              <Button
                size="sm"
                iconName={"X"}
                variant="outline"
                onClick={() => {
                  close();
                }}
                isIconOnly
              ></Button>
            </CloseButtonWrapper>
            {content}
          </ContentWrapper>
        </Slide>
      </Modal>
    </div>
  );
};

export default InfoSlider;
