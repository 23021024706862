import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Header from "../../elements/Header";
import YesNoQuestion from "@components/templates/YesNoQuestion";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import { userUpdate } from "../../../store/user/userSlice";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { useLoadOnTop } from "@hooks/useLoadOnTop";
import {
  Button,
  Text,
  Title,
  FormGroup,
  Label,
  TextField,
} from "@runwayhealth/runway-components-react";

const Allergies = () => {
  const dispatch = useDispatch();
  const [allergies, setAllergies] = useState("");
  const [hasAllergies, setHasAllergies] = useState(false);
  const currentQuestion = "Do you have allergies?";

  useTitle("Allergies");
  useLoadOnTop();

  const history = useHistory();

  useEffect(() => {
    sessionStorage.removeItem("allergies");
  }, []);

  const setNo = (value) => {
    dispatch(
      questionsUpdate({
        question: currentQuestion,
        value,
        important: false,
        questionType: "general",
      })
    );

    dispatch(userUpdate({ name: "allergies", value: "None" }));
    history.push("/child_vaccines");
  };

  const setAnswer = () => {
    if (allergies.length > 0) {
      dispatch(
        questionsUpdate({
          question: currentQuestion,
          value: allergies,
          important: true,
          questionType: "general",
        })
      );

      dispatch(userUpdate({ name: "allergies", value: allergies }));
      history.push("/child_vaccines");
    }
  };

  const YesAnswer = () => {
    setHasAllergies(true);
  };

  const collectAllergies = (e) => {
    setAllergies(e.target.value);
  };

  return (
    <>
      <Header progress={85} />
      <PageConsultation tabIndex={1}>
        <PageConsultation.Header>
          <Title size="giant">Do you have any allergies?</Title>
          <Text size="md">Fill the form below</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <YesNoQuestion
            setAnswer={YesAnswer}
            setNo={setNo}
            problems={true}
            isActive={hasAllergies}
          />

          {hasAllergies && (
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-9 col-sm-10">
                <FormGroup className="rwa-page-any-problems-form-group">
                  <Label>List your allergies including the severity for each</Label>
                  <TextField
                    as="textarea"
                    value={allergies}
                    onChange={collectAllergies}
                    placeholder="- Penicillin - mild&#13;&#10;- Peanuts - severe"
                  />
                </FormGroup>
              </div>
            </div>
          )}
        </PageConsultation.Body>
        {hasAllergies && (
          <Button
            size="lg"
            iconName="ArrowRight"
            customWidth={440}
            onClick={setAnswer}
            disabled={allergies.trim().length === 0}
          >
            Next
          </Button>
        )}
      </PageConsultation>
    </>
  );
};

export default Allergies;
