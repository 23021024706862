import "./menu.scss";

import { ChatCircleDots, FirstAidKit, Gift, IdentificationCard } from "phosphor-react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import { color } from "@runwayhealth/runway-components-react";
import { LOGOUT } from "@store/user/userActions";
import { LANDING_PAGE } from "@utils/constantsEnv";

import ArrowLeftSvg from "../../../img/ArrowLeft.svg";
import LogoutSvg from "../../../img/Logout.svg";
import LogoSvg from "../../../img/RunwayLogo.svg";

type Props = {
  setMenu: React.Dispatch<React.SetStateAction<boolean>>;
  userId: string;
  hasNotification: boolean;
  showRewardButton: boolean;
};

const Menu = ({ setMenu, userId, hasNotification, showRewardButton }: Props) => {
  const dispatch = useDispatch();
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  useEffect(() => {
    setScreenWidth(window.screen.width);
  }, [window.screen.width]);

  const logOut = async () => {
    dispatch({
      type: LOGOUT,
      userId,
    });
  };
  return (
    <div className="menu">
      <div className="menu-nav">
        <div className="logo">
          <div className={"menu-mobile"} onClick={() => setMenu(false)}>
            <img src={ArrowLeftSvg} alt="" />
            <span>Menu</span>
          </div>
          <a href={LANDING_PAGE} target="blank">
            <img className={"logo-desktop"} src={LogoSvg} alt="" style={{ width: "100%" }} />
          </a>
        </div>

        <NavLink
          to="/dashboard/cases"
          activeClassName="active"
          onClick={() => {
            screenWidth <= 768 ? setMenu(false) : setMenu(true);
          }}
        >
          <div className="menu-nav-link">
            <div className="menu-nav-link-item">
              <FirstAidKit size={24} weight="fill" color={color.brand.primary.medium} />
              Treatments
            </div>
          </div>
        </NavLink>

        <NavLink
          to="/dashboard/account"
          activeClassName="active"
          onClick={() => {
            screenWidth <= 768 ? setMenu(false) : setMenu(true);
          }}
        >
          <div className="menu-nav-link">
            <div className="menu-nav-link-item">
              <IdentificationCard size={24} color={color.brand.primary.medium} />
              Account
            </div>
          </div>
        </NavLink>

        <NavLink
          style={{ position: "relative" }}
          to="/dashboard/messages"
          onClick={() => {
            screenWidth <= 768 ? setMenu(false) : setMenu(true);
          }}
        >
          <div className="menu-nav-link">
            <div className="menu-nav-link-item">
              <ChatCircleDots size={24} color={color.brand.primary.medium} />
              Messages
              {hasNotification && <div className="notification-dot"></div>}
            </div>
          </div>
        </NavLink>

        {showRewardButton && (
          <NavLink
            to="/dashboard/referrals"
            activeClassName="active"
            onClick={() => {
              screenWidth <= 768 ? setMenu(false) : setMenu(true);
            }}
          >
            <div className="menu-nav-link">
              <div className="menu-nav-link-item">
                <Gift size={24} color={color.brand.primary.medium} />
                Earn rewards
              </div>
            </div>
          </NavLink>
        )}
      </div>
      <div className="logout" onClick={logOut}>
        <img src={LogoutSvg} alt="" />
        Log Out
      </div>
    </div>
  );
};

export default Menu;
