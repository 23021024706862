import { user } from "api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, TextField } from "@material-ui/core";
import { Case } from "@store/../@types/case";
import { RootState, UserSearchData } from "@store/../@types/state";
import { findPatientById, getCasesByUser } from "@store/admin/adminAction";

import CaseResults from "./CaseResults";

const UserSearch = () => {
  const [email, setEmail] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector<RootState, UserSearchData>((state) => state.admin.users);
  const cases = useSelector<RootState, Case[]>((state) => state.admin.cases);

  const findUser = () => {
    dispatch(findPatientById(email));
  };

  useEffect(() => {
    if (userData.id) {
      dispatch(getCasesByUser(userData.id));
    }
  }, [userData.email]);

  return (
    <section>
      <h2>Search User by Email 🕵️</h2>
      <div className="noMargin">
        <TextField
          type="email"
          id="email"
          value={email}
          onChange={(evt) => setEmail(evt.target.value)}
          label="Email"
        />
        <Button
          className="nonMaterialAlignment"
          variant="contained"
          color="primary"
          onClick={findUser}
        >
          Search 🔍
        </Button>
      </div>
      {userData && (
        <div className="noMargin">
          <div
            style={{ cursor: "pointer", marginLeft: 0 }}
            onClick={() => {
              setShowDetails(!showDetails);
            }}
          >
            <h3>User Info ⬇️</h3>
            <p>Click to show/hide</p>
          </div>
          <code style={{ display: showDetails ? "block" : "none" }}>
            <pre>{JSON.stringify(userData, null, 2)}</pre>
          </code>
          {showDetails ? <CaseResults cases={cases} /> : null}
        </div>
      )}
    </section>
  );
};

export default UserSearch;
