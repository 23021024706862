import "./ConfirmationModal.scss";

import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { Button, color, opacity, Text, Title } from "@runwayhealth/runway-components-react";

interface ConfirmationModalProps {
  open: boolean;
  title: string;
  message: string;
  handleChoice: (choice: boolean) => void;
}

const ConfirmationModal = ({ open, title, message, handleChoice }: ConfirmationModalProps) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      className="modal"
      BackdropComponent={Backdrop}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: `${color.neutral.darkest}`, opacity: `${opacity.semiOpaque}` },
      }}
    >
      <Fade in={open}>
        <div className="paper">
          <div className="text-wrapper">
            <Title as="h2" size="lg">
              {title}
            </Title>
            <Text>{message}</Text>
          </div>
          <div className="buttons-wrapper">
            <Button
              size="md"
              variant="outline"
              customWidth={180}
              onClick={() => handleChoice(false)}
              isBlock
            >
              Cancel
            </Button>
            <Button
              size="md"
              variant="primary"
              customWidth={180}
              onClick={() => handleChoice(true)}
              isBlock
            >
              Confirm
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ConfirmationModal;
