import React from "react";
import "./MediumInput.scss";

const MediumInput = ({
  isNone,
  placeholder,
  name,
  setOwnAnswer,
  renderProps,
  ref,
  onChange,
  inputType,
  setErrorText,
  handleBlur,
  value,
}) => {
  return (
    <div className="medium-input">
      {name !== "zip_code" ? (
        <input
          type={inputType ? inputType : "text"}
          name={name}
          value={value}
          onChange={onChange}
          disabled={isNone}
          {...renderProps}
          ref={ref}
          className="custom-input"
          placeholder={placeholder}
          onKeyUp={(e) => setOwnAnswer(e.target.value)}
        />
      ) : (
        <input
          type={inputType ? inputType : "text"}
          name={name}
          value={value}
          onChange={onChange}
          onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
          {...renderProps}
          ref={ref}
          onBlur={(e) => handleBlur(e.target.value)}
          onFocus={() => setErrorText("")}
          className="custom-input"
          placeholder={placeholder}
        />
      )}
    </div>
  );
};

export default MediumInput;
