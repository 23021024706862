import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import Header from "@elements/Header";
import { useTitle } from "@hooks/useTitle";
import { Text, Title } from "@runwayhealth/runway-components-react";
import { questionsUpdate } from "@store/questions/questionsSlice";

import YesNoQuestion from "../../templates/YesNoQuestion";

const MountainSickness = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useTitle("Mountain Sickness");
  let important = false;
  const setAnswer = (value: string) => {
    if (value === "Yes") {
      important = true;
    }

    dispatch(
      questionsUpdate({
        question: "Have you ever had symptoms of acute mountain sickness in the past?",
        value,
        important,
        questionType: "altitude",
      })
    );
    history.push("/conditions");
  };

  return (
    <>
      <Header progress={70} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">
            Have you ever had symptoms of acute mountain sickness in the past?
          </Title>
          <Text size="md">Choose one option</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <YesNoQuestion setAnswer={setAnswer} option="" />
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default MountainSickness;
