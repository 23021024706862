import { useState, useEffect } from "react";
import AutocompleteInput from "../../elements/AutocompleteInput";
import Header from "../../elements/Header";
import LongInput from "../../elements/LongInput";
import MediumInput from "../../elements/MediumInput";
import NextButton from "../../elements/NextButton";
import "./PharmacyPage.scss";
import MaskInput from "react-maskinput";
import { useDispatch, useSelector } from "react-redux";
import { pharmacies_save } from "../../../store/pharmacy/pharmacyAction";
import { error_change } from "../../../store/ui/uiAction";
import zipState from "zip-state";
import { useTitle } from "@hooks/useTitle";

const states = require("../WhatState/states.json");

const PharmacyPage = () => {
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [modal, setModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const dispatch = useDispatch();
  const error = useSelector((state) => state.ui.error);
  const [pharmacyData, setPharmacyData] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
  });
  useTitle("Pharmacy Page");

  const handleClick = () => {
    if (
      pharmacyData.name.length > 0 &&
      pharmacyData.address.length > 0 &&
      pharmacyData.city.length > 0 &&
      pharmacyData.state.length > 2 &&
      pharmacyData.zip_code.length >= 5
    ) {
      dispatch(
        pharmacies_save({
          ...pharmacyData,
          state: states.filter((i) => i.name === state)[0].abbreviation,
        })
      );
      console.log("clicked");
    } else {
      dispatch(
        error_change(
          "All fields except the phone number are required. Notice: zipcode length must be 5 or 9 symbols"
        )
      );
    }
  };
  useEffect(() => {
    if (state.length > 0) {
      setPharmacyData((prev) => ({ ...prev, state: state }));
    }
  }, [state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const abbreviationZipcode = zipState(value);
    setZipcode(abbreviationZipcode);

    if (name === "phoneOrFax") {
      setPharmacyData((prev) => ({
        ...prev,
        phoneOrFax: value.split("-").join(""),
      }));
    } else {
      setPharmacyData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleBlur = (e) => {
    const abbreviation = states.find((i) => i.name === state);

    if ((state.length > 2 && zipcode === 5) || 9) {
      if (abbreviation?.abbreviation !== zipcode) {
        setErrorText(
          "This ZIP Code does not correspond to the selected state. Please enter a valid ZIP Code"
        );
      } else {
        setErrorText("");
      }
    }
  };

  return (
    <div>
      <Header progress={98} />
      <div className="main-container">
        <div className="slide-headers">
          <p className="slide-header">Checkout</p>
          <p className="slide-question">Enter Your Pharmacy Address</p>
        </div>
        <div className="slide-main pharmacy-page-main">
          <p className="slide-descript">
            If prescribed, we will send your prescription to your local pharmacy of choice.
          </p>
        </div>
        <div className="slide-field">
          <LongInput name="name" placeholder="Name of pharmacy" handleChange={handleChange} />
          <LongInput name="address" placeholder="Address" handleChange={handleChange} />
          <LongInput placeholder="City" handleChange={handleChange} name="city" />
          <div className="inputs">
            <AutocompleteInput
              fontSize={"20px"}
              height={"30px"}
              setChange={handleChange}
              width={"300px"}
              name="state"
              placeholder="State"
              capturedVariant={setState}
            />
            <MediumInput
              inputType={"number"}
              placeholder="Zipcode"
              onChange={handleChange}
              handleBlur={handleBlur}
              setErrorText={setErrorText}
              name={"zip_code"}
            />
          </div>
          <div className="phone-inputs">
            <MaskInput
              className="login-input"
              // alwaysShowMask
              mask={"000-000-0000"}
              size={15}
              showMask={false}
              name={"phoneOrFax"}
              maskChar="_"
              placeholder="Phone Number"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className={errorText || error ? "error-message" : "error-message nonactive"}>
          <p className="message-content">{errorText || error}</p>
        </div>
        {/*<div className="home-address">*/}
        {/*  <label className="street-address-label">Your home address</label>*/}
        {/*  <span className="slide-header">*/}
        {/*    We also need your home address for your account information*/}
        {/*  </span>*/}
        {/*  <LongInput placeholder="Street address"/>*/}
        {/*</div>*/}
        <div className="buts-container">
          <NextButton content={"Save and continue"} handleClick={handleClick} />
        </div>
      </div>
    </div>
  );
};

export default PharmacyPage;
