import styled from "styled-components";

import { spacing } from "@runwayhealth/runway-components-react";

export const ParentalConsentFooter = styled.div`
  p {
    margin-top: ${spacing.xxs};
    text-align: left;
  }
`;
