import { toast } from "react-toastify";

export const toastSuccess = (header, description) => {
  toast.success(
    <div style={{ textAlign: "left" }}>
      <b>{header}</b>
      <br></br>
      {description}
    </div>,
    {
      theme: "colored",
    }
  );
};

export const toastWarn = (header, description) => {
  toast.warn(
    <div style={{ textAlign: "left" }}>
      <b>{header}</b>
      <br></br>
      {description}
    </div>,
    {
      theme: "colored",
    }
  );
};

export const toastError = (header, description, error) => {
  toast.error(
    <div style={{ textAlign: "left" }}>
      <b>{header}</b>
      <br></br>
      {description}
      <br></br>
      {error && <small>{`${error}`}</small>}
    </div>,
    {
      theme: "colored",
    }
  );
};

export const toastInfo = (header, description) => {
  toast.info(
    <div style={{ textAlign: "left" }}>
      <b>{header}</b>
      <br></br>
      {description}
    </div>,
    {
      theme: "colored",
    }
  );
};

// Fowler-Noll-Vo
export const fnv1a = (str) => {
  const FNV_OFFSET = 2166136261;
  const FNV_PRIME = 16777619;
  let hash = FNV_OFFSET;
  for (let i = 0; i < str.length; i++) {
    hash ^= str.charCodeAt(i);
    hash *= FNV_PRIME;
  }
  return (hash >>> 0).toString(); // Convert to unsigned 32-bit integer
};
