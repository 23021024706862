import "./LoginPage.scss";

import { History } from "history";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import useDataLayer, { GA4Events } from "@hooks/useDataLayer";
import { useTitle } from "@hooks/useTitle";
import { CircularProgress, TextField } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Button, Feedback, Link } from "@runwayhealth/runway-components-react";
import { ErrorEvents, RootEntity, RootState } from "@store/../@types/state";
import { User } from "@store/../@types/user";
import { LOGIN } from "@store/user/userActions";
import { toastError } from "@utils/utils";

import Header from "../../elements/Header";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiFormLabel-filled, .Mui-focused": {
        color: "#000000",
        fontWeight: 700,
        opacity: 1,
        textTransform: "uppercase",
        fontSize: "16px",
      },
      "& .Mui-focused input": {
        fontWeight: 400,
      },
      "& label": {
        color: "#393939",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "18px",
        opacity: 0.3,
      },
      "& > *": {
        width: "100%",
        fontSize: "16px",
        lineHeight: "18px",
      },
      "& .MuiInput-underline:after": {
        borderBottom: "1px solid #393939",
      },
      "& .MuiInput-underline:before": {
        borderBottom: "1px solid #393939",
      },
      "& .MuiInput-underline.Mui-focused:after": {
        borderBottom: "1px solid #393939",
      },
      "& .MuiInput-underline.Mui-focused:before": {
        borderBottom: "1px solid #393939",
      },
    },
  })
);

const LoginPage = () => {
  const dispatch = useDispatch();
  const { reportAccess } = useDataLayer(GA4Events.LOGIN);
  // TODO: Research history type.
  const history: History<any> = useHistory();
  const classes = useStyles();
  const location = useLocation<{ prevPath: string }>();
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);

  const [passwordShown, setPasswordShown] = useState(false);
  const [loginState, setLoginState] = useState({
    email: user.data.email || "",
    password: "",
  });

  const [errorText, setErrorText] = useState<string>("");
  useTitle("Login");

  useEffect(() => {
    // If there is a cart cookie, it means there is a new user, so we need
    // to redirect to the start consultation card.
    // const cookies = document.cookie.split(";");
    // const cartCookie = cookies.find((cookie) => cookie.includes("cart"));
    // if (cartCookie && cartCookie !== "") {
    //   history.push(`/cart/${cartCookie?.replace("cart=", "")}`);
    //   // Clear the cookie
    //   document.cookie = "cart=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // }
  }, []);

  useEffect(() => {
    // We watch the user ID field to see if it changes.
    // If it does, we know that a user has been logged in successfully.
    // If user is logged in, we start checking his or her desired intention
    // by checking out the following aspects.

    const prevPath = location?.state?.prevPath?.split("/")[1] || history?.location?.state?.from;
    if (user.data.id && localStorage.getItem("token")) {
      // Report login analytics
      reportAccess();
      // Aspect #1: User is starting a new questionnaire.
      if (
        prevPath === "dia" ||
        prevPath === "motion" ||
        prevPath === "altitude" ||
        prevPath === "insomnia" ||
        prevPath === "malaria" ||
        prevPath === "cart" ||
        prevPath === "plus"
      ) {
        return history.push("/welcome-back");
      }

      // Aspect #2: User is starting a new case.
      if (prevPath === "registration") {
        return history.push("/checkout");
      }

      // Aspect #3: Admin user.
      if (user.data.isAdmin) {
        return history.push("/dashboard/admin");
      } else {
        return history.push("/dashboard");
      }
    }
  }, [user.data.id]);

  useEffect(() => {
    if (user.error.event === ErrorEvents.SERVER_ERROR) {
      setErrorText(user.error.message || "Not autorized.");
      toastError("Error", user.error.message);
    }
  }, [user.error]);

  const authorization = () => {
    dispatch({
      type: LOGIN,
      email: loginState.email,
      password: loginState.password,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginState((prev) => ({ ...prev, [name]: value }));
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <Header hasProgressBar={false} />
      <div className="login-page" onKeyUp={(e) => e.key == "Enter" && authorization()}>
        <div className="right-side">
          <div className="sign-container">
            <div className="sign-up-content">
              <div className="login-form">
                <label className="sign-up"> Log In</label>
                <div className="form">
                  <div className="" style={{ width: "100%" }}>
                    <TextField
                      name="email"
                      className={classes.root}
                      style={{ width: "100%" }}
                      type="email"
                      id="standard-basic"
                      label="Email Address"
                      variant="standard"
                      onChange={handleChange}
                      value={loginState.email}
                      onFocus={() => setErrorText("")}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <TextField
                      name="password"
                      className={classes.root}
                      style={{ width: "100%" }}
                      type={passwordShown ? "text" : "password"}
                      id="standard-basic"
                      label="Password"
                      variant="standard"
                      autoComplete="current-password"
                      onChange={handleChange}
                      onFocus={() => setErrorText("")}
                    />
                    <div className="login-img">
                      {passwordShown ? (
                        <Visibility className="show" onClick={togglePassword} />
                      ) : (
                        <VisibilityOff className="show" onClick={togglePassword} />
                      )}
                    </div>
                  </div>
                  {user.isPending ? (
                    <CircularProgress />
                  ) : (
                    <div className="button-div">
                      <Button
                        size="lg"
                        iconName="ArrowRight"
                        isBlock
                        type="submit"
                        onClick={authorization}
                      >
                        Log In
                      </Button>
                    </div>
                  )}
                  <Link href="/forgot-password">Forgot password?</Link>
                </div>
                <Feedback>{errorText}</Feedback>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
