import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Header from "@components/elements/Header";
import { PageConsultation } from "@components/layouts/PageConsultation";
import YesNoQuestion from "@components/templates/YesNoQuestion";
import { useTitle } from "@hooks/useTitle";
import {
  Button,
  FormGroup,
  Label,
  Text,
  TextField,
  Title,
} from "@runwayhealth/runway-components-react";
import { RootEntity, RootState } from "@store/../@types/state";
import { User } from "@store/../@types/user";
import { questionsUpdate } from "@store/questions/questionsSlice";

enum ReferralAnswer {
  ITS_FOR_ME = "It's for me",
  ITS_FOR_SOMEONE_ELSE = "It's for someone else",
}

const WelcomeBack = () => {
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);

  const history = useHistory();
  useTitle("Welcome back!");
  const welcomeMessage = `Welcome back, ${user.data.first_name} ${user.data.last_name}.`;
  const currentQuestion = " Who is this consultation for?";

  const setAnswer = (value: string) => {
    if (value === ReferralAnswer.ITS_FOR_ME) {
      history.push("/state");
    } else {
      history.push("/dashboard?referral=true");
    }
  };
  return (
    <>
      <Header progress={95} />
      <PageConsultation className="rwa-page-any-problems" tabIndex={1}>
        <PageConsultation.Header>
          <Title size="giant">{welcomeMessage}</Title>
          <Text size="lg">{currentQuestion}</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <YesNoQuestion
            choiceOne={ReferralAnswer.ITS_FOR_ME}
            choiceTwo={ReferralAnswer.ITS_FOR_SOMEONE_ELSE}
            setAnswer={setAnswer}
          />
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default WelcomeBack;
