export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const createMessage = (payload) => ({
  type: CREATE_MESSAGE,
  payload,
});

export const GET_MESSAGES = "GET_MESSAGES";
export const getMessages = (payload) => ({
  type: GET_MESSAGES,
  payload,
});

export const GET_MESSAGES_LAST_PAGE = "GET_MESSAGES_LAST_PAGE";
export const getMessagesLastPage = (payload) => ({
  type: GET_MESSAGES_LAST_PAGE,
  payload,
});
