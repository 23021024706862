import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../elements/Header";
import RadioButton from "../../elements/RadioButton";
import "./WakingUp.scss";
import { useDispatch } from "react-redux";
import { questionsUpdate } from "@store/questions/questionsSlice";
import { ConditionTypes } from "@store/../@types/condition";
import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { useLoadOnTop } from "@hooks/useLoadOnTop";
import { Button, Text, Title } from "@runwayhealth/runway-components-react";

const WakingUp = () => {
  const variants = ["Often", "On occasion", "Rarely", "Never"];
  const [state, setState] = useState([false, false, false, false]);
  const dispatch = useDispatch();

  useEffect(() => {
    sessionStorage.setItem("consultation", ConditionTypes.INSOMNIA_CONSULTATION);
  }, []); // setting the kind of consultaion to present it in Entrance page

  useTitle("How often do you wake up?");
  useLoadOnTop();

  const capturedVariant = state
    .map((item, i) => (item ? variants[i] : false))
    .filter((variant) => variant)
    .join("");

  const history = useHistory();

  const setAnswer = () => {
    if (capturedVariant) {
      dispatch(
        questionsUpdate({
          question: "Do you have problems waking up too early?",
          value: capturedVariant,
          questionType: "insomnia",
        })
      );
      history.push("/sleep_troubles");
    }
  };

  const onKeyUpHandler = (e) => {
    e.key == "Enter" && setAnswer();
  };

  return (
    <>
      <Header progress={70} />
      <PageConsultation tabIndex={1} onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">Do you have problems waking up too early?</Title>
          <Text size="md">Choose one option</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <div className="rw-page-consultation-list-checkboxes">
            <RadioButton content={variants[0]} checked={state[0]} setChecked={setState} num={0} />
            <RadioButton content={variants[1]} checked={state[1]} setChecked={setState} num={1} />
            <RadioButton content={variants[2]} checked={state[2]} setChecked={setState} num={2} />
            <RadioButton content={variants[3]} checked={state[3]} setChecked={setState} num={3} />
          </div>
        </PageConsultation.Body>

        <Button
          size="lg"
          iconName="ArrowRight"
          customWidth={440}
          onClick={setAnswer}
          disabled={!capturedVariant}
        >
          Next
        </Button>
      </PageConsultation>
    </>
  );
};

export default WakingUp;
