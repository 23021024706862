import { push } from "connected-react-router";
import { call, put, retry, take, takeEvery } from "redux-saga/effects";

import * as Sentry from "@sentry/react";
import { REQUEST_PRESCRIPTIONS_PRICES } from "@store/prices/pricesActions";
import { requestPrescriptionsPrices } from "@store/prices/pricesSaga";
import { userMutationSuccess } from "@store/user/userSlice";
import { fnv1a, toastSuccess } from "@utils/utils";

import { Case, Question } from "../../@types/case";
import { Condition, ConditionTypes } from "../../@types/condition";
import { ErrorEvents, PricesState, QuestionsStateData, selectState } from "../../@types/state";
import { cases, order, stripe, user } from "../../api";
import { travelLengthQuestion } from "../../components/pages/TravellingLong";
import * as casesActions from "./casesActions";
import * as casesSlice from "./casesSlice";

function* requestConditions({ consultation, pricesIds }: casesActions.SetConditions) {
  yield put(casesSlice.newCaseFailure({}));
  yield put(casesSlice.newCasePending());
  let searchObject = {};
  const searchMethod = consultation ? "products" : "prices";

  if (consultation) {
    searchObject = {
      metadata: {
        name: consultation,
      },
    };
  } else {
    searchObject = {
      pricesIds: pricesIds || [],
    };
  }

  try {
    const { data } = yield call(() => stripe.stripePost(searchMethod, searchObject));
    yield put(casesSlice.setConditions({ conditions: data }));
  } catch (e) {
    const exception: any = e;
    const event = exception?.response?.status === 401 ? ErrorEvents.ACCESS_DENIED : undefined;
    const message = event || exception?.response?.statusText || "Unknown error.";

    if (message === "Unknown error.") {
      // If a type error is present, interceptors are having problems.
      // Most likely becuase a corrupted token is being used.
      localStorage.removeItem("token");
    }

    yield put(
      casesSlice.newCaseFailure({
        event,
        message,
      })
    );
  }
}

function* addConditions({ conditions }: casesActions.AddConditions) {
  yield put(casesSlice.newCaseFailure({}));
  yield put(casesSlice.newCasePending());
  try {
    const namesBatch = conditions.map((condition) => {
      return { name: condition };
    });
    const { data } = yield call(() =>
      stripe.stripePost("products", {
        metadata: namesBatch,
      })
    );
    yield put(casesSlice.addConditions(data));
  } catch (e) {
    const exception: any = e;
    const event = exception?.response?.status === 401 ? ErrorEvents.ACCESS_DENIED : undefined;
    const message = event || exception?.response?.statusText || "Unknown error.";

    yield put(
      casesSlice.newCaseFailure({
        event,
        message,
      })
    );
  }
}

function* finalizeCase({ newCase }: casesActions.FinalizeCase) {
  // Gets prices
  const prices: PricesState = yield selectState<PricesState>((state) => state.price);
  const questions: QuestionsStateData = yield selectState<QuestionsStateData>(
    (state) => state.questions
  );
  if (newCase.conditions.length > 0) {
    // Set prices to case conditions if any.
    yield call(mapConditionPrescriptions, {
      type: casesActions.MAP_CONDITION_PRESCRIPTIONS,
      conditions: newCase.conditions,
      questions,
      prices,
    });
  }

  // It arranges the questions in a single array.
  const flatQuestions = Object.values(questions).flatMap((question) => question);

  // Set MDI consent answer.
  flatQuestions.push({
    question: "MDI telemedicine consent acknowledgement",
    answer: "Yes",
    type: "string",
    important: true,
  });

  yield put(casesSlice.updateCaseQuestions(flatQuestions));

  // Create the case after finalization.
  const finalizedCase: Case = yield selectState<Case>((state) => state.case.newCase.data);
  yield put({ type: casesActions.CREATE_CASE, newCase: finalizedCase });
}

function* createCase({ newCase }: casesActions.CreateCase) {
  yield put(casesSlice.newCaseFailure({}));
  yield put(casesSlice.newCasePending());

  // Create questionnaire fingerprint.
  const questionsFingerprint = fnv1a(newCase.case_questions.toString());
  try {
    // Post the case.
    const { data } = yield call(() =>
      cases.casePost("", {
        case: { ...newCase, questionsFingerprint },
      })
    );
    // Update cases state.
    yield put(casesSlice.newCaseSuccess(data));
    yield put(
      casesSlice.addCaseToFeed([
        {
          isPending: false,
          data,
        },
      ])
    );
  } catch (error) {
    const exception: any = error;
    const event = exception?.response?.status === 401 ? ErrorEvents.ACCESS_DENIED : undefined;
    const message =
      event || exception?.response?.statusText || exception?.message || "Unknown error.";

    if (message === ErrorEvents.ERR_NETWORK) {
      try {
        // Retry 3 times if fails if error network happens.
        const { data } = yield retry(3, 3000, cases.casePost, "", {
          case: { ...newCase, questionsFingerprint },
        });
        // Update cases state.
        yield put(casesSlice.newCaseSuccess(data));
      } catch (error) {
        Sentry.captureException(error);
        Sentry.captureMessage("Couldn't create case");

        yield put(
          casesSlice.newCaseFailure({
            event,
            message,
          })
        );
      } finally {
        // Break process
        return;
      }
    }

    if (event !== ErrorEvents.ACCESS_DENIED) {
      Sentry.captureException(error);
      Sentry.captureMessage("Couldn't create case");
    }

    yield put(
      casesSlice.newCaseFailure({
        event,
        message,
      })
    );
  }
}

function* updateCase({ caseId, updateBody }: casesActions.UpdateCase) {
  yield put(casesSlice.newCaseFailure({}));
  yield put(casesSlice.newCasePending());
  try {
    const { data } = yield call(() => cases.casePut(`/${caseId}`, updateBody));
    yield put(casesSlice.newCaseSuccess(data));
  } catch (error) {
    const exception: any = error;
    const event = exception?.response?.status === 401 ? ErrorEvents.ACCESS_DENIED : undefined;
    const message = event || exception?.response?.statusText || "Unknown error.";

    if (event !== ErrorEvents.ACCESS_DENIED) {
      Sentry.captureException(error);
      Sentry.captureMessage(`Couldn't update case: ${caseId}`);
    }

    yield put(
      casesSlice.newCaseFailure({
        event,
        message,
      })
    );
  }
}

function* payCase({ newCase, promoCodeId, transaction }: casesActions.PayCase) {
  // Wait for payment method to be set.
  yield take(userMutationSuccess);

  // Initiate loaders.
  yield put(casesSlice.newCaseFailure({}));
  yield put(casesSlice.newCasePending());

  try {
    // Check if case has an invoice.
    if (!newCase?.invoiceId) {
      // Create case invoice.
      const { data } = yield call(() =>
        cases.casePost("/invoice", {
          caseId: newCase.id,
          promoCodeId,
          transaction,
        })
      );
      yield put(casesSlice.newCaseInvoiceCreated(data));
    }
    // We return the pending state after successfully creating the invoice.
    yield put(casesSlice.newCasePending());
    // Pay the case.
    const { data } = yield call(() =>
      cases.casePost("/pay", {
        caseId: newCase.id,
      })
    );
    // Then succeed the new case.
    yield put(casesSlice.newCaseSuccess(data.case));
    yield put(push("/thank_you"));
  } catch (error) {
    const exception: any = error;
    let event: string | undefined;

    if (exception?.response?.status === 404) {
      event = ErrorEvents.NOT_FOUND;
    } else if (exception?.response?.status === 400) {
      event = ErrorEvents.BAD_REQUEST;
    } else if (exception?.response?.status === 401) {
      event = ErrorEvents.ACCESS_DENIED;
    }

    yield put(
      casesSlice.newCaseFailure({
        event,
        message: exception?.response?.data?.message,
      })
    );
  }
}

function* sendCase({ newCase, isPlus }: casesActions.SendCase) {
  // Check if case is paid.
  if (!newCase?.invoiceId) {
    // Wait for case to be paid.
    yield take(casesSlice.newCaseSuccess);
  }

  yield put(casesSlice.caseMutationFailure({ caseId: newCase.id }));
  yield put(casesSlice.caseMutationPending({ caseId: newCase.id }));

  try {
    const { data } = yield call(() =>
      cases.casePost("/send", {
        caseId: newCase.id,
        isPlus,
      })
    );
    yield put(
      casesSlice.updateCaseSuccess({
        caseId: newCase.id,
        data,
      })
    );
  } catch (error) {
    const exception: any = error;
    let event: string | undefined;

    if (exception?.response?.status === 404) {
      event = ErrorEvents.NOT_FOUND;
    } else if (exception?.response?.status === 400) {
      event = ErrorEvents.BAD_REQUEST;
    } else if (exception?.response?.status === 401) {
      event = ErrorEvents.ACCESS_DENIED;
    } else if (exception?.response?.data?.message === ErrorEvents.PENDING_INVOICE) {
      event = ErrorEvents.PENDING_INVOICE;
    }

    yield put(
      casesSlice.newCaseFailure({
        event,
        message: exception?.response?.data?.message,
      })
    );

    yield put(
      casesSlice.caseMutationFailure({
        caseId: newCase.id,
        error: {
          event,
          message: exception?.response?.data?.message,
        },
      })
    );
  }
}

function* requestCases({ userId }: casesActions.RequestCases) {
  yield put(casesSlice.getCasesFailure({}));
  yield put(casesSlice.getCasesPending());
  try {
    const { data } = yield call(() => user.userGet(`${userId}/cases`));
    yield put(casesSlice.getCasesSuccess(data));
  } catch (error) {
    const exception: any = error;
    const event = exception?.response?.status === 401 ? ErrorEvents.ACCESS_DENIED : undefined;
    const message = event || exception?.response?.statusText || "Unknown error.";

    if (event !== ErrorEvents.ACCESS_DENIED) {
      Sentry.captureException(error);
      Sentry.captureMessage(`User cannot fetch cases ${userId}`);
    }

    yield put(
      casesSlice.getCasesFailure({
        event,
        message,
      })
    );
  }
}

function* refreshCases({ userId }: casesActions.RefreshCases) {
  yield put(casesSlice.getCasesFailure({}));
  yield put(casesSlice.getCasesPending());
  try {
    const { data } = yield call(() => cases.casePut(`/telehealth/sync?userId=${userId}`));
    yield put(casesSlice.getCasesSuccess(data));
  } catch (error) {
    const exception: any = error;
    const event = exception?.response?.status === 401 ? ErrorEvents.ACCESS_DENIED : undefined;
    const message = event || exception?.response?.statusText || "Unknown error.";

    if (event !== ErrorEvents.ACCESS_DENIED) {
      Sentry.captureException(error);
      Sentry.captureMessage(`User cannot fetch cases ${userId}`);
    }

    yield put(
      casesSlice.getCasesFailure({
        event,
        message,
      })
    );
  }
}

function* requestOrderInvoice({ orderId, caseId }: casesActions.RequestOrderInvoice) {
  yield put(
    casesSlice.caseMutationFailure({
      caseId: caseId,
      error: {},
    })
  );
  yield put(casesSlice.caseMutationPending({ caseId }));
  try {
    const { data } = yield call(() => order.orderGet(`/${orderId}/invoice`));
    yield put(
      casesSlice.getInvoiceSuccess({
        caseId,
        data,
      })
    );
  } catch (error) {
    Sentry.captureException(error);
    Sentry.captureMessage(`User cannot fetch order summary ${orderId}`);
    const exception: any = error;
    yield put(
      casesSlice.caseMutationFailure({
        caseId,
        error: {
          event: ErrorEvents.PAY_ORDER,
        },
      })
    );
  }
}

function* payOrder({ orderId, caseId }: casesActions.PayOrder) {
  yield put(
    casesSlice.caseMutationFailure({
      caseId: caseId,
      error: {},
    })
  );
  yield put(casesSlice.caseMutationPending({ caseId }));
  try {
    const { data } = yield call(() => order.orderGet(`/${orderId}/pay`));
    yield put(
      casesSlice.updateOrderSuccess({
        caseId,
        data,
      })
    );
    yield call(sendOrder, {
      type: casesActions.SEND_ORDER,
      orderId,
      caseId,
    });
    toastSuccess(
      "Payment success!",
      "Your order is still processing, we'll notify you when shipped."
    );
  } catch (error) {
    Sentry.captureException(error);
    Sentry.captureMessage(`User cannot pay order ${orderId}`);

    const exception: any = error;

    yield put(
      casesSlice.caseMutationFailure({
        caseId,
        error: {
          event: ErrorEvents.PAY_ORDER,
        },
      })
    );
  }
}

function* sendOrder({ orderId, caseId }: casesActions.SendOrder) {
  yield put(
    casesSlice.caseMutationFailure({
      caseId: caseId,
      error: {},
    })
  );
  yield put(casesSlice.caseMutationPending({ caseId }));
  try {
    const { data } = yield call(() => order.orderGet(`/${orderId}/send`));
    yield put(
      casesSlice.updateOrderSuccess({
        caseId,
        data,
      })
    );
  } catch (error) {
    Sentry.captureException(error);
    Sentry.captureMessage(`User cannot pay order ${orderId}`);
    const exception: any = error;

    yield put(
      casesSlice.caseMutationFailure({
        caseId,
        error: {
          event: ErrorEvents.SEND_ORDER,
        },
      })
    );
  }
}

function* upsellCase({ destination, chosenConditions }: casesActions.UpsellCase) {
  yield put(casesSlice.newCaseFailure({}));
  yield put(casesSlice.newCasePending());

  try {
    // Get questions to map prices.
    const questions: QuestionsStateData = yield selectState<QuestionsStateData>(
      (state) => state.questions
    );
    const initialConditions: Condition[] = yield selectState<Condition[]>(
      (state) => state.case.newCase.data.conditions
    );
    // Upsell case
    const { data } = yield call(() =>
      cases.casePost("/upsell", {
        destination,
        chosenConditions,
      })
    );

    const upsoldConditions: Condition[] = data.conditions;

    // Get prescription prices.
    yield call(requestPrescriptionsPrices, {
      type: REQUEST_PRESCRIPTIONS_PRICES,
      conditions: initialConditions.concat(upsoldConditions),
    });

    if (upsoldConditions.length > 0) {
      // Store new upsell state.
      yield put(casesSlice.setUpsellState(data));
      const prices: PricesState = yield selectState<PricesState>((state) => state.price);

      // Map pricing to upsell conditions.
      yield call(mapConditionPrescriptions, {
        type: casesActions.MAP_CONDITION_PRESCRIPTIONS,
        conditions: upsoldConditions,
        questions,
        prices,
        upsell: true, // Mapping for upsell conditions is true
      });

      yield put(push("/addon"));
    } else {
      yield put(push("/departure"));
    }
  } catch (error) {
    yield put(push("/departure"));
    Sentry.captureException(error);
    Sentry.captureMessage("Upsell module was skipped.");
  }
}
// function* cancelCase(action) {
//   //action.payload = case_id
//   try {
//     const { data } = yield call(() => md.mdPost("cancel_case"));
//   } catch (error) {
//     console.log(error.response);
//   }
// }

function* mapConditionPrescriptions({
  conditions,
  questions,
  prices,
  upsell,
}: casesActions.MapConditionPrescriptions) {
  const mappedConditions = conditions.map((condition: Condition) => {
    const prescriptionId =
      condition.product.metadata?.medication || condition.product.metadata?.medication_1;
    const prescriptionPrice = prices.prescriptions.data.find(
      (price) => price.id === prescriptionId
    );
    const metadata = prescriptionPrice?.product?.metadata;
    let prescriptionCostAmount = prescriptionPrice?.unit_amount || 0;
    let mdiId = metadata?.MDI_ID;
    let quantity = parseInt(metadata?.quantity ?? "0");
    if (condition.product.name === ConditionTypes.MALARIA_CONSULTATION) {
      // Check travel length answers to estimate Malaria costs.
      const travelLengthQuestionObject = questions.general?.find((question: Question) =>
        question.question.includes(travelLengthQuestion)
      );

      const travelDays = parseInt(
        travelLengthQuestionObject?.answer.split("-")[1] ??
          travelLengthQuestionObject?.answer ??
          "1"
      );

      if (travelDays > 7) {
        // Get week increase.
        // We substract the first week.
        const weekIncrease = travelDays / 7 - 1;

        if (weekIncrease > 1) {
          // Price increase after first two weeks.
          // Increase 25 dollars per increased week minus the second week
          const priceIncrease = (weekIncrease - 1) * 2500;
          prescriptionCostAmount += Math.round(priceIncrease);
        }

        // Increase 7 tablets in quantity per increased week.
        const quantityIncrease = 7 * weekIncrease;
        quantity += Math.round(quantityIncrease);
      }
    }

    return {
      ...condition,
      suggestedMedication: {
        ...condition.suggestedMedication,
        quantity,
        id: mdiId,
      },
      price: prescriptionCostAmount || 0,
    };
  });

  yield put(casesSlice.setConditions({ conditions: mappedConditions, upsell }));
}

export default function* caseSaga() {
  yield takeEvery(casesActions.ADD_CONDITIONS, addConditions);
  yield takeEvery(casesActions.SET_CONDITIONS, requestConditions);
  yield takeEvery(casesActions.FINALIZE_CASE, finalizeCase);
  yield takeEvery(casesActions.CREATE_CASE, createCase);
  yield takeEvery(casesActions.UPDATE_CASE, updateCase);
  yield takeEvery(casesActions.PAY_CASE, payCase);
  yield takeEvery(casesActions.SEND_CASE, sendCase);
  yield takeEvery(casesActions.REQUEST_CASES, requestCases);
  yield takeEvery(casesActions.REFRESH_CASES, refreshCases);
  // yield takeEvery(CANCEL_CASE, cancelCase);
  yield takeEvery(casesActions.REQUEST_ORDER_INVOICE, requestOrderInvoice);
  yield takeEvery(casesActions.PAY_ORDER, payOrder);
  yield takeEvery(casesActions.SEND_ORDER, sendOrder);
  yield takeEvery(casesActions.MAP_CONDITION_PRESCRIPTIONS, mapConditionPrescriptions);
  yield takeEvery(casesActions.UPSELL_CASE, upsellCase);
}
