import { useState } from "react";
import { useDispatch } from "react-redux";

import { UPDATE_SHIPPING_ADDRESS } from "@store/user/userActions";

import { RootEntity } from "../../../../../../../@types/state";
import { User } from "../../../../../../../@types/user";
import ArrowRightSvg from "../../../../../../../img/ArrowRightBlack.svg";
import AutocompleteInput from "../../../../../../elements/AutocompleteInput";
import TextFieldDisable from "../../../../../../elements/TextFieldDisable/TextFieldDisable";
import { State } from "../../../../../WhatState/stateAvailability";

const states = require("../../../../../WhatState/states.json");

interface AddressProps {
  disableBillingForm: boolean;
  user: RootEntity<User>;
  setDisableBillingForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShippingAccount = ({ disableBillingForm, user, setDisableBillingForm }: AddressProps) => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState(user.data.address);
  const [state, setState] = useState<string>("");
  const [openStateDropdown, setOpenStateDropdown] = useState(false);

  const handlerAddress = (name: string, value: string) => {
    setAddress((prev) => ({ ...prev, [name]: value }));
  };

  const save = () => {
    dispatch({
      type: UPDATE_SHIPPING_ADDRESS,
      address,
    });
    setDisableBillingForm(true);
  };

  return (
    <>
      <form className="account-billing-form">
        <TextFieldDisable
          type={"text"}
          disabled={disableBillingForm}
          title={"Address"}
          defaultValue={user.data.address.address}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            handlerAddress("address", e.currentTarget.value)
          }
        />
        <TextFieldDisable
          type={"text"}
          disabled={disableBillingForm}
          title={"City"}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            handlerAddress("city_name", e.currentTarget.value)
          }
          defaultValue={user.data.address.city_name}
        />
        <div className="account-billing-form-inputs">
          <label className="disable-input">
            <span className={"disable-input-title"}>State</span>
            <AutocompleteInput
              open={openStateDropdown}
              options={states.map((state: State) => state.name)}
              width={"100%"}
              placeholder="State"
              defaultValue={user.data.address.state_name}
              handleBlur={() => {
                handlerAddress("state_name", state);
              }}
              capturedVariant={(e) => {
                setState(e);
                setOpenStateDropdown(false);
              }}
              onKeyUp={() => setOpenStateDropdown(true)}
              disabled={disableBillingForm}
            />
          </label>

          <TextFieldDisable
            type={"text"}
            disabled={disableBillingForm}
            title={"Zipcode"}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              handlerAddress("zip_code", e.currentTarget.value)
            }
            defaultValue={user.data.address.zip_code}
          />
        </div>
        <div className="account-billing-form-subtitle">
          <h4>Home address</h4>
          <p>We also need your home address for your account information</p>
        </div>
        <TextFieldDisable
          type={"text"}
          disabled={true}
          title={"Street Address"}
          onChange={() => {}}
          defaultValue={`${user.data.address.address}, ${user.data.address.city_name}, ${user.data.address.state_name}, ${user.data.address.zip_code}`}
        />
      </form>
      <br />
      <br />
      <button className="button-save" onClick={save}>
        Save <img src={ArrowRightSvg} alt="" />
      </button>
      {user.error && (
        <div className="error-message-forgot">
          <span className="message-content-forgot">{user.error.message}</span>
        </div>
      )}
    </>
  );
};

export default ShippingAccount;
