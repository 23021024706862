import styled from "styled-components";

import { from, spacing } from "@runwayhealth/runway-components-react";

export const ParentalConsentBody = styled.div`
  text-align: left;
  margin-top: ${spacing.nano};

  .input-birthday {
    label {
      margin-bottom: ${spacing.quarck};
    }
  }

  .date-inputs {
    align-items: flex-start;
  }

  .upload-image {
    margin: ${spacing.sm} 0;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: ${spacing.xxxs};

    label {
      margin-bottom: 0;
    }

    .rw-feedback {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: ${spacing.xxxs};
    }
  }

  .legal-options {
    margin-bottom: ${spacing.nano};
    label {
      display: block;
      margin-bottom: 0;
    }
  }

  .rw-checkbox-feedback {
    margin-top: ${spacing.quarck};
    padding-left: ${spacing.xs};

    @media ${from.mobileS} {
      margin-top: -6px;
    }
  }

  .MuiFormControlLabel-root {
    line-height: 0;
    margin-right: 0;
  }
`;
