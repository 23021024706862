import "./condition.scss";

import classNames from "classnames";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { CaseStatuses } from "@store/../@types/case";
import { Condition as ConditionType } from "@store/../@types/condition";

import OrderImg from "../../../../../../../img/orders/runwayhealthpill_3 4.png";
import TrackingSvg from "../../../../../../../img/orders/truck.svg";

type Props = {
  condition: ConditionType;
  caseStatus: CaseStatuses;
};

const Condition = ({ condition, caseStatus }: Props) => {
  const isRejected = !condition.suggestedMedication?.name && caseStatus === CaseStatuses.COMPLETED;

  return (
    <Col xs={12} md={12} lg={6}>
      <Container>
        <Row>
          <Col xs={4}>
            <div className="treatment-order">
              <img src={OrderImg} alt={condition.suggestedMedication?.name} />
            </div>
          </Col>
          <Col className="centered-text">
            <div className="treatment-order-description">
              <h3>{condition.product.name}</h3>
              <p>{condition.suggestedMedication?.name ?? ""}</p>
              {isRejected && <button className="order-button">Dismiss</button>}
            </div>
          </Col>
        </Row>
      </Container>
    </Col>
  );
};

export default Condition;
