import update from "immutability-helper";

import { combineReducers, createSlice } from "@reduxjs/toolkit";
import { Case, Order } from "@store/../@types/case";
import { RootEntity } from "@store/../@types/state";

const orderObject: Order = {
  id: 0,
  order_id: "",
  status: "",
  UserId: "",
  carrier: "",
  caseId: "",
  createdAt: "",
  updatedAt: "",
  shipping_method: null,
  tracking_number: "",
  departureDate: "",
};
const caseObject: RootEntity<Case> = {
  isPending: false,
  data: {
    caseId: null,
    conditions: [],
    invoiceId: null,
    case_status: null,
    case_questions: [],
    shipping_method: null,
    CurexaOrder: orderObject,
    updatedAt: Date.now().toString(),
    UserId: null,
  },
  error: {},
};

const newCaseState: RootEntity<Case> = {
  isPending: false,
  data: caseObject.data,
  error: {},
};

export const newCaseSlice = createSlice({
  name: "newCase",
  initialState: newCaseState,
  reducers: {
    addConditions: (state, action) => {
      return update(state, {
        isPending: {
          $set: false,
        },
        data: {
          conditions: {
            $push: action.payload,
          },
        },
      });
    },
    removeConditions: (state, action) => {
      const indexOfCondition = state.data.conditions.findIndex(
        (condition) => condition.product.name === action.payload
      );
      return update(state, {
        data: {
          conditions: {
            $splice: [[indexOfCondition, 1]],
          },
        },
      });
    },
    setConditions: (state, action) => {
      if (action.payload.upsell) {
        // If upsell then set upsell conditions.
        return {
          ...state,
          isPending: false,
          data: {
            ...state.data,
            upsell: { ...state.data.upsell, conditions: action.payload.conditions },
          },
        };
      }

      return {
        ...state,
        isPending: false,
        data: { ...state.data, conditions: action.payload.conditions },
      };
    },
    setConditionUnits: (state, action) => {
      const indexOfCondition = state.data.conditions.findIndex(
        (condition) => condition.product.id === action.payload.productId
      );
      return update(state, {
        data: {
          conditions: {
            [indexOfCondition]: {
              product: {
                units: {
                  $set: action.payload.units,
                },
              },
            },
          },
        },
      });
    },
    updateShippingMethod: (state, action) => {
      return {
        ...state,
        data: { ...state.data, shipping_method: action.payload },
      };
    },
    updateCaseQuestions: (state, action) => {
      return {
        ...state,
        data: { ...state.data, case_questions: action.payload },
      };
    },
    setUser: (state, action) => {
      return {
        ...state,
        data: { ...state.data, UserId: action.payload },
      };
    },
    loadCase: (state, action) => {
      return {
        ...state,
        data: action.payload.data,
      };
    },
    newCasePending: (state) => {
      return Object.assign({}, state, { isPending: true });
    },
    newCaseSuccess: (state, action) => {
      return Object.assign({}, state, {
        data: action.payload,
        isPending: false,
      });
    },
    newCaseFailure: (state, action) => {
      return Object.assign({}, state, { error: action.payload, isPending: false });
    },
    newCaseInvoiceCreated: (state, action) => {
      return Object.assign({}, state, {
        data: action.payload,
        isPending: false,
      });
    },
    setUpsellState: (state, action) => {
      return {
        ...state,
        isPending: false,
        data: { ...state.data, upsell: action.payload },
      };
    },
    removeUpsellConditions: (state, action) => {
      const indexOfCondition =
        state.data.upsell?.conditions.findIndex(
          (condition) => condition.product.name === action.payload
        ) ?? -1;

      if (indexOfCondition >= 0) {
        return update(state, {
          data: {
            upsell: {
              conditions: {
                $splice: [[indexOfCondition, 1]],
              },
            },
          },
        });
      }
    },
    clearNewCase: (state) => {
      return Object.assign({}, state, newCaseState);
    },
  },
});

const casesArray: RootEntity<Case>[] = [];

const casesState: RootEntity<RootEntity<Case>[]> = {
  isPending: false,
  data: casesArray,
  error: {},
};

export const casesSlice = createSlice({
  name: "cases",
  initialState: casesState,
  reducers: {
    getCasesPending: (state) => {
      return Object.assign({}, state, { isPending: true });
    },
    getCasesSuccess: (state, action) => {
      // We merge open cases with closed ones.
      const incomingCases = new Set<number>(action.payload.map((case_: Case) => case_.id));

      const newState = state.data.filter((case_) => {
        const incomingCase = incomingCases.has(case_.data.id || 0);

        if (!incomingCase) {
          return case_;
        }
      });

      const cases = action.payload.map((case_: any) => {
        case_ = {
          isPending: false,
          data: {
            ...case_,
            conditions: case_.conditions || [],
          },
          error: {},
        };
        return case_;
      });

      return Object.assign({}, state, {
        isPending: false,
        data: newState.concat(cases),
      });
    },
    getCasesFailure: (state, action) => {
      return Object.assign({}, state, { isPending: false, error: action.payload });
    },
    caseMutationPending: (state, action) => {
      const indexOfCase = state.data.findIndex((case_) => case_.data.id === action.payload.caseId);
      // Deep nesting update.
      return update(state, {
        // Cases layer
        data: {
          // ID of the case.
          // Single case layer
          [indexOfCase]: {
            isPending: {
              $set: true,
            },
          },
        },
      });
    },
    getInvoiceSuccess: (state, action) => {
      const indexOfCase = state.data.findIndex((case_) => case_.data.id === action.payload.caseId);
      return update(state, {
        // Cases layer
        data: {
          // Single case layer.
          [indexOfCase]: {
            isPending: {
              $set: false,
            },
            data: {
              CurexaOrder: {
                invoice: {
                  $set: action.payload.data,
                },
              },
            },
          },
        },
      });
    },
    updateOrderSuccess: (state, action) => {
      const indexOfCase = state.data.findIndex((case_) => case_.data.id === action.payload.caseId);
      return update(state, {
        data: {
          [indexOfCase]: {
            isPending: {
              $set: false,
            },
            data: {
              CurexaOrder: {
                $set: action.payload.data,
              },
            },
          },
        },
      });
    },
    updateCaseSuccess: (state, action) => {
      const indexOfCase = state.data.findIndex((case_) => case_.data.id === action.payload.caseId);
      return update(state, {
        data: {
          [indexOfCase]: {
            isPending: {
              $set: false,
            },
            data: {
              $set: action.payload.data,
            },
          },
        },
      });
    },
    addCaseToFeed: (state, action) => {
      return update(state, {
        data: {
          $push: action.payload,
        },
      });
    },
    caseMutationFailure: (state, action) => {
      const indexOfCase = state.data.findIndex((case_) => case_.data.id === action.payload?.caseId);
      return update(state, {
        data: {
          // ID of the case.
          [indexOfCase]: {
            isPending: {
              $set: false,
            },
            error: {
              $set: action.payload.error,
            },
          },
        },
      });
    },
    setCasePendingPayment: (state, action) => {
      const indexOfCase = state.data.findIndex((case_) => case_.data.id === action.payload?.caseId);
      return update(state, {
        data: {
          // ID of the case.
          [indexOfCase]: {
            isPending: {
              $set: false,
            },
            data: {
              invoiceId: {
                $set: null,
              },
            },
          },
        },
      });
    },
    clearCases: () => {
      return casesState;
    },
  },
});

export const {
  newCasePending,
  addConditions,
  removeConditions,
  setConditions,
  setConditionUnits,
  loadCase,
  newCaseSuccess,
  updateShippingMethod,
  updateCaseQuestions,
  setUser,
  newCaseFailure,
  newCaseInvoiceCreated,
  setUpsellState,
  removeUpsellConditions,
  clearNewCase,
} = newCaseSlice.actions;

export const {
  getCasesPending,
  getCasesSuccess,
  getCasesFailure,
  caseMutationPending,
  getInvoiceSuccess,
  updateOrderSuccess,
  updateCaseSuccess,
  addCaseToFeed,
  caseMutationFailure,
  setCasePendingPayment,
  clearCases,
} = casesSlice.actions;

export default combineReducers({
  newCase: newCaseSlice.reducer,
  cases: casesSlice.reducer,
});
