import "./TravellingLong.scss";

import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import DatePicker from "@components/elements/DatePicker";
import { PageConsultation } from "@components/layouts/PageConsultation";
import { useLoadOnTop } from "@hooks/useLoadOnTop";
import { useTitle } from "@hooks/useTitle";
import { AlertBanner, Button, Label, Text, Title } from "@runwayhealth/runway-components-react";
import { Question } from "@store/../@types/case";
import { ConditionTypes } from "@store/../@types/condition";
import { RootState } from "@store/../@types/state";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import Header from "../../elements/Header";
import RadioButton from "../../elements/RadioButton";

export const travelLengths = [
  "1-7 nights",
  "8-14 nights",
  "15-21 nights",
  "22-28 nights",
  "28 nights+",
];

export const travelLengthQuestion = "How long will you be traveling?";

const departureQuestion = "When do you depart?";

const PriceIncreaseNotice = ({ returnDate }: { returnDate: boolean }) => (
  <AlertBanner isRounded className="depart-banner">
    <AlertBanner.Message>
      {returnDate && "Please provide your return date for physician review. "}
      After the first two weeks, each additional week of medication coverage for malaria prophylaxis
      is $25
    </AlertBanner.Message>
  </AlertBanner>
);

const TravellingLong = () => {
  const dispatch = useDispatch();
  const questions = useSelector((state: RootState) => state.questions);
  const conditions = useSelector((state: RootState) => state.case.newCase.data.conditions);
  const [state, setState] = useState([false, false, false, false, false]);
  const [malariaRequested, setMalariaRequested] = useState<boolean>(false);
  const departureQuestionObject: Question | undefined = questions.general.find(
    (q) => q.question === departureQuestion
  );
  const [departureDate, setDepartureDate] = useState<Date>(
    new Date(departureQuestionObject?.answer || "")
  );
  const [returnDate, setReturnDate] = useState<Date>(new Date());
  const [error, setError] = useState<string>("");
  const history = useHistory();
  useTitle("How long will you be travelling for?");
  useLoadOnTop();

  useEffect(() => {
    conditions.forEach((condition) => {
      if (condition.product.name === ConditionTypes.MALARIA_CONSULTATION) {
        setMalariaRequested(true);
      }
    });
  }, []);

  const capturedVariant = state
    .map((item, i) => (item ? travelLengths[i] : false))
    .filter((variant) => variant)
    .join("");

  const setAnswer = () => {
    if (capturedVariant) {
      setError("");
      if (state[state.length - 1] && malariaRequested) {
        // Validate dates before sending them.
        if (returnDate.getTime() < departureDate.getTime()) {
          setError(
            `Return date can't be before departure date. Departure date: ${departureDate.toDateString()}`
          );
          return;
        }

        // Set return date
        dispatch(
          questionsUpdate({
            question: "When do you return?",
            value: returnDate.toISOString(),
            important: true,
            questionType: "general",
          })
        );

        // Set travelling days
        const daysTravelling = dayjs(returnDate).diff(departureDate, "days") + 1;
        dispatch(
          questionsUpdate({
            question: travelLengthQuestion,
            value: daysTravelling.toString(),
            questionType: "general",
            important: true,
          })
        );
      } else {
        dispatch(
          questionsUpdate({
            question: travelLengthQuestion,
            value: capturedVariant,
            questionType: "general",
            important: travelLengths.indexOf(capturedVariant) >= 2 ? true : false,
          })
        );
      }

      history.push("/insurance");
    }
  };

  const onKeyUpHandler = (e: React.KeyboardEvent) => {
    e.key == "Enter" && setAnswer();
  };

  return (
    <>
      <Header progress={10} />
      <PageConsultation tabIndex={1} onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">{travelLengthQuestion}</Title>
          <Text size="md">Choose one option</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <div className="rw-page-consultation-list-checkboxes">
            {travelLengths.map((variant, index) => (
              <RadioButton
                key={`travel-length-${index}`}
                content={variant}
                checked={state[index]}
                setChecked={setState}
                num={index}
              />
            ))}
          </div>
          {/* This business logic below given by this conditional, only
          applies to the Malaria travel ailment. */}
          {state[state.length - 1] && malariaRequested && (
            <div className="rw-page-consultation-feedback-message">
              <PriceIncreaseNotice returnDate={true} />
              <div className="datepicker-wrapper">
                <Label>Return date</Label>
                <DatePicker id="return" date={returnDate} setDate={setReturnDate} />
                <p style={{ color: "red" }}>{error}</p>
              </div>
            </div>
          )}
        </PageConsultation.Body>
        <Button
          size="lg"
          iconName="ArrowRight"
          customWidth={440}
          onClick={setAnswer}
          disabled={!capturedVariant}
        >
          Next
        </Button>
        {/* This semantic only applies to Malaria travel ailment */}
        {(state[2] || state[3]) && malariaRequested && <PriceIncreaseNotice returnDate={false} />}
      </PageConsultation>
    </>
  );
};

export default TravellingLong;
