import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { Text, Title } from "@runwayhealth/runway-components-react";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import Header from "../../elements/Header";
import YesNoQuestion from "../../templates/YesNoQuestion";

const Alone = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useTitle("Traveling Alone?");
  const setAnswer = (value: string) => {
    dispatch(
      questionsUpdate({ question: "Will you be traveling alone?", value, questionType: "general" })
    );
    history.push("/destination_type");
  };

  return (
    <div>
      <Header progress={55} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">Will you be traveling alone?</Title>
          <Text size="md">Choose one option</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <YesNoQuestion option="" setAnswer={setAnswer} />
        </PageConsultation.Body>
      </PageConsultation>
    </div>
  );
};

export default Alone;
