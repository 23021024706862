import "../../../../assets/scss/cart.scss";

import { XCircle } from "phosphor-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { AlertBanner, AlertBannerMessage, Text } from "@runwayhealth/runway-components-react";
import { CaseStatuses } from "@store/../@types/case";
import { Condition as ConditionType } from "@store/../@types/condition";

import Condition from "../../../../../pages/Dashboard/Contents/Cases/Conditions/component/Condition";

interface ConditionsSummaryProps {
  conditions: ConditionType[];
  removeCondition: (condition: string) => void;
  includesTyphoid: boolean;
}

const DashboardConditionsSummary = ({
  conditions,
  removeCondition,
  includesTyphoid,
}: ConditionsSummaryProps) => {
  return (
    <>
      <Container>
        <Row>
          {conditions.map((condition) => {
            return (
              <div
                className="dashboard-condition-wrapper"
                onClick={() => {
                  removeCondition(condition.product.name);
                }}
              >
                <XCircle style={{ cursor: "pointer" }} size={25} color="#95b6fb" />
                <Condition
                  key={condition.suggestedMedication?.id}
                  condition={condition}
                  caseStatus={CaseStatuses.CREATED}
                />
              </div>
            );
          })}
        </Row>
      </Container>
      {includesTyphoid && (
        <AlertBanner variant="warning">
          <AlertBannerMessage>
            The Typhoid Vaccine (Oral) requires Express Shipping. Your shiping will automatically be
            set to accommodate this requirement.
          </AlertBannerMessage>
        </AlertBanner>
      )}
      <Text>
        Choose the conditions to treat during your travel by clicking on any of options below.
      </Text>
    </>
  );
};

export default DashboardConditionsSummary;
