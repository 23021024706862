import axios from "axios";
import {
  COUPONS_URL,
  ORDER_URL,
  CASE_URL,
  PARTNER_URL,
  BASE_URL,
  AUTH_URL,
  MD_URL,
  USER_URL,
  STRIPE_URL,
  ADMIN_URL,
} from "../utils/constantsEnv";

const runwayApi = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
});

//auth
//POST   login, register, restorePassword, updatePassword
//GET changePassword
const authPost = async (path, data) => {
  return await runwayApi.post(`${AUTH_URL}${path}`, data, {});
};
const authPut = async (path, data) => {
  return await runwayApi.put(`${AUTH_URL}${path}`, data, {});
};
const authGet = async (path) => {
  return await runwayApi.get(`${AUTH_URL}${path}`, {
    params: { token: localStorage.getItem("token") },
  });
};
const authDelete = async (path) => {
  return await runwayApi.delete(`${AUTH_URL}${path}`);
};
const authPublicGet = async (path) => {
  return await runwayApi.get(`${AUTH_URL}${path}`);
};
export const auth = {
  authPost,
  authPut,
  authGet,
  authDelete,
  authPublicGet,
};

//MD POST
//upload_file, create_case, get_patient_cases, cancel_case, update_case_prescriptions, get_case_prescriptions,
//get_messages, create_message, update_messages, set_message_as_read

const mdPost = async (path, data) => {
  let payload = data;
  if (path === "upload_file") {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("file", data.file);
    formData.append("path", data.filePath);
    payload = formData;
  }
  return await runwayApi.post(`${MD_URL}${path}`, payload, {});
};

export const md = {
  mdPost,
};

// STRIPE
//GET  get_plans, get_subscriptions
//POST  pay, buy_plan, cancel_subscriptions
const stripePost = async (path, data) => {
  return await runwayApi.post(`${process.env.REACT_APP_STRIPE_URL}${path}`, data, {});
};
const stripeGet = async (path) => {
  return await runwayApi.get(`${STRIPE_URL}${path}`, {});
};
export const stripe = {
  stripePost,
  stripeGet,
};

//USER
// POST treatment, intakeform
// GET  treatment
// PATCH  updateInfo
const userGet = async (path) => {
  return await runwayApi.get(`${USER_URL}${path}`, {});
};
const userPatch = async (path, data) => {
  return await runwayApi.patch(`${USER_URL}${path}`, data, {});
};
const userPost = async (path, data) => {
  return await runwayApi.post(`${USER_URL}${path}`, data, {});
};
const userPut = async (path, data) => {
  return await runwayApi.put(`${USER_URL}${path}`, data, {});
};
const userDelete = async (path) => {
  return await runwayApi.delete(`${USER_URL}${path}`);
};
// ADMIN
// GET getUser partnerPayments
const adminGet = async (path, data) => {
  return await runwayApi.get(`${ADMIN_URL}${path}`, data, {});
};

// GET partner
const partnerGet = async (path) => {
  return await runwayApi.get(`${PARTNER_URL}${path}`);
};

// POST partner
const partnerPost = async (path, data) => {
  return await runwayApi.post(`${PARTNER_URL}${path}`, data);
};

// DELETE partner
const partnerDelete = async (path) => {
  return await runwayApi.delete(`${PARTNER_URL}${path}`);
};

// COUPONS
// GET promo_code
const couponGet = async (path) => {
  return await runwayApi.get(`${COUPONS_URL}${path}`, {});
};

export const coupons = {
  couponGet,
};

// CASES
const casePost = async (path, data) => {
  return await runwayApi.post(`${CASE_URL}${path}`, data, {});
};

const casePut = async (path, data) => {
  return await runwayApi.put(`${CASE_URL}${path}`, data, {});
};

const caseGet = async (path) => {
  return await runwayApi.get(`${CASE_URL}${path}`, {});
};

export const cases = {
  casePost,
  casePut,
  caseGet,
};

// SEARCH
export const search = async (path, data) => {
  return await runwayApi.get(`/v1/search/${path}`, data);
};

const orderGet = async (path) => {
  return await runwayApi.get(`${ORDER_URL}${path}`, {});
};

const orderPut = async (path, data) => {
  return await runwayApi.put(`${ORDER_URL}${path}`, data, {});
};

export const order = {
  orderGet,
  orderPut,
};

runwayApi.interceptors.request.use(
  (config) => {
    config.headers["x-access-token"] = localStorage.getItem("token");
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

runwayApi.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response) {
      if (error.response.status === 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        runwayApi
          .post(`${AUTH_URL}refresh`)
          .then((response) => {
            localStorage.setItem("token", response.data.token);
            return runwayApi.request(originalRequest);
          })
          .catch((err) => {
            console.log(err);
            console.log("not authorized");
          });
      }
    }
    throw error;
  }
);

export const user = {
  userGet,
  userPatch,
  userPost,
  userPut,
  userDelete,
};

export const admin = {
  adminGet,
  partnerGet,
  partnerPost,
  partnerDelete,
};
