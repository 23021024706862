import "./AutocompleteInput.scss";

import { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";

import { StateAvailability } from "../../pages/WhatState/stateAvailability";

type AutoCompleteProps = {
  key?: string;
  width: string;
  value?: string;
  capturedVariant: React.Dispatch<React.SetStateAction<string>>;
  options: any[];
  onFocus?: () => void;
  onChange?: (value: string) => void;
  onKeyUp?: (value: React.SyntheticEvent) => void;
  defaultValue?: string;
  disabled?: boolean;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  fontSize?: string;
  height?: string;
  placeholder?: string;
  stateAvailability?: StateAvailability[];
  setSyncRequired?: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
};

const AutocompleteInput = ({
  width,
  onFocus,
  onKeyUp,
  handleBlur,
  value,
  capturedVariant,
  options,
  defaultValue,
  disabled = false,
  fontSize,
  height,
  placeholder,
  stateAvailability = [],
  setSyncRequired,
  open,
}: AutoCompleteProps) => {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const useStyles = makeStyles({
    root: {
      width: `${width}`,
      fontFamily: "Gustavo",
    },
    inputRoot: {
      fontFamily: "Gustavo",
      paddingBottom: "36px",
      fontSize: screenWidth < 760 ? "20px" : "32px",
      backgroundColor: "red",
    },
  });

  const classes = useStyles();

  let inputStyles = {
    width: `${width}`,
    marginRight: "0.5rem",
    height: height || "5rem",
    border: "none",
    borderBottom: disabled ? "none" : "1px solid #393939",
    outline: "none",
    fontSize: fontSize || screenWidth < 760 ? "20px" : "32px",
    fontFamily: "Gustavo",
  };

  const captureState = (value: string) => {
    if (setSyncRequired) {
      setSyncRequired(
        stateAvailability
          .filter((state) => !state.isAsync)
          .some((state: { name: string }) => state.name === value)
      );
    }

    capturedVariant(value);
  };

  return (
    <Autocomplete
      clearOnBlur={false}
      freeSolo={true}
      open={open}
      value={value}
      onKeyUp={onKeyUp}
      onBlur={(event: React.FocusEvent<HTMLDivElement>) => {
        // Cast the event target to HTMLInputElement
        const inputElement = event.target as HTMLInputElement;
        captureState(inputElement.value);
      }}
      options={options}
      id={classes.inputRoot}
      onChange={(e, value) => {
        captureState(value || "");
      }}
      defaultValue={defaultValue}
      renderInput={(params) => (
        <>
          <div ref={params.InputProps.ref} className="autocomplete-input">
            <input
              autoComplete={"disabled"}
              style={inputStyles}
              {...params.inputProps}
              name="state"
              onFocus={onFocus}
              onBlur={(e) => (handleBlur ? handleBlur(e) : null)}
              placeholder={placeholder || "Select State"}
              disabled={disabled}
            />
          </div>
        </>
      )}
    />
  );
};

export default AutocompleteInput;
