import Cookies from "js-cookie";
import { eventChannel, EventChannel } from "redux-saga";
import { io, ManagerOptions, Socket, SocketOptions } from "socket.io-client";

import { BASE_URL } from "../../utils/constantsEnv";

export interface SocketEvent<T> {
  eventName: SocketEvents;
  payload: T;
}

interface WebSocketConfig {
  url: string;
  options: Partial<ManagerOptions & SocketOptions>;
}

export enum SocketEvents {
  MESSAGE_CREATED = "message_created",
  DISCONNECT = "disconnect",
  SOCKET_WITHDRAWAL = "socket_withdrawal",
}

const SOCKET_ID = "sid";

const createWebSocketConnection = (authToken: string): Socket => {
  const socketId = Cookies.get(SOCKET_ID);
  const config: WebSocketConfig = {
    url: BASE_URL || "",
    options: {
      secure: true,
      auth: { token: authToken },
      withCredentials: true,
    },
  };
  if (socketId) {
    config.options.query = { socketId };
  }

  return io(config.url, config.options);
};

const createSocketChannel = (socket: Socket): EventChannel<Socket | Object | Error> => {
  return eventChannel((emit) => {
    // A socket event will always receive an object wrapped within a payload.
    // The object is generic.
    const eventHandler = (eventName: string, event: SocketEvent<Object>) => {
      emit({
        eventName,
        payload: event.payload,
      });
    };

    const errorHandler = (errorEvent: any) => {
      emit(new Error(errorEvent));
    };

    // It catches all the different events that
    // may be receiving the client.
    // So, we would register socket events here.
    socket.on("connect", () => {
      // Store session as cookie.
      Cookies.set(SOCKET_ID, socket.id);
    });
    socket.on("disconnect", () => {
      // Remove session from cookies.
      Cookies.remove(SOCKET_ID);
    });
    socket.on(SocketEvents.MESSAGE_CREATED, eventHandler);
    socket.on("error", errorHandler);

    const unsubscribe = () => {
      socket.off("message", eventHandler);
    };
    return unsubscribe;
  });
};

export { createWebSocketConnection, createSocketChannel };
