import React from "react";
import Privacy from "../../LegalDocuments/Privacy";
import Terms from "../../LegalDocuments/Terms";
import Consent from "../../LegalDocuments/Consent";
import InformationSharing from "../../LegalDocuments/InformationSharing";

const DocViewer = ({ name }) => {
  return (
    <div>
      {name === "privacy" && <Privacy />}
      {name === "terms" && <Terms />}
      {name === "consent" && <Consent />}
      {name === "information" && <InformationSharing />}
    </div>
  );
};

export default DocViewer;
