import "./referrals.scss";

import ReferralInviteForm, { ViewType } from "@components/ReferralInviteForm";

const Referrals = () => {
  return (
    <div className="referrals">
      <ReferralInviteForm isOpen={true} view={ViewType.REWARD} />
    </div>
  );
};

export default Referrals;
