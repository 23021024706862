import { React, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CompactLogo from "../../../img/CompactLogo.svg";
import "./PharmacyModal.scss";
import CloseButtonColor from "../../../img/closeButtonColor";

const PharmacyModal = ({ open, close }) => {
  const [screenWidth, setScreenWidth] = useState(null);
  const useStyles = makeStyles((theme) => ({
    modal: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#E5E5E5",
      padding: screenWidth < 760 ? "0" : "10px",
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: screenWidth < 760 ? "100%" : "auto",
      height: screenWidth < 760 ? "100vh" : "auto",
      backgroundColor: theme.palette.background.paper,
      border: screenWidth < 760 ? "none" : "2px solid rgba(#ffffff, 0.3)",
      borderRadius: screenWidth < 760 ? "0" : "90px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(5, 4, 3),
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    setScreenWidth(window.screen.width);
  }, [window.screen.width]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {/*<div className="button-containter">*/}
            <div className="close-button shipping-modal-close-button" onClick={() => close(false)}>
              <CloseButtonColor color={screenWidth < 760 ? "grey" : "#FFFFFF"} />
            </div>
            {/*</div>*/}
            <div className={"compact-logo"}>
              <img src={CompactLogo} alt="Logotype" />
            </div>
            <div className="modal-notes">
              <span className="modal-warning">Would you like to use a different pharmacy?</span>
              <span className="warning-description">
                If you choose to use a pharmacy outside of the Runway network, we will not be able
                to guarantee the price of the medication and provide free delivery.
              </span>
            </div>
            <div className="modal-buttons">
              <button type="button" className="free-shipping" onClick={() => close(false)}>
                Nevermind, I want free shipping
              </button>
              <button
                type="button"
                className="different-pharmacy"
                onClick={() => history.push("/pharmacy-find")}
              >
                Yes, I want to use a different pharmacy
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default PharmacyModal;
