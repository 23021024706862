import { Link } from "react-router-dom";
import { useEffect } from "react";
import { BASE_URL, LANDING_PAGE } from "./utils/constantsEnv";
import "./Main.scss";

const notProd = ["staging", "dev", "localhost"];
const hideStagingIndex = !notProd.some((host) => BASE_URL.includes(host));

const Main = () => {
  useEffect(() => {
    if (hideStagingIndex) {
      window.location.replace(`${LANDING_PAGE}`);
    }
  });
  return (
    <div className="main-div">
      {hideStagingIndex ? (
        <div className="main-div-inner">Redirecting . . .</div>
      ) : (
        <div>
          <div className="first-things">
            <p className="welcome-title">This is a staging site runwayhealth.com</p>
            <p className="welcome-choose">
              Please choose any of the following URLs to start using app
            </p>
          </div>
          <div className="first-links">
            <Link to="/dia">Start with a Traveler's Diarrhea consultation'</Link>
            <Link to="/insomnia">Start with a Sleeplessness consultation</Link>
            <Link to="/altitude">Start with a Altitude Sickness consultation</Link>
            <Link to="/motion">Start with a Motion Sickness consultation</Link>
            <Link to="/malaria">Start with a Malaria prophylaxis consultation</Link>
            <Link to="/nausea">Start with a Nausea Prevention consultation</Link>
            <Link to="/plus">Start with a Runway+ consultation</Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Main;
