import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  error: "",
  home_address_status: false,
  status: "",
  loading: false,
  notification: {
    caseId: "",
  },
};

export const uiSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    errorHandler: (state, action) => {
      state.error = action.payload;
    },
    changeHomeStatusAddress: (state) => {
      state.home_address_status = !state.home_address_status;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setStatus: (_, { payload }) => {
      toast(`${payload.message}`, {
        position: "top-center",
        autoClose: 2000,
        closeButton: true,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    },
    setNotification: (state, action) => {
      return (state = { ...state, notification: action.payload });
    },
  },
});

export const { errorHandler, changeHomeStatusAddress, setStatus, setLoading, setNotification } =
  uiSlice.actions;

export default uiSlice.reducer;
