import styled from "styled-components";

import { border, from, spacing } from "@runwayhealth/runway-components-react";

export const OrderItems = styled.div`
  margin-top: ${spacing.xxs};
  margin-bottom: 6px;

  border-top: ${border.width.hairline} solid #e0e0e0;
  .order-items-title {
    margin: -8px auto 0 auto;
    background: #f7f8f9;
    width: fit-content;
    padding: 0 8px;
  }

  .order-items-container {
    padding: 8px 0 2px 0;

    @media ${from.mobile} {
      padding: 22px 0 16px 0;
    }

    &.single-order {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
`;
