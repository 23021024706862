import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { Text, Title } from "@runwayhealth/runway-components-react";
import { ConditionTypes } from "@store/../@types/condition";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import ChoiceButtons from "../../elements/ChoiceButtons";
import Header from "../../elements/Header";

const FluconazolePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    sessionStorage.setItem("current_consultation", ConditionTypes.VAGINAL_YEAST_INFECTION);
  }, []);
  useTitle(ConditionTypes.VAGINAL_YEAST_INFECTION);

  const history = useHistory();

  const question = "Have you successfully used fluconazole (Diflucan) to treat a yeast infection?";

  const setAnswer = (value: string) => {
    dispatch(
      questionsUpdate({
        question,
        value,
        questionType: "vaginalYeastInfection",
      })
    );

    history.push("/conditions");
  };

  return (
    <>
      <Header progress={2.5} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">{question}</Title>
          <Text size="md">Choose one option</Text>
        </PageConsultation.Header>
        <PageConsultation.Body>
          <ChoiceButtons
            first="Yes"
            second="No"
            setAnswer={setAnswer}
            setNo={() => {}}
            problems={false}
            isActive={false}
            isActiveSecond={false}
          />
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default FluconazolePage;
