import "./dashboard.scss";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";

import { useTitle } from "@hooks/useTitle";
import { Case as ICase } from "@store/../@types/case";
import { ErrorEvents, RootEntity, RootState } from "@store/../@types/state";
import { User } from "@store/../@types/user";
import * as casesActions from "@store/cases/casesActions";
import { RESTORE_CONNECTION } from "@store/socket/socketActions";
import { GET_AFFILIATE_PROFILE, LOGOUT } from "@store/user/userActions";
import { toastInfo } from "@utils/utils";

import MenuSvg from "../../../img/menu.svg";
import LogoSvg from "../../../img/RunwayLogo.svg";
import { LANDING_PAGE } from "../../../utils/constantsEnv";
import HelpModal from "../../elements/HelpModal";
import Menu from "../../elements/Menu/Menu";
import Account from "./Contents/Account/Account";
import AdminMain from "./Contents/AdminMain";
import Cases from "./Contents/Cases/Cases";
import Messages from "./Contents/Messages/Messages";
import Referrals from "./Contents/Referrals/Referrals";

const Dashboard = () => {
  const [menu, setMenu] = useState(false);
  const [showRewardButton, setShowRewardButton] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const hasNotification = useSelector<RootState, boolean>(
    (state) => state.ui.notification?.message
  );
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);
  // User rewards will be active after 3 weeks = 1.814e+9 ms
  const userRewardsActived = new Date().getTime() - (user.data?.createdAt ?? 0) > 1.814e9;
  const casesState = useSelector<RootState, RootEntity<RootEntity<ICase>[]>>(
    (state) => state.case.cases
  );
  useTitle("Dashboard");

  useEffect(() => {
    sessionStorage.removeItem("answered_questions");
    sessionStorage.removeItem("step");
    sessionStorage.removeItem("upsoldConditions");
    if (user.data.id) {
      dispatch({
        type: casesActions.REQUEST_CASES,
        userId: user.data.id,
      });
    }
  }, []);

  useEffect(() => {
    if (user.data.id) {
      dispatch({
        type: RESTORE_CONNECTION,
        authToken: localStorage.getItem("token"),
      });
    }

    if (!user.data.affiliateProfile) {
      dispatch({
        type: GET_AFFILIATE_PROFILE,
      });
    }
  }, []);

  useEffect(() => {
    if (user.data.affiliateProfile) {
      const validAffiliate = user.data.affiliateProfile.conversions < 5;
      setShowRewardButton(validAffiliate && userRewardsActived);
    }
  }, [user.data.affiliateProfile]);

  useEffect(() => {
    if (!user.data.id) {
      sessionExpired();
      history.push("/login");
    }
  }, [user.data.id]);

  useEffect(() => {
    if (casesState.error.event === ErrorEvents.ACCESS_DENIED) {
      sessionExpired();
    }
  }, [casesState.error]);

  const sessionExpired = () => {
    toastInfo("Session expired", "Please login again to continue...");
    dispatch({
      type: LOGOUT,
      userId: user.data.id,
    });
  };

  return (
    <div className="dashboard">
      <div className="dashboard-menu" style={{ left: menu ? "0" : "-100%" }}>
        <Menu
          setMenu={setMenu}
          userId={user.data.id}
          hasNotification={hasNotification}
          showRewardButton={showRewardButton}
        />
      </div>
      <div className="dashboard-content">
        <header className="dashboard-content-header">
          <img
            className={"mobile-menu"}
            src={MenuSvg}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => setMenu((prev) => !prev)}
          />
          {hasNotification && <div className="notification"></div>}
          <a href={LANDING_PAGE} target="blank">
            <img className={"mobile-logo"} src={LogoSvg} alt="" style={{ height: "38px" }} />
          </a>
          <div className="dashboard-content-header-icons">
            <HelpModal />
          </div>
        </header>
        <Switch>
          <Route exact path="/dashboard/" component={Cases} />
          <Route exact path="/dashboard/cases" component={Cases} />
          <Route exact path="/dashboard/cases?referral=true" component={Cases} />
          <Route exact path="/dashboard/cases?cart=true" component={Cases} />
          <Route exact path="/dashboard/account" component={Account} />
          <Route exact path="/dashboard/messages" component={Messages} />
          <Route exact path="/dashboard/messages/:caseId" component={Messages} />
          <Route exact path="/dashboard/referrals" component={Referrals} />
          <Route exact path="/dashboard/admin" component={AdminMain} />
        </Switch>
      </div>
    </div>
  );
};

export default Dashboard;
