import { PaymentManagerSetup, PaymentMethod } from "@store/../@types/billing";
import { Address, User } from "@store/../@types/user";

export const LOGIN = "LOGIN";
export interface Login {
  type: typeof LOGIN;
  email: string;
  password: string;
}

export const LOGOUT = "LOGOUT";
export interface Logout {
  type: typeof LOGOUT;
  userId: string;
}

export const SIGNUP = "SIGNUP";
export interface Signup {
  type: typeof SIGNUP;
  user: User;
}

export const PASSWORD_UPDATE = "PASSWORD_UPDATE";
export interface PasswordUpdate {
  type: typeof PASSWORD_UPDATE;
  oldPassword: string;
  newPassword: string;
  resetToken?: string;
}

export const USER_UPDATE = "USER_UPDATE";
export interface UserUpdate {
  type: typeof USER_UPDATE;
  userUpdate: UserUpdate;
}

export const UPDATE_SHIPPING_ADDRESS = "UPDATE_SHIPPING_ADDRESS";
export interface UpdateShippingAddress {
  type: typeof UPDATE_SHIPPING_ADDRESS;
  address: Address;
}

export const RESTORE_PASSWORD = "RESTORE_PASSWORD";
export interface RestorePassword {
  type: typeof RESTORE_PASSWORD;
  email: string;
}

export const VERIFY_SECURITY_CODE = "VERIFY_SECURITY_CODE";
export interface VerifySecurityCode {
  type: typeof VERIFY_SECURITY_CODE;
  token: string;
}

export const COLLECT_MARKETING_EMAIL = "COLLECT_MARKETING_EMAIL";
export interface CollectMarketingEmail {
  type: typeof COLLECT_MARKETING_EMAIL;
  email: string;
}

export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export interface GetPaymentMethods {
  type: typeof GET_PAYMENT_METHODS;
}

export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export interface AddPaymentMethod {
  type: typeof ADD_PAYMENT_METHOD;
  paymentMethodId: string;
  pendingCase: boolean;
}

export const SET_DEFAULT_PAYMENT_METHOD = "SET_DEFAULT_PAYMENT_METHOD";
export interface SetDefaultPaymentMethod {
  type: typeof SET_DEFAULT_PAYMENT_METHOD;
  methodId: string;
  managerSetup: PaymentManagerSetup;
  pendingCase: boolean;
}

export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD";
export interface DeletePaymentMethod {
  type: typeof DELETE_PAYMENT_METHOD;
  methodId: string;
}

export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export interface UpdatePaymentMethod {
  type: typeof UPDATE_PAYMENT_METHOD;
  paymentMethodUpdate: PaymentMethod;
}

export const SEND_TRAVEL_GROUP_EMAIL = "SEND_TRAVEL_GROUP_EMAIL";
export interface SendTravelGroupEmail {
  type: typeof SEND_TRAVEL_GROUP_EMAIL;
  emails: string[];
}

export const GET_AFFILIATE_PROFILE = "GET_AFFILIATE_PROFILE";
export interface GetAffiliateLink {
  type: typeof GET_AFFILIATE_PROFILE;
}

export const GET_PROMO_CODES = "GET_PROMO_CODES";
export interface GetPromoCodes {
  type: typeof GET_PROMO_CODES;
}

export const CREATE_PATIENT_PROFILE = "CREATE_PATIENT_PROFILE";
export interface CreatePatientProfile {
  type: typeof CREATE_PATIENT_PROFILE;
  userId: string;
}
