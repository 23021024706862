import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { persistReducer, persistStore } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import {
  createStateSyncMiddleware,
  initMessageListener,
  withReduxStateSync,
} from "redux-state-sync";

import { combineReducers, configureStore } from "@reduxjs/toolkit";

import adminSlice from "./admin/adminSlice";
import caseReducer from "./cases/casesSlice";
import messagesReducer from "./messages/messagesSlice";
import priceReducer from "./prices/pricesSlice";
import questionsReducer from "./questions/questionsSlice";
import saga from "./saga";
import uSlice from "./ui/uiSlice";
import userReducer from "./user/userSlice";

export const history = createBrowserHistory();

const persistConfig = {
  key: "root",
  storage: sessionStorage,
  blacklist: [
    "router",
    "persist/PERSIST",
    "persist/REHYDRATE",
    /**
     * The idea of this black list is to prevent writing operations
     * to be triggered twice if state is synced through multiple tabs.
     */
    // Meessage actions
    "CREATE_MESSAGE",
    // Case actions
    "CREATE_CASE",
    "PAY_ORDER",
    "SEND_ORDER",
    "UPDATE_CASE",
    "FINALIZE_CASE",
    "ADD_CONDITIONS",
    "SEND_CASE",
    "PAY_CASE",
    // User actions
    "SIGNUP",
    "PASSWORD_UPDATE",
    "UPDATE_SHIPPING_ADDRESS",
    "SET_DEFAULT_PAYMENT_METHOD",
    "DELETE_PAYMENT_METHOD",
    "UPDATE_PAYMENT_METHOD",
    "ADD_PAYMENT_METHOD",
    "USER_UPDATE",
    "LOGOUT",
  ],
};

const reactRouterMiddleware = routerMiddleware(history);

const rootReducer = () =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    questions: questionsReducer,
    case: caseReducer,
    price: priceReducer,
    messages: messagesReducer,
    ui: uSlice,
    admin: adminSlice,
  });

const persistedReducer = persistReducer(persistConfig, withReduxStateSync(rootReducer()));
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [reactRouterMiddleware, sagaMiddleware, createStateSyncMiddleware(persistConfig)],
  devTools: true,
});
export const persistor = persistStore(store);

sagaMiddleware.run(saga);

initMessageListener(store);
