import update from "immutability-helper";
import Stripe from "stripe";

import { combineReducers, createSlice } from "@reduxjs/toolkit";
import { RootEntity, RunwayPrice, ServicesTypes } from "@store/../@types/state";

const casePriceObject: RunwayPrice = {
  id: "",
  product: {
    id: "",
    name: ServicesTypes.VISIT_FEE,
    metadata: {
      name: "",
      T: "",
    },
    units: 1,
  },
  unit_amount: 0,
};

const casePriceState: RootEntity<RunwayPrice> = {
  isPending: false,
  data: casePriceObject,
  error: {},
};

const casePriceSlice = createSlice({
  name: "casePrice",
  initialState: casePriceState,
  reducers: {
    requestCasePricePending: (state) => {
      return Object.assign({}, state, { isPending: true });
    },
    requestCasePriceSuccess: (state, action) => {
      // We just need the first result of case price.
      return Object.assign({}, state, { data: action.payload[0], isPending: false });
    },
    requestCasePriceFailure: (state, action) => {
      return Object.assign({}, state, { error: action.payload, isPending: false });
    },
    clearCasePrice: (state) => {
      return Object.assign({}, state, casePriceState);
    },
  },
});

const shippingPriceState: RootEntity<Stripe.ShippingRate[]> = {
  isPending: false,
  data: [],
  error: {},
};

const shippingPriceSlice = createSlice({
  name: "shippingPrice",
  initialState: shippingPriceState,
  reducers: {
    requestShippingPricePending: (state) => {
      return update(state, { isPending: { $set: true } });
    },
    requestShippingPriceSuccess: (state, action) => {
      return update(state, {
        isPending: {
          $set: false,
        },
        data: {
          $set: action.payload,
        },
      });
    },
    requestShippingPriceFailure: (state, action) => {
      return update(state, {
        isPending: {
          $set: false,
        },
        error: {
          $set: action.payload,
        },
      });
    },
    clearShippingPrice: (state) => {
      return Object.assign({}, state, casePriceState);
    },
  },
});

const prescriptionsPrices: RunwayPrice[] = [];

const prescriptionsPricesState: RootEntity<RunwayPrice[]> = {
  isPending: false,
  data: prescriptionsPrices,
  error: {},
};

export const prescriptionsPricesSlice = createSlice({
  name: "prescriptionsPrices",
  initialState: prescriptionsPricesState,
  reducers: {
    requestPrescriptionsPricesPending: (state) => {
      return Object.assign({}, state, { isPending: true });
    },
    requestPrescriptionsPricesSuccess: (state, action) => {
      return update(state, {
        isPending: {
          $set: false,
        },
        data: {
          $push: action.payload,
        },
      });
    },
    requestPrescriptionsPricesFailure: (state, action) => {
      return Object.assign({}, state, { isPending: false, error: action.payload });
    },
    clearPrescriptionsPrices: () => {
      return prescriptionsPricesState;
    },
  },
});

export const {
  requestCasePricePending,
  requestCasePriceSuccess,
  requestCasePriceFailure,
  clearCasePrice,
} = casePriceSlice.actions;

export const {
  requestShippingPricePending,
  requestShippingPriceSuccess,
  requestShippingPriceFailure,
} = shippingPriceSlice.actions;

export const {
  requestPrescriptionsPricesPending,
  requestPrescriptionsPricesSuccess,
  requestPrescriptionsPricesFailure,
  clearPrescriptionsPrices,
} = prescriptionsPricesSlice.actions;

export default combineReducers({
  case: casePriceSlice.reducer,
  prescriptions: prescriptionsPricesSlice.reducer,
  shipping: shippingPriceSlice.reducer,
});
