import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Header from "@components/elements/Header";
import { PageConsultation } from "@components/layouts/PageConsultation";
import YesNoQuestion from "@components/templates/YesNoQuestion";
import { useTitle } from "@hooks/useTitle";
import { Button, Text, Title } from "@runwayhealth/runway-components-react";
import { questionsUpdate } from "@store/questions/questionsSlice";

const TripType = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useTitle("What type of trip are you going for?");

  const setAnswer = (value: string) => {
    dispatch(
      questionsUpdate({
        question: "What is the type of trip?",
        value,
        questionType: "general",
      })
    );
    history.push("/travellinglong");
  };
  return (
    <div>
      <Header progress={12} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">What is the type of trip?</Title>
          <Text size="md">Choose one option</Text>
        </PageConsultation.Header>
        <PageConsultation.Body>
          <YesNoQuestion option="" choiceOne="Business" choiceTwo="Leisure" setAnswer={setAnswer} />
        </PageConsultation.Body>
      </PageConsultation>
    </div>
  );
};

export default TripType;
