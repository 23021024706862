import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { OrderItems } from "@components/layouts/StartConsultationPage";
import { Title } from "@runwayhealth/runway-components-react";
import { ConditionTypes } from "@store/../@types/condition";
import { PricesState, RootState } from "@store/../@types/state";
import { User } from "@store/../@types/user";

import Consultation from "../Consultation";

interface ConditionsSummaryProps {
  consultations: string[];
  isRunwayPlus: boolean;
  removeCondition: (condition: string) => void;
}

const StartConsultationConditionsSummary = ({
  consultations,
  isRunwayPlus,
  removeCondition,
}: ConditionsSummaryProps) => {
  const history = useHistory();
  const user = useSelector<RootState, User>((state) => state.user.data);
  const prices = useSelector<RootState, PricesState>((state) => state.price);

  useEffect(() => {
    if (user.id) {
      history.push("/dashboard/cases?cart=true");
    }
  }, [prices]);

  return (
    <>
      <OrderItems>
        <Title size="xs" as="h3" className="order-items-title">
          Your Order
        </Title>
        <div className="order-items-container container-fluid g-0">
          <div className="row g-0">
            {consultations.map((ct, index) => (
              <div
                className="col-md-6"
                key={`consultation-${index}`}
                onClick={() => {
                  removeCondition(ct);
                }}
              >
                <Consultation text={ct} />
              </div>
            ))}
            {isRunwayPlus && <Consultation text={ConditionTypes.RUNWAY_PLUS} />}
          </div>
        </div>
      </OrderItems>
    </>
  );
};

export default StartConsultationConditionsSummary;
