import styled from "styled-components";

import { border, from, spacing } from "@runwayhealth/runway-components-react";

export const PickerItems = styled.div`
  margin-top: ${spacing.xxs};
  margin-bottom: 6px;

  border-top: ${border.width.hairline} solid #e0e0e0;

  .picker-items-title {
    margin: -7px auto 0 auto;
    border: 1px solid #e0e0e0;
    border-radius: 13px;
    border-width: 1px;
    box-shadow: 1px 2px #f3f3f3;
  }

  .picker-items-container {
    padding: 8px 0 2px 0;

    @media ${from.mobile} {
      padding: 22px 0 16px 0;
    }
  }
`;
