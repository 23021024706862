import { put, takeEvery, delay } from "redux-saga/effects";
import { ERROR_CHANGE, LOADING_CHANGE } from "./uiAction";

import { errorHandler, setLoading } from "./uiSlice";

function* errors(action) {
  try {
    yield put(errorHandler(action.payload));
    yield delay(5000);
    yield put(errorHandler(null));
  } catch (e) {
    console.log(e);
  }
}

function* loading(action) {
  yield put(setLoading(action.payload));
}

export default function* uiSaga() {
  yield takeEvery(ERROR_CHANGE, errors);
  yield takeEvery(LOADING_CHANGE, loading);
}
