import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Header from "../../elements/Header";
import Checkbox from "../../elements/Checkbox";
import CheckboxInput from "../../elements/CheckboxInput";

import { userUpdate } from "../../../store/user/userSlice";
import { questionsUpdate } from "../../../store/questions/questionsSlice";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { Text, Title, Button } from "@runwayhealth/runway-components-react";
import { ConditionTypes } from "@store/../@types/condition";

const Activities = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const variants = [
    "Exposure to rivers, lakes, or streams",
    "Close contact with animals",
    "Adventure racing",
    "Camping",
    "Exposure to ticks",
    "Sun exposure",
  ];

  const [isNone, setNone] = useState(false);
  const [ownAnswer, setOwnAnswer] = useState("");
  const [formState, setFormState] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [captureVariants, setCaptureVariants] = useState([]);
  const captureFromInput = (value) => {
    setCaptureVariants((prev) => [...prev, value]);
  };

  useEffect(() => {
    sessionStorage.setItem("current_consultation", ConditionTypes.MALARIA_CONSULTATION);
  }, []);

  useEffect(() => {
    formState?.forEach((item, index) => {
      return item === true && captureVariants.indexOf(variants[index]) === -1
        ? setCaptureVariants((prev) => [...prev, variants[index]])
        : null;
    });
  }, [formState]);

  useEffect(() => {
    if (formState[7] === true) {
      setNone(true);
    } else {
      setNone(false);
    }
  }, [formState]);
  useTitle("Activities");

  let capturedVariant = formState
    ?.map((item, i) => (item ? variants[i] : false))
    .filter((variant) => variant)
    .join(", ");

  const setAnswer = () => {
    if ((capturedVariant || ownAnswer) && !isNone) {
      dispatch(
        questionsUpdate({
          question: "Will your trip include any of the following?",
          value: ownAnswer ? `${ownAnswer}, ${capturedVariant}` : capturedVariant,
          important: true,
          questionType: "malaria",
        })
      );
      dispatch(userUpdate({ name: "activities", value: capturedVariant }));
      history.push("/abx_side_effects");
    } else if (isNone) {
      dispatch(
        questionsUpdate({
          question: "Will your trip include any of the following?",
          value: "None of the above",
          important: false,
          questionType: "malaria",
        })
      );
      dispatch(userUpdate({ name: "activities", value: "None of the above" }));
      history.push("/abx_side_effects");
    }
  };

  const onClick = () => {
    setFormState(!formState[7]);
  };

  const onKeyUpHandler = (e) => {
    e.key == "Enter" && setAnswer();
  };

  return (
    <>
      <Header progress={5} />
      <PageConsultation tabIndex={1} onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">Will your trip include any of the following ?</Title>
          <Text size="md">Choose one or more options</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <div className="rw-page-consultation-list-checkboxes">
            {variants.map((variant, index) => (
              <>
                <Checkbox
                  content={variant}
                  checked={formState[index]}
                  setChecked={() => {
                    const newState = formState.map((state, i) => {
                      if (i === index) {
                        return !state;
                      } else if (i === formState.length - 1) {
                        return false;
                      }
                      return state;
                    });
                    setFormState(newState);
                  }}
                  num={index}
                  key={`checkbox-${index}`}
                />
              </>
            ))}
            <CheckboxInput
              isNone={isNone}
              type={"number"}
              checked={ownAnswer.length !== 0 ? formState[6] : false}
              setChecked={() => {
                const newState = formState.map((state, i) => {
                  if (i === 6) {
                    return !state;
                  } else if (i === formState.length - 1) {
                    return false;
                  }
                  return state;
                });
                setFormState(newState);
              }}
              num={variants.length}
              ownAnswer={ownAnswer}
              setOwnAnswer={setOwnAnswer}
              capture={captureFromInput}
              key={"own-answer"}
              disabled={ownAnswer.length === 0}
            />
            <Checkbox
              content={"None of the above"}
              checked={formState[7]}
              setChecked={() => {
                const newState = formState.map((state, i) => {
                  if (i === 7) {
                    return !state;
                  } else {
                    return false;
                  }
                });
                setFormState(newState);
              }}
              num={7}
              key={"checkbox-none"}
            />
          </div>
        </PageConsultation.Body>

        <Button
          size="lg"
          iconName="ArrowRight"
          customWidth={440}
          onClick={setAnswer}
          disabled={!capturedVariant && !ownAnswer && !isNone}
        >
          Next
        </Button>
      </PageConsultation>
    </>
  );
};

export default Activities;
