import "../../account.scss";

import { useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";

import { USER_UPDATE } from "@store/user/userActions";
import { validateEmail, validateFullname, validatePhone } from "@utils/utilsFormvalidation";

import { User, UserUpdate } from "../../../../../../../@types/user";
import ArrowRightSvg from "../../../../../../../img/ArrowRightBlack.svg";
import TextFieldDisable from "../../../../../../elements/TextFieldDisable/TextFieldDisable";

interface ProfileProps {
  disableProfileForm: boolean;
  user: User;
  error: string;
  setDisableProfileForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileAccount = ({
  disableProfileForm,
  user,
  error,
  setDisableProfileForm,
}: ProfileProps) => {
  const [userUpdate, setUserUpdate] = useState<UserUpdate>();
  const [errorText, setErrorText] = useState<string>("");
  const dispatch = useDispatch();
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      setUserUpdate({
        first_name: user.first_name,
        last_name: user.last_name,
        phone_number: user.phone_number,
        email: user.email,
      });
      return;
    }
    if (error) {
      setErrorText(error);
    }
  }, [userUpdate]);

  const handlerUserUpdate = (
    event: React.FormEvent<HTMLInputElement> | React.SyntheticEvent,
    name: string
  ) => {
    let value: string;
    if (event.currentTarget) {
      value = (event as React.FormEvent<HTMLInputElement>).currentTarget.value;
    } else {
      value = ((event as React.SyntheticEvent).target as HTMLInputElement).value;
    }

    setUserUpdate((prev) => ({ ...prev, [name]: value }));
  };

  const formIsValid = () => {
    if (!validateFullname(userUpdate?.first_name || "", userUpdate?.last_name || "")) {
      setErrorText("Please enter your full name");
      return false;
    }

    if (!validatePhone(userUpdate?.phone_number || "")) {
      setErrorText("Please use a US phone number");
      return false;
    }

    if (!validateEmail(userUpdate?.email || "")) {
      setErrorText("Please enter your email address");
      return false;
    }

    return true;
  };

  const save = () => {
    if (formIsValid()) {
      dispatch({
        type: USER_UPDATE,
        userUpdate,
      });
      setErrorText("");
      setDisableProfileForm(true);
    }
  };

  return (
    <>
      <form className="account-billing-form">
        <div className="account-billing-form-inputs">
          <TextFieldDisable
            type="text"
            disabled={disableProfileForm}
            title="First Name"
            defaultValue={user.first_name}
            onChange={(e: React.FormEvent<HTMLInputElement>) => handlerUserUpdate(e, "first_name")}
          />
          <TextFieldDisable
            type="text"
            disabled={disableProfileForm}
            title="Last Name"
            defaultValue={user.last_name}
            onChange={(e: React.FormEvent<HTMLInputElement>) => handlerUserUpdate(e, "last_name")}
          />
        </div>
        <div className="account-billing-form-inputs">
          <label className="disable-input">
            <span className={"disable-input-title"}>Phone</span>
            <InputMask
              className={
                disableProfileForm ? "disable-input-change" : "disable-input-change custom-input"
              }
              mask="(999) 999-9999"
              maskChar=" "
              size={20}
              disabled={disableProfileForm}
              value={userUpdate?.phone_number}
              onChange={(e: React.SyntheticEvent) => handlerUserUpdate(e, "phone_number")}
            />
          </label>
          <TextFieldDisable
            type="email"
            disabled={disableProfileForm}
            title="Email Address"
            defaultValue={user.email}
            onChange={(e: React.FormEvent<HTMLInputElement>) => handlerUserUpdate(e, "email")}
          />
        </div>
      </form>
      <br />
      {!disableProfileForm && (
        <button className="button-save" onClick={save}>
          Save <img src={ArrowRightSvg} alt="" />
        </button>
      )}
      {errorText && (
        <div className="error-message-forgot">
          <span className="message-content-forgot">{errorText}</span>
        </div>
      )}
    </>
  );
};

export default ProfileAccount;
