import "./Header.scss";

import { CaretLeft } from "phosphor-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";

import BackButton from "../../../img/BackButton.svg";
import RunwayLogo from "../../../img/RunwayLogo.svg";
import { LANDING_PAGE } from "../../../utils/constantsEnv";
import HelpModal from "../HelpModal";

interface HeaderProps {
  progress?: number;
  background?: string;
  hideBackButton?: boolean;
  hasProgressBar?: boolean;
}

const Header = ({
  progress,
  background,
  hideBackButton = false,
  hasProgressBar = true,
}: HeaderProps) => {
  const history = useHistory();

  const classes = makeStyles({
    root: {
      barColorPrimary: {
        backgroundColor: "#AAC4F9",
      },
    },
  })();
  return (
    <header className="rw-header" style={{ backgroundColor: background }}>
      <nav className="navbar">
        {history.location.pathname !== "/checkout" ? (
          <div
            className="back-button"
            onClick={() => history.goBack()}
            style={{ visibility: !hideBackButton ? "visible" : "hidden" }}
          >
            <CaretLeft size={32} weight="bold" color="#5F80C8" />
            <span>Back</span>
          </div>
        ) : (
          <div className="back-button-placeholder"></div>
        )}
        <a className="runway-logo" href={LANDING_PAGE} target="_blank" rel="noopener noreferrer">
          <img src={RunwayLogo} alt="Runway Health" />
        </a>
        <HelpModal />
      </nav>
      {hasProgressBar && (
        <div className={classes.root}>
          <LinearProgress variant="determinate" value={progress} />
        </div>
      )}
    </header>
  );
};

export default Header;
