import amplitude from "amplitude-js";
import { useSelector } from "react-redux";

import { Condition } from "../@types/condition";
import { RootState } from "../@types/state";

const enrichIfAvailable = (eventProperties: any, conditions: Condition[], question: string) => {
  let enrichedEventProperties = eventProperties || {};
  if (conditions.length > 0) {
    enrichedEventProperties.conditions = conditions.map((condition) => condition.product.name);
  }
  if (question && question.length > 0) {
    enrichedEventProperties.question = question;
  }

  return enrichedEventProperties;
};

const useAmplitude = () => {
  const amplitudeInstance = amplitude.getInstance();
  const conditions = useSelector<RootState, Condition[]>(
    (state) => state.case.newCase.data.conditions
  );
  const question = useSelector<RootState, string>((state) => state.router.location.pathname);
  const logEvent = (eventName: string, eventProperties?: any) => {
    const enrichedEventProperties = enrichIfAvailable(eventProperties, conditions, question);
    amplitudeInstance.logEvent(eventName, enrichedEventProperties);
  };

  return { logEvent };
};

export default useAmplitude;
