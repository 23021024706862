import "./HelpModal.scss";

import useAmplitude from "@hooks/useAmplitude";

import EmailMessage from "../../../img/EmailMessage.svg";
import ModalCloseButton from "../../../img/ModalCloseButton.svg";

interface ModalProps {
  setOpenHelpModal: () => void;
}

const Modal = ({ setOpenHelpModal }: ModalProps) => {
  return (
    <div className="wrapper-help-modal">
      <span className="top-bot"></span>
      <span className="top-bot top-bot-border"></span>
      <div className="items-container">
        <div className="first-item">
          <p>Need help?</p>
          <span onClick={setOpenHelpModal} className="modal-close-button">
            <img src={ModalCloseButton} alt="Modal close button" />
          </span>
        </div>
        <div className="second-item">
          <img src={EmailMessage} alt="Email icon" />
          <div className="email-message-text">
            <p>Email us at anytime at</p>
            <a href="mailto:support@runwayhealth.com">support@runwayhealth.com</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
