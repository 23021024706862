import "./summary.scss";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Stripe from "stripe";

import { Card } from "@runwayhealth/runway-components-react";
import { Case } from "@store/../@types/case";
import * as casesActions from "@store/cases/casesActions";

interface SummaryProps {
  case_: Case;
}

const presentationAmount = (amount: number): number => {
  return amount / 100;
};

const OrderSummary = ({ case_ }: SummaryProps) => {
  const dispatch = useDispatch();
  const invoiceItems = (case_.CurexaOrder?.invoice?.lines.data ?? []).map(
    (item: Stripe.InvoiceLineItem) => {
      return {
        ...item,
        amount: presentationAmount(item.amount ?? 0),
      };
    }
  );
  const shippingCost = presentationAmount(
    case_.CurexaOrder.invoice?.shipping_cost?.amount_total ?? 0
  );
  const invoiceTotal = presentationAmount(case_.CurexaOrder.invoice?.total ?? 0);

  useEffect(() => {
    if (!case_.CurexaOrder.invoice) {
      dispatch({
        type: casesActions.REQUEST_ORDER_INVOICE,
        orderId: case_.CurexaOrder.id,
        caseId: case_.id,
      });
    }
  }, [case_.CurexaOrder]);

  return (
    <div className="summary">
      <Card>
        <div className="row">
          <div className="col1 title">Item</div>
          <div className="col2 title">Amount</div>
        </div>
        {invoiceItems.map((item, i) => (
          <div key={i} className="row">
            <div className="col1">{item.description}</div>
            <div className="col2">${item.amount}</div>
          </div>
        ))}
        <div className="row">
          <div className="col1">Shipping</div>
          <div className="col2">${shippingCost}</div>
        </div>
        <div className="row total-amount">
          <div className="col1">
            <b>Order Total</b>
          </div>
          <div className="col2">
            <b>${invoiceTotal}</b>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default OrderSummary;
