import styled from "styled-components";

import { border, color, font, from, spacing } from "@runwayhealth/runway-components-react";

import { PageConsultationBaseProps } from "../PageConsultation.types";

const PageConsultationBase = styled.section.attrs<PageConsultationBaseProps>({
  className: "container rw-page-consultation",
})`
  padding-top: ${spacing.xxs};
  padding-bottom: ${spacing.md};
  text-align: center;

  @media (min-width: 768px) {
    padding-top: ${spacing.md};
  }

  .rw-page-consultation {
    &-list-checkboxes {
      display: flex;
      flex-direction: column;
      align-items: center;

      .checkbox-form {
        margin-bottom: ${spacing.xxxs};

        &:last-child {
          margin-bottom: 0;
        }

        .items {
          .custom-input {
            font-size: ${font.size.sm};
            height: 40px;
          }
        }
      }
    }

    &-feedback-message {
      margin-top: ${spacing.xxxs};
    }

    // Image in /selfie
    &-upload-image {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: ${spacing.tiny};

      @media ${from.mobile} {
        margin-bottom: ${spacing.xs};
      }

      &-container {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 160px;
        height: 160px;
        border-radius: ${border.radius.circular};
        background: ${color.neutral.lightest};
        box-shadow: 2px 4px 16px rgba(9, 47, 70, 0.08);

        @media ${from.mobile} {
          width: 224px;
          height: 224px;
        }
      }

      img {
        width: 78%;
        @media ${from.mobile} {
          width: auto;
        }
      }
    }
  }

  .date-inputs {
    margin-bottom: 16px;

    .depart-banner {
      margin-top: 24px;
      text-align: left;
      align-items: flex-start;
    }
  }
`;

export default PageConsultationBase;
