import React from "react";

const Ok = ({ color = "#393939" }) => {
  return (
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3917 0.12766L4.7593 9.08085L1.60832 5.77872C1.4442 5.64255 1.18162 5.64255 1.05033 5.77872L0.0984683 6.76596C-0.0328228 6.90213 -0.0328228 7.17447 0.0984683 7.34468L4.49672 11.8723C4.66083 12.0426 4.89059 12.0426 5.0547 11.8723L14.9015 1.65957C15.0328 1.5234 15.0328 1.25106 14.9015 1.08085L13.9497 0.12766C13.8184 -0.0425532 13.5558 -0.0425532 13.3917 0.12766Z"
        fill={color}
      />
    </svg>
  );
};

export default Ok;
