import React from "react";

import { Text, Title } from "@runwayhealth/runway-components-react";

export const RunwayRxText = () => {
  return (
    <>
      <Title size="giant">You're almost there!</Title>
      <Text>
        To help your physician finalize your treatment plan, please fill out this short
        questionnaire to tell us about your specific travel plans and health history.
      </Text>
      <Text>Enter your email below to get started!</Text>
    </>
  );
};

export const RunwayPlusText = () => (
  <>
    <Title size="giant">Runway+</Title>
    <Text>
      To initiate your consultation, please fill out this short form to tell your physician about
      your specific travel plans. Your physician will use this information to provide you with
      travel health support before, during and after your trip.
    </Text>
    <Text>Enter your email below to get started!</Text>
  </>
);

export const ConsentText = () => {
  return (
    <Text as="span" size="sm">
      Keep me in the loop with current travel tips and news from Runway Health
    </Text>
  );
};
