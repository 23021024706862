import { useTitle } from "@hooks/useTitle";
import { Link, Text, Title } from "@runwayhealth/runway-components-react";

const Terms = () => {
  useTitle("Terms");
  return (
    <>
      <Title size="giant">Runway’s Terms of Use </Title>

      <Text variant="base">Last revised: June 23, 2022 </Text>

      <Text variant="base">
        These terms and conditions of use (“Terms of Use”) govern your use of our online interfaces
        and digital properties (e.g., websites) owned and controlled by Roam Health, Inc., d/b/a
        Runway. (“Runway”), including the website located at{" "}
        <Link href="http://www.runwayhealth.com/" isBlank>
          www.runwayhealth.com
        </Link>{" "}
        (the Site”), collectively, referred to as the “Digital Services”. Your compliance with these
        Terms of Use is a condition to your use of the Digital Services. If you do not agree to be
        bound by the Terms of Use, promptly exit the Digital Services. Please also consult our
        PRIVACY POLICY for a description of our privacy practices and policies.
      </Text>

      <Text variant="base">These Terms of Use address the following:</Text>

      <ul>
        <li>
          <Text variant="base">
            <strong>Subscriptions</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Billing and Cancellation</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Our Fees</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Account Set Up</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Disclaimer of Medical Services</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Provider Directory</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>No Medical Advice</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Not for Emergencies</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Risks of Telehealth Services</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Prescription Policy</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Binding Arbitration</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Ownership of the Digital Services</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Access to the Digital Services; Security and Restrictions; Passwords</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Accuracy and Integrity of Information</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Typographical Errors and Incorrect Pricing</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Order Acceptance</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Links to Other Sites</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Disclaimer of Warranties</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Not an Insurance Product</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Limitation of Liability Regarding Use of Digital Services</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Indemnification</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Dispute Resolution; Arbitration Agreement.</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Consent to Receive Calls and Text Messages</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>CAN-SPAM Act and Telephone Consumer Protection Act Compliance</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Electronic Communications</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Force Majeure</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>External Services</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>Revisions; General</strong>
          </Text>
        </li>
        <li>
          <Text variant="base">
            <strong>SUBSCRIPTIONS</strong>
          </Text>
        </li>
      </ul>

      <Text variant="base">
        <strong>
          If you sign up for a subscription, your subscription will continue and automatically renew
          until terminated at the price, intervals, and other terms that you agreed to when signing
          up. Please see the Billing and Cancellation section below for more information on payment
          information, cancellation, refunds, and changes to subscription plans.
        </strong>
      </Text>

      <Title size="md" as="h3">
        BILLING AND CANCELLATION
      </Title>

      <Title size="sm" as="h4">
        Payment Information
      </Title>

      <Text variant="base">
        We accept credit and debit cards issued by U.S. banks. If a credit card account is being
        used for a transaction, Runway may obtain preapproval for an amount up to the amount of the
        payment. All charges and fees will be billed to the credit card you designate during the
        setup process. If you want to designate a different credit card or if there is a change in
        your credit card, you must change your information online. This may temporarily delay your
        ability to make online payments while we verify your new payment information.
      </Text>

      <Text variant="base">
        You represent and warrant that if you are making online payments that (i) any credit card,
        debit card, and bank account information you supply is true, correct, and complete, (ii)
        charges incurred by you will be honored by your credit/debit card company or bank, (iii) you
        will pay the charges incurred by you in the amounts posted, including any applicable taxes,
        and (iv) you are the person in whose name the card was issued and you are authorized to make
        a purchase or other transaction with the relevant credit card and credit card information.
      </Text>

      <Title size="sm" as="h4">
        Cancellation
      </Title>

      <Text variant="base">
        You may cancel your subscription at any time by contacting us at{" "}
        <Link href="mailto:support@runwayhealth.com">support@runwayhealth.com</Link>. The
        cancellation will take effect for the next billing cycle following the date of cancellation.
      </Text>

      <Title size="sm" as="h4">
        No Refunds
      </Title>

      <Text variant="base">
        Fees are non-refundable due to the nature of the products and services being provided.
        Please note that orders cannot be cancelled once shipped by the pharmacy because medication
        cannot be returned or refunded.
      </Text>

      <Title size="sm" as="h4">
        Changes to Subscription Plans
      </Title>

      <Text variant="base">
        Runway reserves the right to change pricing for our service or any components thereof in any
        manner and at any time as we may determine in our sole and absolute discretion. Except as
        otherwise expressly provided for in these Terms of Use, any price changes or changes to your
        subscription plan will take effect following notice to you.
      </Text>

      <Title size="md" as="h3">
        OUR FEES
      </Title>

      <Text variant="base">
        Runway will directly pay your telemedicine Provider and the dispensing pharmacy for their
        services from the fees paid by you to Runway. All other amounts paid for Runway’s services
        will be retained by Runway, provided that Runway may share a portion of such fees with our
        marketing partners.
      </Text>

      <Title size="md" as="h3">
        ACCOUNT SET-UP
      </Title>

      <Text variant="base">
        You agree to: (a) provide true, accurate, current, and complete information when registering
        to use the Digital Services and establishing your account (“Registration Information”) and
        (b) maintain and promptly update the Registration Information to keep it true, accurate,
        current, and complete. If you provide any information that is untrue, inaccurate, not
        current, or incomplete, or Runway has reasonable grounds to suspect that such information is
        untrue, inaccurate, not current, or incomplete, Runway may suspend or terminate your
        account. You are entirely responsible for maintaining the confidentiality of any passwords
        and any usage and activities that occur in connection with your account. You agree not to
        allow others to access your account or utilize your password. Doing so will compromise the
        security of your account.
      </Text>

      <Text variant="base">
        You agree to immediately notify Runway of any unauthorized use of your password or account,
        and to ensure that you properly exit from your account at the end of each session. Runway
        shall not be liable for any loss or damage arising from your failure to comply with any of
        these terms and conditions.
      </Text>

      <Text variant="base">
        The following actions are expressly prohibited in relation to your username and password
        used to obtain the Digital Service:
      </Text>

      <ul>
        <li>
          <Text variant="base" as="span">
            Sharing, disclosing, permitting access to, or otherwise facilitating the use by any
            person of your username and password;
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Using the username and password to cache the Digital Service in such a manner as to be
            accessible by persons who have not properly registered with Runway; or
          </Text>
        </li>
        <li>
          <Text variant="base" as="span">
            Using the username and password to permit multiple persons access to the Digital Service
            through a local or wide area network.
          </Text>
        </li>
      </ul>

      <Text variant="base">
        If you have forgotten your username or password, Runway will use an email address previously
        provided by you to send your username or temporary password. You understand that any other
        individuals using the same email address will be able to gain access to your Digital Service
        account information, but only if they provide the correct answers to your security
        questions.
      </Text>

      <Text variant="base">
        Your use of the Digital Service and any content accessed through the Digital Service must
        comply with all applicable laws, regulations, and ordinances, including any laws regarding
        the export of data or software. You must be at least 18 years old to register and use the
        Digital Service, or you must be the legal guardian for someone under age 18 whose data is
        accessed through the Digital Service. You may not interfere with or disrupt the proper
        operation of the Digital Service.
      </Text>

      <Text variant="base">
        If you use the Digital Service to enter and maintain your personal information, you
        understand that you are responsible for safeguarding and securing your mobile device and the
        associated credentials (such as user identifiers and passwords). If you leave your mobile
        device unattended, or if it is lost or stolen, you understand that your personal information
        may be accessible to others.
      </Text>

      <Title size="md" as="h3">
        MINORS
      </Title>

      <Text variant="base">
        You must be at least 18 years old to register and use the Digital Service, or you must be
        the legal guardian for someone under age 18 whose data is accessed through the Digital
        Service. The Digital Services may be used or accessed by the parent or legal guardian on
        behalf of a minor child who is at least 13 years old. Children under the age of 13 are not
        eligible to receive services from Runway.
      </Text>

      <Title size="md" as="h3">
        DISCLAIMER OF MEDICAL SERVICES
      </Title>

      <Text variant="base">
        Runway operates as a treatment service coordinator only. Runway does not provide medical
        advice or care, nor does it dispense pharmaceutical products. Any telemedicine consults
        obtained through our Digital Services are provided by licensed, independent medical
        practitioners that provide healthcare services via telemedicine through a network of United
        States based health care providers (each, a “Provider”). Any prescriptions issued by your
        Provider are filled and shipped to you by a licensed pharmacy. Your Provider and/or pharmacy
        is directly responsible for providing you with a Notice of Privacy Practices that describes
        its collection and use of your health information, not Runway.
      </Text>

      <Title size="md" as="h3">
        PROVIDER DIRECTORY
      </Title>

      <Text variant="base">
        As the treatment service coordinator, Runway connects customers to medical providers through
        a telehealth consult/visit. Medical providers offering their services through a telehealth
        encounter on the Runway platform are compensated for a fixed fee as part of the overall
        service fee charged to the customer.
      </Text>

      <Title size="md" as="h3">
        NO MEDICAL ADVICE
      </Title>

      <Text variant="base">
        The Providers are independent of Runway and are merely using the Digital Services as a way
        to communicate with you. Any information or advice received from a Provider comes from them
        alone, and not from Runway. Your interactions with a Provider via the Digital Services are
        not intended to take the place of your relationship with your regular health care
        practitioners or primary care physician. Neither Runway, nor any of its subsidiaries or
        affiliates or any third party who may promote the Digital Services or provide a link to the
        services, shall be liable for any professional advice obtained from a Provider via the
        Digital Services, nor any information obtained on the Digital Services. Runway does not make
        any representations or warranties about the training or skills of any Providers who deliver
        services via the Digital Services. You will be provided with available Providers based
        solely on the information you submit to the Digital Services. You are ultimately responsible
        for choosing your particular Provider.
      </Text>

      <Text variant="base">
        The content of the Digital Services, including without limitation, text, copy, audio, video,
        photographs, illustrations, graphics, and other visuals, is for informational purposes only
        and does not constitute professional medical advice, diagnosis, treatment, or
        recommendations of any kind. You should always seek the advice of your qualified heath care
        professionals with any questions or concerns you may have regarding your individual needs
        and any medical conditions. Runway does not recommend or endorse any specific tests,
        physicians, products, procedures, opinions, or other information that may be included on the
        Digital Services. Reliance on any information appearing on the Digital Services, whether
        provided by Runway, its content providers, its clients, visitors to the Digital Services or
        others, is solely at your own risk.
      </Text>
      <Text variant="base">
        Our website offers the opportunity to receive online medical consults for persons who may
        need certain medical interventions in preparation for travel. A Provider, not affiliated
        with Runway, will conduct any medical consult scheduled through our Website. The Provider
        will conduct a medical evaluation to determine whether a prescription is appropriate based
        our your individual medical needs. All prescribing is based solely on the independent
        medical decision making of your Provider. There is no guarantee you will receive a
        prescription. All Providers work for independent physician-owned medical practices. The
        Providers do not have a financial relationship with Runway and are compensated based solely
        on their personally performed services. The Provider’s compensation is the same whether or
        not a prescription is issued, and regardless of what pharmacy you select to dispense the
        prescription.
      </Text>

      <Title size="md" as="h3">
        NOT FOR EMERGENCIES
      </Title>

      <Text variant="base">
        IF YOU ARE EXPERIENCING A MEDICAL EMERGENCY, YOU SHOULD DIAL “911” IMMEDIATELY.
      </Text>

      <Text variant="base">
        Runway’s Digital Services are not for medical emergencies or urgent situations. You should
        not disregard or delay seeking medical advice based on anything that appears or does not
        appear on the Digital Services. If you believe you have an emergency, call 9-1-1
        immediately.
      </Text>

      <Text variant="base">
        You should seek emergency help or follow up care when recommended by a Provider or when
        otherwise needed. You should continue to consult with your primary provider and other
        healthcare professionals as recommended. Always seek the advice of a physician or other
        qualified healthcare provider concerning questions you have regarding a medical condition
        and before stopping, starting, or modifying any treatment or medication.
      </Text>

      <Title size="md" as="h3">
        RISKS OF TELEHEALTH SERVICES
      </Title>

      <Text variant="base">
        Telehealth involves the delivery of healthcare services using electronic communications,
        information technology, or other means between a healthcare provider and a patient who is
        not in the same physical location. While the provision of health care services using
        telehealth may offer certain potential benefits, there are also potential risks associated
        with the use of telehealth. The telehealth services are not a substitute for in-person
        health care in all cases. In order to use the telehealth services, you will be required to
        review and agree to an informed consent regarding the use of telehealth (the “Patient
        Consent”) that will be provided to you by the Providers through the Digital Services. You
        agree that Runway is a third-party beneficiary of the Patient Consent and has the right to
        enforce it.
      </Text>

      <Title size="md" as="h3">
        PRESCRIPTION POLICY
      </Title>

      <Text variant="base">
        You will not be able to obtain a prescription product unless you have completed a
        consultation with one of the Providers through the Digital Services, the Provider has
        determined the prescription product is appropriate for you, and the Provider has written a
        prescription.
      </Text>

      <Text variant="base">
        You agree that any prescriptions that you acquire from a Provider will be solely for your
        personal use. You agree to read fully and carefully all provided product information and
        labels and to contact a physician or pharmacist if you have any questions regarding the
        prescription. Runway has negotiated rates for its customers with its pharmacy partners and
        our service fee proposal includes the cost of a telehealth Provider consultation as well as
        the cost of medication when dispensed from our pharmacy partner. However, Runway fully
        honors patient freedom of choice and, if you receive a prescription for a medication, you
        always have the option to instruct your Provider to transmit that prescription to the
        pharmacy of your choice, provided that you are willing to pay the additional cost charged by
        such pharmacy.
      </Text>

      <Title size="md" as="h3">
        BINDING ARBITRATION
      </Title>

      <Text variant="base">
        These Terms of Use provide that all disputes between you and Runway that in any way relate
        to these Terms of Use or your use of the Digital Services will be resolved by BINDING
        ARBITRATION. ACCORDINGLY, YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT (INCLUDING IN A
        CLASS ACTION PROCEEDING) to assert or defend your rights under these Terms of Use (except
        for matters that may be taken to small claims court). Your rights will be determined by a
        NEUTRAL ARBITRATOR and NOT a judge or jury and your claims cannot be brought as a class
        action. Please review the Section below entitled Dispute Resolution; Arbitration Agreement
        for the details regarding your agreement to arbitrate any disputes with Runway.
      </Text>

      <Title size="md" as="h3">
        OWNERSHIP OF THE DIGITAL SERVICES
      </Title>

      <Text variant="base">
        All pages, features, and content within the Digital Services and any material made available
        for download are the property of Runway, or its licensors or suppliers, as applicable. The
        Digital Services are protected by United States and international copyright and trademark
        laws. The contents of the Digital Services, including without limitation all data, files,
        documents, text, photographs, images, audio, and video, and any materials accessed through
        or made available for use or download through these Digital Services (“Content”) may not be
        copied, distributed, modified, reproduced, published, or used, in whole or in part, except
        for purposes authorized by these Terms of Use or otherwise approved in writing by Runway.
        You may not frame or utilize framing techniques to enclose, or deep link to, any name,
        trademarks, service marks, logo, Content, or other proprietary information (including
        images, text, page layout, or form) of Runway without our express written consent.
      </Text>

      <Title size="md" as="h3">
        ACCESS TO THE DIGITAL SERVICES; SECURITY AND RESTRICTIONS; PASSWORDS
      </Title>

      <Text variant="base">
        You are prohibited from violating or attempting to violate the security of the Digital
        Services, including, without limitation, (a) accessing data not intended for such user or
        logging onto a server or an account which the user is not authorized to access; or (b)
        attempting to probe, scan, or test the vulnerability of a system or network or to breach
        security or authentication measures without proper authorization; or (c) accessing or using
        the Digital Services or any portion thereof without authorization, in violation of these
        Terms of Use or in violation of applicable law.
      </Text>

      <Text variant="base">
        You may not use any scraper, crawler, spider, robot, or other automated means of any kind to
        access or copy data on the Digital Services, deep-link to any feature or content on the
        Digital Services, bypass our robot exclusion headers or other measures we may use to prevent
        or restrict access to the Digital Services.
      </Text>

      <Text variant="base">
        Violations of system or network security may result in civil or criminal liability. Runway
        will investigate occurrences that may involve such violations and may involve, and cooperate
        with, law enforcement authorities in prosecuting users who are involved in such violations.
        You agree not to use any device, software, or routine to interfere or attempt to interfere
        with the proper working of these Digital Services or any activity being conducted on these
        Digital Services.
      </Text>

      <Text variant="base">
        In the event access to the Digital Services or a portion thereof is limited requiring a user
        ID and password (“Protected Areas”), you agree to access Protected Areas using only your
        user ID and password as provided to you by Runway. You agree to protect the confidentiality
        of your user ID and password, and not to share or disclose your user ID or password to any
        third party. You agree that you are fully responsible for all activity occurring under your
        user ID. Your access to the Digital Services may be revoked by Runway at any time with or
        without cause. You agree to defend, indemnify, and hold Runway harmless from and against all
        third-party claims, damages, and expenses (including reasonable attorneys’ fees) against or
        incurred by arising out of your breach of these Terms of Use or violation of applicable law,
        your use or access of the Digital Services, or access by anyone accessing the Digital
        Services using your user ID and password.
      </Text>

      <Title size="md" as="h3">
        ACCURACY AND INTEGRITY OF INFORMATION
      </Title>

      <Text variant="base">
        Although Runway attempts to ensure the integrity and accuracy of the Digital Services, it
        makes no representations, warranties, or guarantees whatsoever as to the correctness or
        accuracy of the Digital Services and Content thereon. It is possible that the Digital
        Services could include typographical errors, inaccuracies, or other errors, and that
        unauthorized additions, deletions, and alterations could be made to the Digital Services by
        third parties. In the event that an inaccuracy arises, please inform Runway so that it can
        be corrected. Runway reserves the right to unilaterally correct any inaccuracies on the
        Digital Services without notice. Information contained on the Digital Services may be
        changed or updated without notice. Additionally, Runway shall have no responsibility or
        liability for information or Content posted to the Digital Services from any non-Runway
        affiliated third party.
      </Text>

      <Title size="md" as="h3">
        TYPOGRAPHICAL ERRORS AND INCORRECT PRICING
      </Title>

      <Text variant="base">
        In the event a product or service is listed at an incorrect price due to typographical error
        or error in pricing information received from our suppliers, we shall have the right to
        refuse or cancel any orders placed for a product or service listed at the incorrect price.
        We shall have the right to refuse or cancel any such orders whether or not the order has
        been confirmed and your credit or debit card charged. If your credit or debit card has
        already been charged for the purchase and your order is canceled, we shall promptly issue a
        credit to your credit or debit card account in the amount of the incorrect price.
      </Text>

      <Title size="md" as="h3">
        ORDER ACCEPTANCE
      </Title>

      <Text variant="base">
        We reserve the right, at our sole discretion, to refuse or cancel any order for any reason.
        Your account may also be restricted or terminated for any reason, at our sole discretion.
        Some situations that may result in your order being canceled include limitations on
        quantities available for purchase, inaccuracies or errors in product or pricing information,
        or problems identified by our credit and fraud avoidance department. We may also require
        additional verifications or information before accepting any order. We will contact you if
        all or any portion of your order is canceled or if additional information is required to
        accept your order. If your order is canceled by us after your credit card (or other payment
        account) has been charged, we will issue a credit to your credit card (or other applicable
        payment account) in the amount of the charge.
      </Text>

      <Title size="md" as="h3">
        LINKS TO OTHER SITES
      </Title>

      <Text variant="base">
        Runway makes no representations whatsoever about any other website that you may access
        through these Digital Services. When you access a non-Runway website, please understand that
        it is independent from Runway, and that Runway has no control over the content on that
        website. In addition, a link to a non-Runway website does not mean that Runway endorses or
        accepts any responsibility for the content, or the use, of the linked website. It is up to
        you to take precautions to ensure that whatever you select for your use or download is free
        of such items as viruses, worms, Trojan horses, and other items of a destructive nature. If
        you decide to access any of the third-party websites linked to these Digital Services, you
        do this entirely at your own risk.
      </Text>

      <Title size="md" as="h3">
        DISCLAIMER OF WARRANTIES
      </Title>

      <Text variant="base">
        RUNWAY DOES NOT WARRANT THAT ACCESS TO OR USE OF THE DIGITAL SERVICES WILL BE UNINTERRUPTED
        OR ERROR-FREE OR THAT DEFECTS IN THE DIGITAL SERVICES WILL BE CORRECTED. THESE DIGITAL
        SERVICES, INCLUDING ANY CONTENT OR INFORMATION CONTAINED WITHIN IT OR ANY SERVICE RELATED TO
        THE DIGITAL SERVICES, IS PROVIDED "AS IS," WITH ALL FAULTS, WITH NO REPRESENTATIONS OR
        WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY OF
        INFORMATION, QUIET ENJOYMENT, AND TITLE/NON-INFRINGEMENT. RUNWAY DOES NOT WARRANT THE
        ACCURACY, COMPLETENESS, OR TIMELINESS OF THE INFORMATION OBTAINED THROUGH THE DIGITAL
        SERVICES.
      </Text>

      <Text variant="base">
        YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THESE DIGITAL SERVICES, DIGITAL
        SERVICES-RELATED SERVICES, AND LINKED WEBSITES. RUNWAY DOES NOT WARRANT THAT FILES AVAILABLE
        FOR DOWNLOAD WILL BE FREE OF VIRUSES, WORMS, TROJAN HORSES, OR OTHER DESTRUCTIVE
        PROGRAMMING. YOU ARE RESPONSIBLE FOR IMPLEMENTING PROCEDURES SUFFICIENT TO SATISFY YOUR
        NEEDS FOR DATA BACK UP AND SECURITY.
      </Text>

      <Text variant="base">
        WARRANTIES RELATING TO PRODUCTS OR SERVICES OFFERED, SOLD, AND DISTRIBUTED BY RUNWAY ARE
        SUBJECT TO SEPARATE WARRANTY TERMS AND CONDITIONS, IF ANY, PROVIDED WITH OR IN CONNECTION
        WITH THE APPLICABLE PRODUCTS OR SERVICES.
      </Text>

      <Title size="md" as="h3">
        NOT AN INSURANCE PRODUCT
      </Title>

      <Text variant="base">
        Runway is not an insurer. The services provided by and through the Digital Services are not
        insurance products. If you desire any type of health or other insurance, you will need to
        purchase such insurance separately.
      </Text>

      <Title size="md" as="h3">
        LIMITATION OF LIABILITY REGARDING USE OF DIGITAL SERVICES
      </Title>

      <Text variant="base">
        RUNWAY AND ANY THIRD PARTIES MENTIONED ON THESE DIGITAL SERVICES ARE NEITHER RESPONSIBLE NOR
        LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR
        OTHER DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOST PROFITS,
        LOST DATA, OR BUSINESS INTERRUPTION) ARISING OUT OF OR RELATING IN ANY WAY TO THE DIGITAL
        SERVICES, DIGITAL SERVICES-RELATED SERVICES, CONTENT OR INFORMATION CONTAINED WITHIN THE
        DIGITAL SERVICES, AND/OR ANY LINKED WEBSITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR
        ANY OTHER LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR
        SOLE REMEDY FOR DISSATISFACTION WITH THE DIGITAL SERVICES, DIGITAL SERVICES-RELATED
        SERVICES, AND/OR LINKED WEBSITES IS TO STOP USING THE DIGITAL SERVICES AND/OR THOSE
        SERVICES. TO THE EXTENT ANY ASPECTS OF THE FOREGOING LIMITATIONS OF LIABILITY ARE NOT
        ENFORCEABLE, THE MAXIMUM AGGREGATE LIABILITY OF RUNWAY TO YOU WITH RESPECT TO YOUR USE OF
        THESE DIGITAL SERVICES MAY NOT EXCEED THE TOTAL COST OF SERVICES PURCAHSED BY YOU IN THE
        THREE MONTHS PRECEDING THE DATE YOUR CLAIM AROSE.
      </Text>

      <Title size="md" as="h3">
        INDEMNIFICATION
      </Title>

      <Text variant="base">
        You agree to defend, indemnify, and hold Runway and any affiliates harmless from and against
        any and all rights, demands, losses, liabilities, damages, claims, causes of action,
        actions, and suits (no matter whether at law or equity), fees, costs, and attorney’s fees of
        any kind whatsoever arising directly or indirectly out of or in connection with: (i) your
        use or misuse of the Digital Services, products, services, or any information posted on the
        Digital Services; (ii) your breach of the Terms of Use or Privacy Policy; (iii) the content
        or subject matter of any information you provide to Runway, your Provider, or a customer
        service agent; or (iv) any negligent or wrongful act or omission by you in your use or
        misuse of the Digital Services, products, services, or any information on the Digital
        Services, including without limitation, infringement of third party intellectual property
        rights, privacy rights, or negligent or wrongful conduct.
      </Text>

      <Title size="md" as="h3">
        DISPUTE RESOLUTION; ARBITRATION AGREEMENT
      </Title>

      <Text variant="base">
        We will try to work in good faith to resolve any issue you have with the Digital Services,
        including products and services ordered or purchased through the Digital Services, if you
        bring that issue to the attention of our customer service department. However, we realize
        that there may be rare cases where we may not be able to resolve an issue to a customer's
        satisfaction.
      </Text>

      <Text variant="base">
        You and Runway agree that any dispute, claim, or controversy arising out of or relating in
        any way to these Terms of Use or your use of the Digital Services, including products and
        services ordered or purchased through the Digital Services, shall be determined by binding
        arbitration instead of in courts of general jurisdiction. Arbitration is more informal than
        bringing a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or
        jury and is subject to very limited review by courts. Arbitration allows for more limited
        discovery than in court, however, we agree to cooperate with each other to agree to
        reasonable discovery in light of the issues involved and amount of the claim. Arbitrators
        can award the same damages and relief that a court can award, but in so doing, the
        arbitrator shall apply substantive law regarding damages as if the matter had been brought
        in court, including without limitation, the law on punitive damages as applied by the United
        States Supreme Court. You agree that, by agreeing to these Terms of Use, the U.S. Federal
        Arbitration Act governs the interpretation and enforcement of this provision, and that you
        and Runway are each waiving the right to a trial by jury or to participate in a class
        action. This arbitration provision shall survive termination of these Terms of Use and any
        other contractual relationship between you and Runway.
      </Text>

      <Text variant="base">
        If you desire to assert a claim against Runway, and you therefore elect to seek arbitration,
        you must first send to Runway, by certified mail, a written notice of your claim ("Notice").
        The Notice to Runway should be addressed to: Runway, Attn: LEGAL NOTICE, 2418 SW 18th Ave,
        Miami, FL 33145 ("Notice Address"). If Runway desires to assert a claim against you and
        therefore elects to seek arbitration, it will send, by certified mail, a written Notice to
        the most recent address we have on file or otherwise in our records for you. A Notice,
        whether sent by you or by Runway, must (a) describe the nature and basis of the claim or
        dispute; and (b) set forth the specific relief sought ("Demand"). If Runway and you do not
        reach an agreement to resolve the claim within 30 days after the Notice is received, you or
        Runway may commence an arbitration proceeding or file a claim in small claims court. During
        the arbitration, the amount of any settlement offer made by Runway, or you shall not be
        disclosed to the arbitrator. You may download or copy a form Notice and a form to initiate
        arbitration from the American Arbitration Association at{" "}
        <Link href="https://www.adr.org/" isBlank>
          www.adr.org
        </Link>{" "}
        If you are required to pay a filing fee, after Runway receives notice at the Notice Address
        that you have commenced arbitration, it will promptly reimburse you for your payment of the
        filing fee, unless your claim is for more than US $10,000. The arbitration will be governed
        by the Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related
        Disputes (collectively, "AAA Rules") of the American Arbitration Association ("AAA"), as
        modified by these Terms of Use, and will be administered by the AAA. The AAA Rules and Forms
        are available online at{" "}
        <Link href="https://www.adr.org" isBlank>
          WWW.ADR.ORG
        </Link>
        , by calling the AAA at 1-800-778-7879, or by requesting them from us by writing to us at
        the Notice Address. The arbitrator is bound by the terms of these Terms of Use. All issues
        are for the arbitrator to decide, including issues relating to the scope and enforceability
        of these Terms of Use, including this arbitration agreement. Unless Runway and you agree
        otherwise, any arbitration hearings will take place in the county (or parish) of your
        billing address. If your claim is for US $10,000 or less, we agree that you may choose
        whether the arbitration will be conducted solely on the basis of documents submitted to the
        arbitrator, through a telephonic hearing, or by an in-person hearing as established by the
        AAA Rules. If your claim exceeds US $10,000, the right to a hearing will be determined by
        the AAA Rules. Regardless of the manner in which the arbitration is conducted, the
        arbitrator shall issue a reasoned written decision sufficient to explain the essential
        findings and conclusions on which the award is based. If the arbitrator issues you an award
        that is greater than the value of Runway’s last written settlement offer made before an
        arbitrator was selected (or if Runway did not make a settlement offer before an arbitrator
        was selected), then Runway will pay you the amount of the award or US $1,000, whichever is
        greater. Except as expressly set forth herein, the payment of all filing, administration,
        and arbitrator fees will be governed by the AAA Rules. Each party shall pay for its own
        costs and attorneys' fees, if any. However, if any party prevails on a statutory claim that
        affords the prevailing party attorneys' fees, or if there is a written agreement providing
        for payment or recovery attorneys’ fees, the arbitrator may award reasonable fees to the
        prevailing party, under the standards for fee shifting provided by law.
      </Text>

      <Text variant="base">
        YOU AND RUNWAY AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS
        INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
        REPRESENTATIVE PROCEEDING. Further, unless both you and Runway agree otherwise, the
        arbitrator may not consolidate more than one person's claims with your claims and may not
        otherwise preside over any form of a representative or class proceeding. The arbitrator may
        award declaratory or injunctive relief only in favor of the individual party seeking relief
        and only to the extent necessary to provide relief warranted by that party's individual
        claim.
      </Text>

      <Text variant="base">
        If this Agreement to Arbitrate provision is found to be unenforceable, then (a) the entirety
        of this arbitration provision shall be null and void, but the remaining provisions of these
        Terms of Use shall remain in full force and effect; and (b) exclusive jurisdiction and venue
        for any claims will be in state or federal courts located in and for Miami Dade County,
        Florida.
      </Text>

      <Title size="md" as="h3">
        CONSENT TO RECEIVE CALLS AND TEXT MESSAGES
      </Title>

      <Text variant="base">
        By providing your mobile number, you are agreeing to be contacted by or on behalf of Runway
        at the mobile number you have provided, including calls and text messages, to receive
        informational, product or service related (e.g., progress tracking, reminders, etc.)
        messages and communications relating to the Digital Services. Message and data rates may
        apply. To stop receiving text messages text a reply to us with the word STOP. We may confirm
        your opt-out by text message. If you subscribe to multiple types of text messages from us,
        we may unsubscribe you from the service that most recently sent you a message or respond to
        your STOP message by texting you a request to identify services you wish to stop. Please
        note, that by withdrawing your consent, some Digital Services may no longer be available to
        you. Keep in mind that if you stop receiving text messages from us you may not receive
        important and helpful information and reminders about your services.
      </Text>

      <Title size="md" as="h3">
        CAN-SPAM ACT AND TELEPHONE CONSUMER PROTECTION ACT COMPLIANCE
      </Title>

      <Text variant="base">
        Runway is committed to being compliant with the Controlling the Assault of Non-Solicited
        Pornography and Marketing Act (“CAN-SPAM ACT”) and the Telephone Consumer Protection Act
        (“TCPA”). You consent to receive text messages from us as set forth above (Consent to
        Receive Calls and Text Messages and Video Recordings). E-mails, newsletters, and text
        messages received from us are intended to fully comply with the CAN-SPAM ACT and the TCPA.
        In the event you receive an e-mail or text message from us which you do not believe is fully
        compliant with the CAN-SPAM ACT or the TCPA, please contact us immediately at{" "}
        <Link href="mailto:support@runwayhealth.com">support@runwayhealth.com</Link>.
      </Text>

      <Title size="md" as="h3">
        ELECTRONIC COMMUNICATIONS
      </Title>

      <Text variant="base">
        When you use the Digital Services, or send e-mails, messages, and other communications from
        your desktop or mobile device to us, you are communicating with us electronically. You
        consent to receive communications from us electronically. You agree that (a) all agreements
        and consents can be signed electronically and (b) all notices, disclosures, and other
        communications that we provide to you electronically satisfy any legal requirement that such
        notices and other communications be in writing. Runway may contact you by telephone, mail,
        or e-mail to verify your information. Runway may request further information from you, and
        you agree to provide such further information to ensure that you have not fraudulently used
        the Digital Services. If you do not provide this information in the manner requested within
        14 days of the request, we reserve the right to suspend, discontinue, or deny your access to
        and use of our Digital Services until you provide the information to us as requested.
      </Text>

      <Title size="md" as="h3">
        FORCE MAJEURE
      </Title>

      <Text variant="base">
        We will not be deemed to be in breach of these terms or liable for any breach of these terms
        or our Privacy Policy due to any event or occurrence beyond our reasonable control,
        including without limitation, acts of God, terrorism, war, invasion, failures of any public
        networks, electrical shortages, earthquakes or floods, civil disorder, pandemics or public
        health emergencies, strikes, fire, or other disaster.
      </Text>

      <Title size="md" as="h3">
        EXTERNAL SERVICES
      </Title>

      <Text variant="base">
        The Digital Services may enable access to Runway’s and/or third-party services and websites,
        including social media sites (collectively and individually, "External Services"). You agree
        to use the External Services at your sole risk. Runway is not responsible for examining or
        evaluating the content or accuracy of any third-party External Services and shall not be
        liable for any such third-party External Services. Data displayed by the Digital Services or
        External Service, including but not limited to financial, medical, and location information,
        is for general informational purposes only and is not guaranteed by Runway or its agents.
        You will not use the External Services in any manner that is inconsistent with the terms of
        this Agreement or that infringes the intellectual property rights of Runway or any third
        party. You agree not to use the External Services to harass, abuse, stalk, threaten, or
        defame any person or entity, and that Runway is not responsible for any such use. External
        Services may not be available in all languages or in your home country and may not be
        appropriate or available for use in any particular location. To the extent you choose to use
        such External Services, you are solely responsible for compliance with any applicable laws.
        Runway reserves the right to change, suspend, remove, disable, or impose access restrictions
        or limits on any External Services at any time without notice or liability to you.
      </Text>

      <Title size="md" as="h3">
        REVISIONS; GENERAL
      </Title>

      <Text variant="base">
        Runway reserves the right, in its sole discretion, to terminate your access to all or part
        of these Digital Services, with or without cause, and with or without notice. In the event
        that any of the Terms of Use are held by a court or other tribunal of competent jurisdiction
        to be unenforceable, such provisions shall be limited or eliminated to the minimum extent
        necessary so that these Terms of Use shall otherwise remain in full force and effect. These
        Terms of Use constitute the entire agreement between Runway and you pertaining to the
        subject matter hereof. In its sole discretion, Runway may from time-to-time revise these
        Terms of Use by updating this posting. You should, therefore, periodically visit this page
        to review the current Terms of Use, so you are aware of any such revisions to which you are
        bound. Your continued use of the Digital Services after revisions to these Terms of Use
        shall constitute your agreement to the revised Terms of Use. Certain provisions of these
        Terms of Use may be superseded by expressly designated legal notices or terms located on
        particular pages within these Digital Services.
      </Text>
    </>
  );
};

export default Terms;
