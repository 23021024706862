import { Ga4Object, Item, ItemCategory, ProductEntryPoint } from "@hooks/useDataLayer";

type Ga4Item = {
  item_name: string;
  item_id: string;
  price: number;
  item_category: ItemCategory;
  product_entry_point: ProductEntryPoint;
};

export class Ga4DTO {
  public currency?: string;
  public value?: number;
  public addon_value?: number;
  public coupon?: string | null;
  public items?: Ga4Item[];
  public method?: string;

  constructor(ga4object: Ga4Object) {
    this.currency = ga4object.currency;
    this.value = ga4object.value;
    this.addon_value = ga4object.addonValue;
    this.coupon = ga4object.coupon;
    this.items = ga4object.items?.map((item: Item) => {
      return {
        item_name: item.name,
        item_id: item.id,
        price: item.price,
        item_category: item.category,
        product_entry_point: item.productEntryPoint,
      };
    });
    this.method = ga4object.method;
  }
}
