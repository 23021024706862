import React, { useState } from "react";
import "./textFieldPassword.scss";

import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const TextFieldPassword = ({ title, onChange, onBlur }) => {
  const [showPassword, setShowPassword] = useState(false);
  const changeShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <label className="textfield-password">
      <span className="textfield-password-title">{title}</span>
      <div className="textfield-password-input">
        <input type={showPassword ? "text" : "password"} onChange={onChange} onBlur={onBlur} />
        {showPassword ? (
          <Visibility className="show" onClick={changeShowPassword} />
        ) : (
          <VisibilityOff className="show" onClick={changeShowPassword} />
        )}
      </div>
    </label>
  );
};

export default TextFieldPassword;
