import "./pharmacyBlock.scss";
import { useEffect } from "react";
import PharmacyElement from "../pharmacyElement/PharmacyElement";

const PharmacyBlock = ({
  pharmacy,
  checkboxState,
  setCheckboxState,
  currentPharmacy,
  setCurrentPharmacy,
}) => {
  //pharmacy - all found pharmacies
  //checkboxState - state of all checkboxes at pharmacies
  //setCheckboxState - function to change the state of the checkbox at the pharmacy
  //currentPharmacy -function for saving the data of the selected pharmacy in the state
  useEffect(() => {
    setCheckboxState(pharmacy?.map((i) => false));
  }, [pharmacy]);

  //function to clear chosen pharmacy if user will change input value
  useEffect(() => {
    return () => {
      setCurrentPharmacy(null);
    };
}, []);

  //Function to change the checkbox status of the selected pharmacy
  const changeStatus = (num) => {
    setCheckboxState((prev) =>
      prev.map((item, index) => {
        if (index === num) {
          return !item;
        }
        return false;
      })
    );

    if (currentPharmacy && currentPharmacy.id === pharmacy[num].id) {
      setCurrentPharmacy(null);
      return;
    } else setCurrentPharmacy(pharmacy[num]);
  };

  return (
    <div className="pharmacy-find-block">
      {pharmacy?.map((item, index) => (
        <PharmacyElement
          pharmacy={item}
          status={checkboxState && checkboxState[index]}
          key={item?.id}
          index={index}
          change={changeStatus}
        />
      ))}
    </div>
  );
};

export default PharmacyBlock;
