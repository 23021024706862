import "./setNewPassword.scss";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "@elements/Header";
import { useTitle } from "@hooks/useTitle";
import {
  Button,
  Card,
  FormGroup,
  Label,
  TextField,
  Title,
} from "@runwayhealth/runway-components-react";
import { ErrorEvents, RootEntity, RootState } from "@store/../@types/state";
import { PASSWORD_RESET_TOKEN_KEY, User } from "@store/../@types/user";
import { PASSWORD_UPDATE } from "@store/user/userActions";
import { toastError } from "@utils/utils";

const SetNewPasswordPage = () => {
  const dispatch = useDispatch();
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);
  const [errorText, setErrorText] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  useTitle("Set new password");

  useEffect(() => {
    if (user.error.event) {
      toastError("Error", user.error.message);
      if (user.error.event === ErrorEvents.BAD_REQUEST) {
        setErrorText(user.error.message || "");
      }
    }
  }, [user.error]);

  const onSetPassword = () => {
    if (errorText === "") {
      dispatch({
        type: PASSWORD_UPDATE,
        newPassword,
        resetToken: localStorage.getItem(PASSWORD_RESET_TOKEN_KEY),
      });
    } else if (confirmNewPassword === "" || newPassword === "") {
      setErrorText("Password fields can't be empty");
    }
  };

  const validatePassword = () => {
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (!passwordPattern.test(newPassword)) {
      setErrorText("Please enter a valid password");
      return false;
    }

    if (newPassword !== confirmNewPassword) {
      setErrorText("Passwords don't match");
      return false;
    }

    setErrorText("");
    return true;
  };

  const PasswordErrorGuide = () => (
    <div>
      <h5 className="error-message">{errorText}</h5>
      {errorText !== "" ? (
        <Card>
          <span style={{ fontSize: " 16px", marginLeft: "36px" }}>Your new password must:</span>
          <ul style={{ marginLeft: "15px" }}>
            <li>Contain at least 8 characters</li>
            <li>Contain at least 1 number</li>
            <li>Contain at least 1 uppercase letter</li>
            <li>Be different from your previous password</li>
          </ul>
        </Card>
      ) : null}
    </div>
  );

  return (
    <div>
      <Header hasProgressBar={false} />
      <div className="row" style={{ marginTop: "40px" }}>
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <Card>
            <div style={{ textAlign: "center", marginBottom: "40px" }}>
              <Title>Set your new password</Title>
            </div>
            <FormGroup>
              <Label>Enter your password.</Label>
              <TextField
                name="password"
                type="password"
                label="New password"
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setConfirmNewPassword(e.currentTarget.value)
                }
                onKeyUp={validatePassword}
              />
            </FormGroup>
            <FormGroup>
              <Label>Confirm your new password.</Label>
              <TextField
                name="confirm"
                type="password"
                label="Confirm password"
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setNewPassword(e.currentTarget.value)
                }
                onKeyUp={validatePassword}
              />
            </FormGroup>
            <PasswordErrorGuide />
            <div style={{ marginTop: "20px" }}>
              <Button
                size="lg"
                onClick={onSetPassword}
                iconName="ArrowRight"
                isLoading={user.isPending}
                disabled={user.isPending}
                isBlock
              >
                Continue
              </Button>
            </div>
          </Card>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  );
};

export default SetNewPasswordPage;
