import { React } from "react";
import "./Checkbox.scss";

const Checkbox = ({
  checked,
  setChecked,
  required,
  num,
  content,
  onClick,
  disabled,
}) => {
  const changeState = () => {
      setChecked((prev) =>
      prev.map((item, index) => {
        if (index === num) {
          return !item;
        }
        return item;
      })
    );
  };

  return (
    <div className={checked ? "checkbox-form checked" : "checkbox-form"}>
      <div className="items">
        <label className="custom-checkbox">
          <input
            type="checkbox"
            className="checkbox-input"
            checked={checked}
            onChange={() => changeState()}
            required={required}
            num={num}
            onClick={onClick}
            disabled={disabled}
          />
          <span className="checkmark-primary" />
          <span className="checkbox-variant">{content}</span>
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
