import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Header from "../../elements/Header";
import Checkbox from "../../elements/Checkbox";

import { userUpdate } from "@store/user/userSlice";
import { questionsUpdate } from "@store/questions/questionsSlice";

import { ConditionTypes } from "@store/../@types/condition";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { Button, Text, Title } from "@runwayhealth/runway-components-react";

const ConditionsMalarone = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const consultations = JSON.parse(sessionStorage.getItem("consultations")) || [];
  const finishedConsultations = JSON.parse(sessionStorage.getItem("finished_consultations")) || [];
  const currentConsultation = sessionStorage.getItem("current_consultation");

  const variants = [
    "None",
    "Chronic liver disease",
    "Chronic kidney disease (stage 3 or higher)",
    "Obesity (medication may be less effective for patients >220lbs)",
    "Intracranial hypertension",
    "Seizures",
    "Conditions requiring use of lithium",
    "G6PD deficiency",
    "Over 65",
  ];

  const [isNone, setNone] = useState(false);
  const [formState, setFormState] = useState([false, false, false, false, false, false, false]);
  const [captureVariants, setCaptureVariants] = useState([]);
  useTitle("Current Conditions");
  useEffect(() => {
    formState?.forEach((item, index) => {
      return item === true && captureVariants.indexOf(variants[index]) === -1
        ? setCaptureVariants((prev) => [...prev, variants[index]])
        : null;
    });
  }, [formState]);

  useEffect(() => {
    if (formState[0] === true) {
      setNone(true);
    } else {
      setNone(false);
    }
  }, [formState]);

  let capturedVariant = formState
    ?.map((item, i) => (item ? variants[i] : false))
    .filter((variant) => variant)
    .join(", ");

  const currentNextRoute = (route) => {
    const nextRoute = consultations.includes(route);
    if (nextRoute && !finishedConsultations.includes(route)) {
      return consultations;
    }
  };

  const setAnswer = () => {
    if (capturedVariant || isNone) {
      dispatch(
        questionsUpdate({
          question: "Do you have any of the following conditions?",
          value: !isNone ? capturedVariant : "None",
          important: !isNone,
          questionType: "malaria",
        })
      );
      dispatch(
        userUpdate({ name: "ConditionsMalarone", value: !isNone ? capturedVariant : "None" })
      );

      finishedConsultations.push(currentConsultation);
      sessionStorage.setItem("finished_consultations", JSON.stringify(finishedConsultations));

      switch (consultations) {
        case currentNextRoute(ConditionTypes.ALTITUDE_CONSULTATION): {
          history.push("/start/altitude");
          break;
        }
        case currentNextRoute(ConditionTypes.MOTION_CONSULTATION): {
          history.push(`/start/motion/${ConditionTypes.MOTION_CONSULTATION}`);
          break;
        }
        case currentNextRoute(ConditionTypes.NAUSEA_CONSULTATION): {
          history.push(`/start/motion/${ConditionTypes.NAUSEA_CONSULTATION}`);
          break;
        }
        case currentNextRoute(ConditionTypes.INSOMNIA_CONSULTATION): {
          history.push("/start/insomnia");
          break;
        }
        case currentNextRoute(ConditionTypes.DIARRHEA_CONSULTATION): {
          history.push("/start/dia");
          break;
        }
        case currentNextRoute(ConditionTypes.VAGINAL_YEAST_INFECTION): {
          history.push(`/start/infection/${ConditionTypes.VAGINAL_YEAST_INFECTION}`);
          break;
        }
        case currentNextRoute(ConditionTypes.URINARY_TRACT_INFECTION): {
          history.push(`/start/infection/${ConditionTypes.URINARY_TRACT_INFECTION}`);
          break;
        }
        case currentNextRoute(ConditionTypes.TYPHOID_FEVER): {
          history.push("/start/typhoid");
          break;
        }
        case currentNextRoute(ConditionTypes.RUNWAY_PLUS): {
          history.push("/start/plus");
          break;
        }
        default: {
          history.push("/allergies");
        }
      }
    }
  };

  const onKeyUpHandler = (e) => {
    e.key == "Enter" && setAnswer();
  };

  return (
    <>
      <Header progress={5} />
      <PageConsultation tabIndex={1} onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">Do you have any of the following conditions?</Title>
          <Text size="md">Choose one or more options</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <div className="rw-page-consultation-list-checkboxes">
            {variants.map((variant, index) => (
              <>
                <Checkbox
                  content={variant}
                  checked={formState[index]}
                  setChecked={() => {
                    if (index === 0) {
                      const newState = formState.map((state) => false);
                      newState[0] = true;
                      setFormState(newState);
                    } else {
                      const newState = formState.map((state, i) => {
                        return i === index ? !state : state;
                      });
                      newState[0] = false;
                      setFormState(newState);
                    }
                  }}
                  num={index}
                  key={`checkbox-${index}`}
                />
              </>
            ))}
          </div>
        </PageConsultation.Body>

        <Button
          size="lg"
          iconName="ArrowRight"
          customWidth={440}
          onClick={setAnswer}
          disabled={!capturedVariant}
        >
          Next
        </Button>
      </PageConsultation>
    </>
  );
};

export default ConditionsMalarone;
