import "./ChoiceButtons.scss";

import { useEffect } from "react";

import useAmplitude from "../../../hooks/useAmplitude";

interface ChoiceButtonsProps {
  first: string;
  second: string;
  setAnswer: (answer: string) => void;
  setNo: (answer: string) => void;
  problems: boolean;
  isActive: boolean;
  isActiveSecond: boolean;
}

const ChoiceButtons = ({
  first,
  second,
  setAnswer,
  setNo,
  problems,
  isActive,
  isActiveSecond,
}: ChoiceButtonsProps) => {
  const { logEvent } = useAmplitude();
  const divContainerClassName = first === "Cities" ? "destination-buttons" : "gender-buttons";
  const reportAnswer = (answer: string) => {
    logEvent("ANSWER_SELECTED", { answer });
  };

  useEffect(() => {
    logEvent("CHOICE_PRESENTED");
  }, [logEvent]);

  return (
    <div className={divContainerClassName}>
      <button
        className={isActive ? "choice-first active" : "choice-first"}
        onClick={() => {
          reportAnswer(first);
          setAnswer(first);
        }}
      >
        {first}
      </button>
      <button
        className={isActiveSecond ? "choice-second active" : "choice-second"}
        onClick={() => {
          reportAnswer(second);
          return problems ? setNo(second) : setAnswer(second);
        }}
      >
        {second}
      </button>
    </div>
  );
};

export default ChoiceButtons;
