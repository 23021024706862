import React from "react";
import ArrowRightBlack from "../../../img/ArrowRightBlack.svg";
import "./NextButton.scss";

const NextButton = ({ disabled = false, content, handleClick }) => {
  return (
    <div className="button-div">
      <button
        className={disabled ? "next-button disabled" : "next-button"}
        disabled={disabled}
        type="submit"
        onClick={() => handleClick()}
      >
        <div className="button-container">
          <span className="button-text">{content}</span>
          <img
            src={ArrowRightBlack}
            className={disabled ? "arrow-right-icon disabled" : "arrow-right-icon"}
            alt="Arrow Right"
          />
        </div>
      </button>
    </div>
  );
};

export default NextButton;
