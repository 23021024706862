import "./Delivery.scss";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { Text, Title } from "@runwayhealth/runway-components-react";
import { Condition } from "@store/../@types/condition";
import { RootState } from "@store/../@types/state";
import { questionsUpdate } from "@store/questions/questionsSlice";
import { userUpdate } from "@store/user/userSlice";

import Header from "../../elements/Header";
import YesNoQuestion from "../../templates/YesNoQuestion";
import { RUNWAY_PLUS } from "../RunwayPlus";

const Delivery = () => {
  const [answer, setAnswers] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const conditions = useSelector<RootState, Condition[]>(
    (state) => state.case.newCase.data.conditions
  );
  const [isRunwayPlus, setIsRunwayPlus] = useState(false);
  useTitle("Delivery Risk");

  const question =
    "Depending on your departure date, Runway may not be able to deliver your medication before the day of your trip.";
  const subtitle = "Do you still want to proceed?";

  useEffect(() => {
    setIsRunwayPlus(conditions.length === 0);
  }, []);

  const setAnswer = (value: string) => {
    if (value === "Yes") {
      setAnswers(value);
      dispatch(
        questionsUpdate({
          question: question,
          value,
          questionType: "general",
        })
      );
      if (!isRunwayPlus) {
        dispatch(userUpdate({ name: "priorityShippingRequest", value: true }));
      }

      history.push("/triptype");
    } else {
      setAnswers(value);
    }
  };

  return (
    <div>
      <Header progress={30} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">{question}</Title>
          <Text size="lg">{subtitle}</Text>
        </PageConsultation.Header>
        <PageConsultation.Body>
          <YesNoQuestion
            title=""
            question=""
            setAnswer={setAnswer}
            isActiveSecond={answer === "No" ? true : false}
            setNo={() => {}}
          />
          {answer === "No" && (
            <div className="error-message-delivery">
              <p className="message-content-delivery">Please consult your physician.</p>
            </div>
          )}
        </PageConsultation.Body>
      </PageConsultation>
    </div>
  );
};

export default Delivery;
