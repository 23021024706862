import styled from "styled-components";

export const ContentWrapper = styled.div`
  position: absolute;
  width: 390px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  top: 0;
  margin: auto;
  right: 0;
  padding: 17px 16px 0 25px;
`;

export const CloseButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 5px;
`;
