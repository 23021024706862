import "./upsellPage.scss";

import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";

import { PresentationType } from "@components/Cart";
import { PageConsultation } from "@components/layouts/PageConsultation";
import Header from "@elements/Header";
import { useTitle } from "@hooks/useTitle";
import { Button, Title } from "@runwayhealth/runway-components-react";
import { Condition } from "@store/../@types/condition";
import { RootState } from "@store/../@types/state";

// Lazy components
const Cart = lazy(() => import("@components/Cart/index"));

const UpsellPage = () => {
  useTitle("Recommended Meds");
  const items = useSelector<RootState, Condition[]>(
    (state) => state.case.newCase.data.conditions
  ).map((condition) => condition.product.name);

  return (
    <>
      <Header progress={4} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">
            Runway offers {items.length > 0 ? "additional" : ""} medications that are commonly
            prescribed to travelers visiting your destination.
          </Title>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <div className="upsell-page-body">
            <div className="wrapper">
              <Suspense fallback={<div>Loading...</div>}>
                <Cart items={items} country={""} presentationType={PresentationType.UPSELL} />
              </Suspense>
            </div>
          </div>
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default UpsellPage;
