import { Condition } from "@store/../@types/condition";

export const REQUEST_CASE_PRICE = "REQUEST_CASE_PRICE";
export interface RequestCasePrice {
  type: typeof REQUEST_CASE_PRICE;
  priceId: string;
}

export const REQUEST_SHIPPING_PRICE = "REQUEST_SHIPPING_PRICE";
export interface RequestShippingPrice {
  type: typeof REQUEST_SHIPPING_PRICE;
  shippingRatesIds: string[];
}

export const REQUEST_PRESCRIPTIONS_PRICES = "REQUEST_PRESCRIPTIONS_PRICES";
export interface RequestPrescriptionsPrices {
  type: typeof REQUEST_PRESCRIPTIONS_PRICES;
  conditions: Condition[];
}
