import "./pharmacyAlert.scss";

import miniLogoSvg from "../../../../../img/miniLogoRunway.svg";

const PharmacyAlert = ({ status }) => {
  return (
    <div className={"pharmacy-alert"}>
      <div className="pharmacy-alert-wrapper">
        <img className={status === "error" && "error"} src={miniLogoSvg} alt="" />
        {status === "loading" ? <p>Getting pharmacies</p> : null}
        {status === "error" ? <p>No pharmacy is found</p> : null}
      </div>
    </div>
  );
};

export default PharmacyAlert;
