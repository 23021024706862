import React from "react";

const CloseButtonColor = ({ color = "grey" }) => {
  return (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5358 1C11.5642 4.16092 9.60445 6.84757 6.99998 8.58902C8.25614 9.41804 9.3712 10.4784 10.2957 11.7231C11.2811 13.0494 12.0399 14.5585 12.5358 16.1781M1 1C1.97154 4.16092 3.93132 6.84757 6.53579 8.58902C5.27963 9.41804 4.16457 10.4784 3.24006 11.7231C2.25464 13.0494 1.49588 14.5585 1 16.1781"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default CloseButtonColor;
