import styled from "styled-components";

import { from, spacing } from "@runwayhealth/runway-components-react";

export const ModalBody = styled.div`
  padding: ${spacing.nano};
  text-align: center;
  overflow: hidden;

  @media ${from.mobile} {
    padding: ${spacing.xs};
  }

  @media ${from.tablet} {
    padding: ${spacing.lg};
  }

  img,
  video {
    height: auto;
    max-height: 60vh;
    width: 100%;
    object-fit: contain;

    @media only screen and (max-width: 1180px) and (orientation: landscape) {
      height: 55vh;
    }
  }

  .rw-modal-error-message {
    margin-top: ${spacing.xxs};
  }
`;

export const ModalCamContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  .rw-modal-button-camera-rotate {
    position: absolute;
    bottom: ${spacing.xxxs};
    right: ${spacing.xxxs};
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacing.xxxs};
  padding-top: ${spacing.xxxs};

  @media ${from.mobile} {
    gap: ${spacing.xxs};
    padding-top: ${spacing.xs};
  }
  @media ${from.tablet} {
    padding-top: ${spacing.lg};
  }
`;
