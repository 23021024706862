import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { STRIPE_KEY } from "../../../../utils/constantsEnv";

const stripeTestPromise = loadStripe(STRIPE_KEY);

const StripeContainer = ({ children }) => {
  return <Elements stripe={stripeTestPromise}>{children}</Elements>;
};

export default StripeContainer;
