import { SocketEvent } from "./socketChannel";

export const RESTORE_CONNECTION = "RESTORE_CONECTION";
export interface RestoreConnection {
  type: typeof RESTORE_CONNECTION;
  authToken: string;
}

export const WEBSOCKET_SEND = "WEBSOCKET_SEND";
export interface WebSocketSend<T> {
  type: typeof WEBSOCKET_SEND;
  event: SocketEvent<T>;
}
