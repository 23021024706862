import styled from "styled-components";

import { from, shadow, spacing } from "@runwayhealth/runway-components-react";

import img from "../../../img/start-consultation-image-md.webp";

export const Sidebar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: auto;
  padding: ${spacing.xxxs};
  box-shadow: ${shadow.level1};

  @media ${from.mobile} {
    min-height: 100vh;
    min-width: 270px;
    width: 27%;

    background-image: url(${img});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 63%;
    background-position: center center;
    box-shadow: none;
  }

  @media ${from.desktop} {
    width: 40%;
    min-width: 380px;
    max-width: 500px;
  }

  .rw-logo {
    height: fit-content;
    border-bottom: none;
    width: ${spacing.xxl};

    @media ${from.mobile} {
      margin-top: 20%;
      width: 55%;
    }

    @media ${from.desktopL} {
      width: 47%;
    }
    img {
      width: -moz-available;
      width: -webkit-fill-available;
      height: auto;
    }
  }
`;
