import styled from "styled-components";

import { from } from "@runwayhealth/runway-components-react";

export const PaymentContainer = styled.div`
  max-width: 600px;
  article {
    margin-bottom: 8px;
  }
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const PaymentBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media ${from.mobileS} {
    justify-content: space-between;
    align-items: center;
  }
  .payment-body {
    &-info {
      display: flex;
      flex-direction: column;
      width: inherit;
      @media ${from.mobileS} {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 18%;
      }
    }
    &-price {
      width: fit-content;
      margin-right: 16px;
      @media (max-width: 786px) {
        margin-top: 1pc;
      }
    }
  }
`;

export const AddEditButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
  margin-bottom: 10px;
  justify-content: flex-start;
`;

export const SavePayButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
