import "./adminmain.scss";

import { ChangeEvent, SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, MenuItem, Select, TextField } from "@material-ui/core";
import { Button as RunwayButton, Title } from "@runwayhealth/runway-components-react";
import { Case, CaseStatuses } from "@store/../@types/case";
import { RootState } from "@store/../@types/state";
import { getCases } from "@store/admin/adminAction";

import CaseResults from "./CaseResults";

// Prod only
export const getMdiLink = (caseId: string) => (
  <a
    href={`https://app.mdintegrations.com/tabs/cases/${caseId}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {caseId}
  </a>
);

export const getMdiMedicationLink = (medicationId?: string, medicationName?: string) => (
  <a
    href={`https://admin.mdintegrations.com/resources/partner-medications/${medicationId}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {medicationName}
  </a>
);

export const getInvoiceIdLink = (invoiceId: string) => (
  <a
    href={`https://dashboard.stripe.com/invoices/${invoiceId}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {invoiceId}
  </a>
);

const CaseSearch = () => {
  const dispatch = useDispatch();
  const cases = useSelector<RootState, Case[]>((state) => state.admin.cases);

  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [caseId, setCaseId] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [status, setStatus] = useState("");
  const searchCases = () => {
    dispatch(getCases({ id: caseId, from, to, status, offset }));
  };

  const nextPage = () => {
    let offsetAccum = offset + 30;
    setOffset(offsetAccum);

    let pageCounter = page + 1;
    setPage(pageCounter);

    dispatch(getCases({ id: caseId, from, to, status, offset: offsetAccum }));
  };

  const previousPage = () => {
    if (page > 1) {
      let offsetAccum = offset - 30;
      setOffset(offsetAccum);

      let pageCounter = page - 1;
      setPage(pageCounter);

      dispatch(getCases({ id: caseId, from, to, status, offset: offsetAccum }));
    }
  };

  return (
    <section>
      <h2>Case Search 📦</h2>
      <div className="noMargin">
        <div>
          <TextField
            label="Case ID"
            value={caseId}
            onChange={(evt) => setCaseId(evt.target.value)}
          />
          <select
            className="nonMaterialAlignment"
            value={status}
            onChange={(event) => {
              setStatus(event.target.value);
            }}
          >
            <option value="">All</option>
            <option value={CaseStatuses.ASSIGNED}>Assigned</option>
            <option value={CaseStatuses.CANCELLED}>Cancelled</option>
            <option value={CaseStatuses.COMPLETED}>Completed</option>
            <option value={CaseStatuses.CREATED}>Created</option>
            <option value={CaseStatuses.PROCESSING}>Processing</option>
            <option value={CaseStatuses.SUPPORT}>Support</option>
            <option value={CaseStatuses.WAITING}>Waiting</option>
          </select>
          <TextField
            label="from"
            type="date"
            value={from}
            onChange={(evt) => setFrom(evt.target.value)}
          />
          <TextField
            label="to"
            type="date"
            value={to}
            onChange={(evt) => setTo(evt.target.value)}
          />
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={searchCases}
            className="nonMaterialAlignment"
          >
            Search 🔍
          </Button>
        </div>
      </div>
      <CaseResults cases={cases} />
      <div className="pagination-wrapper">
        <RunwayButton iconName="CaretLeft" isIconOnly variant="outline" onClick={previousPage} />
        <Title>{page}</Title>
        <RunwayButton iconName="CaretRight" isIconOnly variant="outline" onClick={nextPage} />
      </div>
    </section>
  );
};

export default CaseSearch;
