import "../../../../assets/scss/cart.scss";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Title } from "@runwayhealth/runway-components-react";
import { Case } from "@store/../@types/case";
import { Condition } from "@store/../@types/condition";
import { PricesState, RootState } from "@store/../@types/state";
import { MAP_CONDITION_PRESCRIPTIONS } from "@store/cases/casesActions";

interface ConditionsSummaryProps {
  conditions: Condition[];
  removeCondition: (condition: string) => void;
}

const UpsellConditionsSummary = ({ conditions, removeCondition }: ConditionsSummaryProps) => {
  const newCase = useSelector<RootState, Case>((state) => state.case.newCase.data);
  const prices = useSelector<RootState, PricesState>((state) => state.price);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: MAP_CONDITION_PRESCRIPTIONS,
      conditions,
      questions: newCase.case_questions,
      prices,
    });
  }, []);
  return (
    <>
      <div className="upsell-summary-wrapper">
        <Title className="upsell-summary-title">Current selection</Title>
        {conditions.map((condition) => {
          return (
            <div key={condition.product.id} className="upsell-conditions-summary-wrapper">
              <Title as="h1" size="sm">
                {condition.product.name}
              </Title>
              <Title as="h1" size="md">
                ${(condition.price ?? 0) / 100}
              </Title>
            </div>
          );
        })}
      </div>{" "}
      <hr></hr>{" "}
    </>
  );
};

export default UpsellConditionsSummary;
