import PageConsultationBase from "./elements/PageConsultationBase";
import PageConsultationBody from "./elements/PageConsultationBody";
import PageConsultationFooter from "./elements/PageConsultationFooter";
import PageConsultationHeader from "./elements/PageConsultationHeader";
import { PageConsultationProps } from "./PageConsultation.types";

const PageConsultation = ({ className, onKeyUp, children, ...other }: PageConsultationProps) => {
  return (
    <PageConsultationBase className={className} onKeyUp={onKeyUp} {...other}>
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-12 col-sm-12">{children}</div>
      </div>
    </PageConsultationBase>
  );
};

PageConsultation.Header = PageConsultationHeader;
PageConsultation.Body = PageConsultationBody;
PageConsultation.Footer = PageConsultationFooter;

export default PageConsultation;
