export type State = {
  name: string;
  abbreviation: string;
};
export type StateAvailability = State & {
  isAsync: boolean;
};

const createState = (name: string, abbreviation: string): State => ({
  name,
  abbreviation,
});

const ASYNC_STATES = [
  createState("Alabama", "AL"),
  createState("Alaska", "AK"),
  createState("Arizona", "AZ"),
  createState("Arkansas", "AR"),
  createState("California", "CA"),
  createState("Colorado", "CO"),
  createState("Connecticut", "CT"),
  createState("Florida", "FL"),
  createState("Georgia", "GA"),
  createState("Hawaii", "HI"),
  createState("Illinois", "IL"),
  createState("Indiana", "IN"),
  createState("Iowa", "IA"),
  createState("Kentucky", "KY"),
  createState("Louisiana", "LA"),
  createState("Maine", "ME"),
  createState("Maryland", "MD"),
  createState("Massachusetts", "MA"),
  createState("Michigan", "MI"),
  createState("Minnesota", "MN"),
  createState("Missouri", "MO"),
  createState("Montana", "MT"),
  createState("Nebraska", "NE"),
  createState("Nevada", "NV"),
  createState("New Hampshire", "NH"),
  createState("New Jersey", "NJ"),
  createState("New York", "NY"),
  createState("North Carolina", "NC"),
  createState("North Dakota", "ND"),
  createState("Ohio", "OH"),
  createState("Oklahoma", "OK"),
  createState("Oregon", "OR"),
  createState("Pennsylvania", "PA"),
  createState("Rhode Island", "RI"),
  createState("South Carolina", "SC"),
  createState("South Dakota", "SD"),
  createState("Tennessee", "TN"),
  createState("Texas", "TX"),
  createState("Utah", "UT"),
  createState("Vermont", "VT"),
  createState("Virginia", "VA"),
  createState("Washington", "WA"),
  createState("Wisconsin", "WI"),
  createState("Wyoming", "WY"),
];

const SYNC_REQUIRED_STATES = [
  createState("Delaware", "DE"),
  createState("District Of Columbia", "DC"),
  createState("Idaho", "ID"),
  createState("Kansas", "KS"),
  createState("Mississippi", "MS"),
  createState("New Mexico", "NM"),
  createState("West Virginia", "WV"),
];

export const getStateAvailability = () => {
  const availableStates: StateAvailability[] = [];
  ASYNC_STATES.forEach((state) => {
    availableStates.push({ ...state, isAsync: true });
  });
  SYNC_REQUIRED_STATES.forEach((state) => {
    availableStates.push({ ...state, isAsync: false });
  });
  return availableStates;
};
