import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import useDataLayer, { GA4Events } from "@hooks/useDataLayer";
import { useTitle } from "@hooks/useTitle";
import { AlertBanner, Button, Feedback, Text, Title } from "@runwayhealth/runway-components-react";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import AutocompleteInput from "../../elements/AutocompleteInput";
import Header from "../../elements/Header";
import { getStateAvailability, State, StateAvailability } from "./stateAvailability";

const states = require("../WhatState/states.json");

const WhatState = () => {
  const dispatch = useDispatch();
  const [stateName, setStateName] = useState("");
  const [stateAvailability, setStateAvailability] = useState<StateAvailability[]>([]);
  const [openStateDropdown, setOpenStateDropdown] = useState(false);
  const [syncRequired, setSyncRequired] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();

  const { reportPurchasedProducts } = useDataLayer(GA4Events.START_CONSULTATION);

  useEffect(() => {
    setStateAvailability(getStateAvailability());
  }, []);
  useTitle("Where do you live?");

  const handleClick = () => {
    if (stateName.length < 4) {
      return setError("Please select your state");
    } else if (stateName && !error) {
      dispatch(
        questionsUpdate({
          question: "Let us know what state you live in",
          questionType: "general",
          value: stateName,
        })
      );
      if (
        stateAvailability
          .filter((state) => state.isAsync)
          .some((state: { name: string }) => state.name === stateName)
      ) {
        // Report start consultation event with purchased products.
        reportPurchasedProducts();
        history.push("/date");
      } else {
        // Report start consultation event with purchased products.
        history.push("/sync-state");
      }
    }
  };

  const onKeyUpHandler = (e: React.KeyboardEvent) => {
    e.key == "Enter" && handleClick();
  };

  return (
    <>
      <Header progress={0} />
      <PageConsultation onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">Let us know what state you live in</Title>
          <Text size="md">Fill the field below</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <AutocompleteInput
            open={openStateDropdown}
            options={states.map((state: State) => state.name)}
            onKeyUp={() => setOpenStateDropdown(true)}
            width={"630px"}
            key={"state-input"}
            value={stateName}
            capturedVariant={(e) => {
              setStateName(e);
              setOpenStateDropdown(false);
            }}
            onFocus={undefined}
            defaultValue={undefined}
            fontSize={undefined}
            height={undefined}
            placeholder={undefined}
            stateAvailability={stateAvailability}
            setSyncRequired={setSyncRequired}
          />
          {error && (
            <Feedback isInvalid size="sm" className="rw-page-consultation-feedback-message">
              {error}
            </Feedback>
          )}
          {syncRequired && (
            <div className="rw-page-consultation-feedback-message">
              <AlertBanner isRounded className="depart-banner">
                <AlertBanner.Message>
                  Please note, your physician may contact you directly via phone or text message
                  prior to prescribing, ensuring personalized and optimal care.
                </AlertBanner.Message>
              </AlertBanner>
            </div>
          )}
        </PageConsultation.Body>

        <Button
          size="lg"
          iconName="ArrowRight"
          customWidth={440}
          onClick={handleClick}
          disabled={!!error || !stateName}
        >
          Start
        </Button>
      </PageConsultation>
    </>
  );
};

export default WhatState;
