import "./RadioButtonInput.scss";
import { TextField } from "@runwayhealth/runway-components-react";

const RadioButton = ({ placeholder, checked, setChecked, num, setValue, value }) => {
  const changeState = () => {
    setChecked((prev) =>
      prev.map((item, index) => {
        if (index === num) {
          return true;
        }
        return false;
      })
    );
  };

  return (
    <div
      className={"checkbox-form"}
      style={{ border: checked ? "2px solid #aac4f9" : "2px solid #e0e0e0" }}
    >
      <div className="items" onClick={changeState}>
        <label className="control-area">
          <label className="control control-radio">
            <input
              type="radio"
              name="radio"
              value={checked}
              onFocus={changeState}
              checked={checked}
            />
            <div className="control_indicator"></div>
            <span className="variant-description"></span>
          </label>
          <TextField id="label" placeholder={placeholder} value={value} onChange={setValue} />
        </label>
      </div>
    </div>
  );
};

export default RadioButton;
