import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { Text, Title } from "@runwayhealth/runway-components-react";
import { ConditionTypes } from "@store/../@types/condition";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import ChoiceButtons from "../../elements/ChoiceButtons";
import Header from "../../elements/Header";

type IConsultationProps = {
  consultationName: string;
};

const MotionSickness = () => {
  const dispatch = useDispatch();
  const { consultationName } = useParams<IConsultationProps>();

  useEffect(() => {
    sessionStorage.setItem("current_consultation", consultationName);
  }, []);
  useTitle(consultationName);

  const history = useHistory();

  const question =
    consultationName === ConditionTypes.MOTION_CONSULTATION
      ? "Have you ever had motion sickness in the past?"
      : "Have you ever had issues with nausea in the past";

  const setAnswer = (value: string) => {
    dispatch(
      questionsUpdate({
        question,
        value,
        questionType: "motion",
      })
    );
    history.push("/conditions");
  };

  return (
    <>
      <Header progress={2.5} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">{question}</Title>
          <Text size="md">Choose one option</Text>
        </PageConsultation.Header>
        <PageConsultation.Body>
          <ChoiceButtons
            first="Yes"
            second="No"
            setAnswer={setAnswer}
            setNo={() => {}}
            problems={false}
            isActive={false}
            isActiveSecond={false}
          />
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default MotionSickness;
